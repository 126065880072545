import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FormField } from '@blueprism/ui-core';
import { AuthContext } from 'contexts/AuthContext';
import { assessments } from 'utils/api';

const AssessmentSummary = props => {
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const [formattedSummary, setFormattedSummary] = useState({
    isFormattedSummarySet: false,
    data: {},
  });

  useEffect(() => {
    if (formattedSummary.isFormattedSummarySet === false) {
      assessments
        .getFormattedSummaryAnswers(getUser, props.id)
        .then(response => setFormattedSummary({ isFormattedSummarySet: true, data: response }))
        .catch(err => console.error(err));
    }
  }, [formattedSummary, props.id, getUser]);

  if (!formattedSummary.isFormattedSummarySet) {
    return null;
  }

  function renderReadonlyInfoMessage() {
    let label = null;

    if (!formattedSummary.data.isInEditableState) {
      label = intl.formatMessage({ id: 'processAssessmentSummary_readonlyInfo_readonlyDueToStatus' });
    }

    if (!formattedSummary.data.userHasEditPermission) {
      label = intl.formatMessage({ id: 'processAssessmentSummary_readonlyInfo_readonlyDueToUserPrivilege' });
    }

    if (label === null) {
      return null;
    }

    return <FormField label={label} error={true}></FormField>;
  }
  return (
    <>
      {renderReadonlyInfoMessage()}
      {React.Children.map(props.children, child =>
        React.cloneElement(child, { formattedSummary: formattedSummary.data })
      )}
    </>
  );
};

export default AssessmentSummary;
