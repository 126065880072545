import React from 'react';
import PropTypes from 'prop-types';
import { CheckBoxGroup, CheckBox, FormField } from '@blueprism/ui-core';

export default function CheckboxFilter({
  enabled,
  fieldName,
  label = '',
  helperText = '',
  options = {},
  onChange = () => {},
}) {
  const [localValue, setLocalValue] = React.useState([]);

  function handleValueChange(_, values) {
    setLocalValue(values);
    onChange({ [fieldName]: values });
  }

  React.useEffect(() => {
    if (enabled && localValue.length) {
      onChange({ [fieldName]: localValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <FormField gap="base" htmlFor={`checkbox-filter-${fieldName}`} label={label} helperText={helperText} isFieldset>
      <CheckBoxGroup
        id={`checkbox-filter-${fieldName}`}
        name={`${fieldName}-group`}
        value={localValue}
        onChange={handleValueChange}
      >
        {Object.keys(options).map(option => (
          <CheckBox
            key={`${fieldName + option}`}
            id={`${fieldName + option}`}
            name={`${fieldName + option}`}
            label={options[option]}
            value={option}
          />
        ))}
      </CheckBoxGroup>
    </FormField>
  );
}

CheckboxFilter.propTypes = {
  enabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
};
