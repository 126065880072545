import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { GroupsContext } from 'contexts/GroupsContext';
import TablePagination from 'components/TablePagination';
import Link from 'components/Link';
import TableLoader from 'components/TableStates/TableLoader';

export default function GroupsList({ selectedRowIds, onRowSelectionChange = () => {} }) {
  const intl = useIntl();
  const history = useHistory();
  const { tableStatus, group } = React.useContext(GroupsContext);

  const groupId = group.parentId;
  const data = group.groups;
  const offset = group.offset;
  const limit = group.limit;

  const count = data?.count ?? 0;

  function handleLimitChange(newLimit) {
    history.push(`/your-spaces/group/${groupId}/groups/${offset}/${newLimit}`);
  }

  function handleNextData() {
    history.push(`/your-spaces/group/${groupId}/groups/${offset + limit}/${limit}`);
  }

  function handlePreviousData() {
    history.push(`/your-spaces/group/${groupId}/groups/${Math.max(0, offset - limit)}/${limit}`);
  }

  const columns = [
    {
      header: intl.formatMessage({ id: 'spaceGroupsColumn1' }),
      accessor: 'nameColumn',
    },
  ];

  const rowData = () => {
    if (data?.items?.length > 0) {
      return data.items.map(item => ({
        id: item.id.toString(),
        group: item,
        nameColumn: (
          <Link id={`group${item.id}Link`} href={`/your-spaces/group/${item.id}`}>
            {item.name}
          </Link>
        ),
        disableSelect: !item.isAdmin,
      }));
    }

    return [];
  };

  return (
    <>
      <TableLoader
        tableStatus={tableStatus}
        emptyStateTitle={intl.formatMessage({ id: 'spaceGroupSubGroupsTableNoDataTitle' })}
        emptyStateText={intl.formatMessage({ id: 'spaceGroupSubGroupsTableNoDataText' })}
        errorStateText={intl.formatMessage({ id: 'spaceGroupSubGroupsTableDataFetchFail' })}
        rowData={rowData()}
        columns={columns}
        selectRows
        selectedIds={selectedRowIds}
        onRowSelectionChange={onRowSelectionChange}
      />
      <TablePagination
        limitLabelId="group-limit"
        itemName="Groups"
        limit={limit}
        offset={offset}
        count={count}
        onLimitChange={handleLimitChange}
        onNextData={handleNextData}
        onPreviousData={handlePreviousData}
      />
    </>
  );
}

GroupsList.propTypes = {
  onRowSelectionChange: PropTypes.func,
  selectedRowIds: PropTypes.array,
};
