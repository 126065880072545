import * as React from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import { PeoplePlus, PeopleRemove, PeopleEdit } from '@blueprism/ui-icons';
import TeamAddDialog from 'components/TeamAddDialog';
import SpaceTeamRemoveDialog from 'components/SpaceTeamRemoveDialog';
import { TeamsContext } from 'contexts/TeamsContext';

export default function TeamNavButtons({ selectedRowIds = [], clearSelection = () => {} }) {
  const intl = useIntl();
  const {
    team,
    openAddDialog,
    closeAddDialog,
    addTeam,
    openEditDialog,
    closeEditDialog,
    editTeam,
    openRemoveDialog,
    closeRemoveDialog,
    removeTeam,
  } = React.useContext(TeamsContext);

  const selectedTeam =
    selectedRowIds.length === 1
      ? team?.teams?.items.find(teamItem => teamItem.teamId.toString() === selectedRowIds[0])
      : null;

  function handleRemoveTeam(teamId) {
    return removeTeam(teamId, clearSelection);
  }

  function handleEditTeam(teamName, teamId) {
    return editTeam(teamName, teamId, clearSelection);
  }

  function handleAddTeam(teamName) {
    return addTeam(teamName, clearSelection);
  }
  return (
    <>
      <ButtonGroup>
        <ToolbarButton
          id="spaceTeamAdd"
          onClick={openAddDialog}
          label={intl.formatMessage({ id: 'spaceTeamAdd' })}
          icon={<PeoplePlus />}
        />
        <ToolbarButton
          id="spaceTeamRemove"
          onClick={openRemoveDialog}
          disabled={!selectedTeam}
          label={intl.formatMessage({ id: 'spaceTeamRemove' })}
          icon={<PeopleRemove />}
        />
        <ToolbarButton
          id="spaceTeamEdit"
          onClick={openEditDialog}
          disabled={!selectedTeam}
          label={intl.formatMessage({ id: 'spaceTeamEdit' })}
          icon={<PeopleEdit />}
        />
      </ButtonGroup>

      <TeamAddDialog mode="Add" open={team.isAddOpen} onConfirm={handleAddTeam} onClose={closeAddDialog} />
      <TeamAddDialog
        mode="Edit"
        team={selectedTeam}
        open={team.isEditOpen}
        onConfirm={handleEditTeam}
        onClose={closeEditDialog}
      />
      <SpaceTeamRemoveDialog
        teamId={selectedTeam?.teamId}
        teamName={selectedTeam?.name}
        open={team.isRemoveOpen}
        onConfirm={handleRemoveTeam}
        onClose={closeRemoveDialog}
      />
    </>
  );
}
