import * as React from 'react';
import { useIntl } from 'react-intl';
import Cluster from '@blueprism/ui-core/layout/Cluster';
import { Lightning, Clock, Clipboard } from '@blueprism/ui-icons';
import JumboMetadata from '../core/JumboMetaData';
import { YourProcessesContext } from 'contexts/YourProcessesContextProvider';

const recommendationType = {
  PRIORITISE: 1,
  REVIEW: 2,
  DEFER: 3,
};

export function getIcon(type) {
  switch (type) {
    case recommendationType.PRIORITISE:
      return <Lightning size={32} />;
    case recommendationType.REVIEW:
      return <Clipboard size={32} />;
    case recommendationType.DEFER:
      return <Clock size={32} />;
    default:
      return null;
  }
}

export default function Banner() {
  const intl = useIntl();
  const { state } = React.useContext(YourProcessesContext);

  return (
    <Cluster justify="evenly">
      {state.summaries.map(summary => (
        <JumboMetadata
          key={summary.recommendation}
          icon={getIcon(summary.recommendation)}
          mainTitleText={intl.formatMessage({ id: `recommentationText${summary.recommendation}` })}
          mainTitleDescriptionText={`${summary.assessments}`}
          secondaryTitleText={intl.formatMessage({ id: `yourProcesses_banner_potentialHoursReturned` })}
          secondaryTitleDescriptionText={`${summary.hours}`}
          width="200px"
        />
      ))}
    </Cluster>
  );
}
