import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Text from '@blueprism/ui-core/components/Text';
import Stack from '@blueprism/ui-core/layout/Stack';

export default function AssessmentBenefitsSummary({ processData = {} }) {
  const intl = useIntl();

  if (!processData.potentialSavingHours && !processData.potentialSavingCost) {
    return null;
  }

  const hours = processData.potentialSavingHours ?? 0;
  const amount = processData.potentialSavingCost ?? '';
  const currency = processData.averageFTECost?.currencyCode ?? '';

  function getPotentialHoursSavedSection() {
    return (
      <>
        <Text type="h4" id="hoursSavingText">
          <FormattedMessage
            id="benefitsHoursSaving"
            values={{ value: hours < 2 ? '< 2' : <FormattedNumber value={parseInt(hours)} /> }}
          />
        </Text>
      </>
    );
  }

  function getPotentialCapitalSavedSection() {
    return (
      <Text type="h4" id="costSavingText">
        <FormattedMessage
          id="benefitsCostSaving"
          values={{
            value: (
              <FormattedNumber
                value={amount}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                locale={intl.locale}
                currencyDisplay="symbol"
                currency={currency}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            ),
          }}
        />
      </Text>
    );
  }

  return (
    <Stack gap="medium">
      <Text type="h3">
        <FormattedMessage id="benefitsSummaryHeader" />
      </Text>
      <Text>
        <FormattedMessage id="benefitsSummaryIntro" />
      </Text>
      <Stack padding="base" gap="medium">
        {processData.potentialSavingCost && getPotentialCapitalSavedSection()}
        {processData.potentialSavingHours && getPotentialHoursSavedSection()}
      </Stack>
      <Text>
        <FormattedMessage id="benefitsSummaryConclusion" />
      </Text>
    </Stack>
  );
}

AssessmentBenefitsSummary.propTypes = {
  processData: PropTypes.object,
};
