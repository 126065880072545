import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PlugSocket = styled.g`
  stroke: #0a5280;
  stroke-width: 2;
  fill: none;
`;

const PlugnWire = styled.g``;

const Prismy = styled.g`
  stroke: #126285;
`;

export const ErrorTable = ({ width, height }) => (
  <svg viewBox="0 25 185 85" width={width} height={height}>
    <PlugSocket>
      <rect x="1" y="60" width="20" height="20" rx="2" />
      <circle cx="11" cy="70" r="5" />
      <line x1="9" y1="69" x2="9" y2="71" strokeLinecap="round" />
      <line x1="13" y1="69" x2="13" y2="71" strokeLinecap="round" />
    </PlugSocket>
    <PlugnWire>
      <path
        d="M24,96c47,0 116,0 145,0 2.7,0 4.5,0 6.4,1.3 1.5,1 1.6,1.7 1.8,3 0,1.3 0,1.7-1,2.5-1.8,1.6-5.7,1.7-8.4,1 -2.5,-1-2,-1-4.6,-3.1-5.2,-5.5-6.7,-19-8.6,-26"
        stroke="#82D3F7"
        strokeWidth="4"
        fill="none"
      />
      <path d="M28 95c0 6.3-6.7 7.4-9.7 7.4 0 0 1-2.8 1-7.4 0-4.2-1-7.3-1-7.3 s9.7 1 9.7 7.3z" fill="#82D3F7" />
      <ellipse cx="18" cy="95" rx="4.5" ry="7.5" fill="#A7E4FF" />
      <path
        d="M19 98a1 1 0 01-1 1l-8 0a1 1 0 0 1 0-2l8 0a1 1 0 011 1zM19 93a1 1 0 01-1 1l-8 0a1 1 0 1 1 0-2l8 0a1 1 0 011 1z"
        fill="#3CA2E1"
      />
    </PlugnWire>
    <Prismy>
      <path
        d="M106.7,26c-0.8,0 -1.5,0 -2.2,0.5 -1,0.5 -1.5,1.4 -1.6,3L90.3,97c0,1 0,2 0.7,3 0.6,0.8 1.6,1.3 2.7,1.3l53.7,0c1.2,0 2.5,-0.7 3.1,-2l25,-47.4c0.5,0 0.6,-2 0.3,-3 -0.5,-1 -1.3,-1.7 -2.3,-2L107.4,26c0,0 -0.5,0 -0.7,0z"
        fill="#82d3f7"
        strokeWidth="0"
      />
      <path d="M127,69.75l5.6,-3.36" />
      <path d="M120.6,69.6l3.6,-2.1" strokeLinecap="round" strokeWidth="2" />
      <path d="M131.6,63l3.6,-2.1" strokeLinecap="round" strokeWidth="2" />
    </Prismy>
  </svg>
);

ErrorTable.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
