import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@blueprism/ui-core/components/Button';
import Row from '@blueprism/ui-core/layout/Row';
import DialogBody from '../../components/DialogBody';
import DialogFooter from '../../components/DialogFooter';
import FormField from '@blueprism/ui-core/components/FormField';
import RadioButtonGroup from '@blueprism/ui-core/components/RadioButtonGroup';
import RadioButton from '@blueprism/ui-core/components/RadioButton';
import ArrowLeft from '@blueprism/ui-icons/ArrowLeft';
import ArrowRight from '@blueprism/ui-icons/ArrowRight';

export default function RegistrationQuestionRadio({
  index,
  fieldName,
  options = {},
  data = {},
  isInError = () => {},
  getErrorMessage = () => {},
  hidden = false,
  onChange = () => {},
  onNextClick = () => {},
  onPreviousClick = () => {},
  onValidate = () => {},
}) {
  const intl = useIntl();
  const handleChange = event => onChange(fieldName, event.target.value);

  async function handleNextClick(event) {
    try {
      await onValidate(fieldName, false);
    } catch (err) {
      return;
    }

    onNextClick(event);
  }
  return hidden ? null : (
    <>
      <DialogBody padding="none" gap="base">
        <FormField
          label={intl.formatMessage({ id: 'registrationQuestion-' + fieldName })}
          error={isInError(fieldName)}
          errorText={getErrorMessage(fieldName)}
          isFieldset
          standalone
        >
          <RadioButtonGroup name={fieldName} value={data[fieldName]?.toString()} onChange={handleChange}>
            {Object.entries(options).map(([key, val]) => (
              <RadioButton
                key={key}
                id={'form-radio-' + key}
                name={'form-radio-' + key}
                value={key.toString()}
                label={val}
              />
            ))}
          </RadioButtonGroup>
        </FormField>
      </DialogBody>
      <DialogFooter element="footer">
        <Row justify="between">
          <Button id="previousButton" onClick={() => onPreviousClick(index - 1)} iconLeft={<ArrowLeft />}>
            <FormattedMessage id="previous" />
          </Button>
          <Button id="continueButton" onClick={() => handleNextClick(index + 1)} iconRight={<ArrowRight />}>
            <FormattedMessage id="continue" />
          </Button>
        </Row>
      </DialogFooter>
    </>
  );
}

RegistrationQuestionRadio.propTypes = {
  index: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.object,
  data: PropTypes.object,
  getErrorMessage: PropTypes.func,
  isInError: PropTypes.func,
  onChange: PropTypes.func,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  onValidate: PropTypes.func,
};
