import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Option, DatalistIndexed, Box } from '@blueprism/ui-core';
import NumberInput from '../core/NumberInput/NumberInput';

export default function CurrencyFilter({
  enabled,
  fieldName,
  labelCurrency = '',
  helperTextCurrency,
  currencyOptions = [],
  labelMin = '',
  labelMax = '',
  helperTextMin = '',
  helperTextMax = '',
  isInErrorCurrency,
  isInErrorMin,
  isInErrorMax,
  errorTextCurrency,
  errorTextMin,
  errorTextMax,
  onChange = () => {},
}) {
  const [localValueMin, setLocalValueMin] = React.useState('');
  const [localValueMax, setLocalValueMax] = React.useState('');
  const lastCurrencyCode = React.useRef('');

  function handleValuesChange(newValues) {
    var filter = {};
    if (newValues[`${fieldName}MinAmount`]) {
      filter[`${fieldName}MinAmount`] = newValues[`${fieldName}MinAmount`];
    }

    if (newValues[`${fieldName}MaxAmount`]) {
      filter[`${fieldName}MaxAmount`] = newValues[`${fieldName}MaxAmount`];
    }

    if (newValues[`${fieldName}Currency`]) {
      filter[`${fieldName}Currency`] = newValues[`${fieldName}Currency`];
    }
    onChange({
      [`${fieldName}`]: {
        ...filter,
      },
    });
  }

  function handleMinValueChange({ target }) {
    setLocalValueMin(target.value);
    handleValuesChange({
      [`${fieldName}MinAmount`]: target.value,
      [`${fieldName}MaxAmount`]: localValueMax,
      [`${fieldName}Currency`]: lastCurrencyCode.current,
    });
  }

  function handleMaxValueChange({ target }) {
    setLocalValueMax(target.value);
    handleValuesChange({
      [`${fieldName}MinAmount`]: localValueMin,
      [`${fieldName}MaxAmount`]: target.value,
      [`${fieldName}Currency`]: lastCurrencyCode.current,
    });
  }

  function handleCurrencyValueChange(event) {
    const { value: currencyCode } = event.target;
    const isEnteredCodeValid = currencyOptions.some(option => option.currencyCode === currencyCode);

    if (
      (!isEnteredCodeValid || lastCurrencyCode.current === currencyCode) &&
      !(!!!currencyCode && lastCurrencyCode.current)
    ) {
      return;
    }

    lastCurrencyCode.current = currencyCode;

    handleValuesChange({
      [`${fieldName}MinAmount`]: localValueMin,
      [`${fieldName}MaxAmount`]: localValueMax,
      [`${fieldName}Currency`]: lastCurrencyCode.current,
    });
  }

  React.useEffect(() => {
    if (enabled && (localValueMin || localValueMax || lastCurrencyCode.current)) {
      handleValuesChange({
        [`${fieldName}MinAmount`]: localValueMin,
        [`${fieldName}MaxAmount`]: localValueMax,
        [`${fieldName}Currency`]: lastCurrencyCode.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <>
      <FormField
        gap="base"
        htmlFor={`currency-datalist-indexed-${fieldName}`}
        label={labelCurrency}
        helperText={helperTextCurrency}
        error={isInErrorCurrency}
        errorText={errorTextCurrency}
      >
        <DatalistIndexed
          id={`currency-datalist-indexed-${fieldName}`}
          error={isInErrorCurrency}
          onChange={handleCurrencyValueChange}
        >
          {currencyOptions.map(({ currencyCode, currencyName }) => (
            <Option key={currencyCode} value={currencyCode}>
              {currencyName}
            </Option>
          ))}
        </DatalistIndexed>
      </FormField>
      <FormField
        gap="base"
        htmlFor={`${fieldName}MinAmount`}
        label={labelMin}
        helperText={helperTextMin}
        error={isInErrorMin}
        errorText={errorTextMin}
      >
        <Box>
          <NumberInput id={`${fieldName}MinAmount`} value={localValueMin} onChange={handleMinValueChange} />
        </Box>
      </FormField>
      <FormField
        gap="base"
        htmlFor={`${fieldName}MaxAmount`}
        label={labelMax}
        helperText={helperTextMax}
        error={isInErrorMax}
        errorText={errorTextMax}
      >
        <Box>
          <NumberInput id={`${fieldName}MaxAmount`} value={localValueMax} onChange={handleMaxValueChange} />
        </Box>
      </FormField>
    </>
  );
}

CurrencyFilter.propTypes = {
  enabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  labelCurrency: PropTypes.string,
  helperTextCurrency: PropTypes.string,
  currencyOptions: PropTypes.array,
  labelMin: PropTypes.string,
  labelMax: PropTypes.string,
  helperTextMin: PropTypes.string,
  helperTextMax: PropTypes.string,
  isInErrorCurrency: PropTypes.bool,
  isInErrorMin: PropTypes.bool,
  isInErrorMax: PropTypes.bool,
  errorTextCurrency: PropTypes.arrayOf(PropTypes.string),
  errorTextMin: PropTypes.arrayOf(PropTypes.string),
  errorTextMax: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
