import React from 'react';
import PropTypes from 'prop-types';
import LoadingState from 'components/TableStates/LoadingState';
import ErrorState from 'components/TableStates/ErrorState';
import EmptyState from 'components/TableStates/EmptyState';
import { dataStates as tableDataStates } from '../../utils/dataStates';
import Table from '@blueprism/ui-core/components/Table';
import Stack from '@blueprism/ui-core/layout/Stack';

export default function TableLoader({
  columns,
  emptyStateTitle,
  emptyStateText,
  errorStateText,
  onHeaderClick,
  onRowSelectionChange,
  rowData,
  selectedIds,
  selectRows,
  sortColumns,
  tableLayout,
  tableStatus,
  errorCode,
}) {
  switch (tableStatus) {
    case tableDataStates.SUCCESS:
      return rowData.length > 0 ? (
        <Table
          columns={columns}
          onHeaderClick={onHeaderClick}
          onRowSelectionChange={onRowSelectionChange}
          rowData={rowData}
          selectedIds={selectedIds}
          selectRows={selectRows}
          sortColumns={sortColumns}
          tableLayout={tableLayout}
        />
      ) : (
        <EmptyState title={emptyStateTitle} text={emptyStateText} />
      );
    case tableDataStates.ERROR:
      return <ErrorState text={errorStateText} errorCode={errorCode} />;
    case tableDataStates.INIT:
    case tableDataStates.FETCHING:
    default:
      return (
        <Stack gap="base">
          <LoadingState />
        </Stack>
      );
  }
}

TableLoader.propTypes = {
  columns: PropTypes.array,
  emptyStateTitle: PropTypes.string,
  emptyStateText: PropTypes.string,
  errorStateText: PropTypes.string,
  onHeaderClick: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  rowData: PropTypes.array,
  selectedIds: PropTypes.array,
  selectRows: PropTypes.bool,
  sortColumns: PropTypes.bool,
  tableStatus: PropTypes.oneOf(Object.values(tableDataStates)),
  tableLayout: PropTypes.string,
  errorCode: PropTypes.string,
};
