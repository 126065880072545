import React from 'react';

export const ServerError = () => (
  <svg viewBox="0 0 1280 440">
    <g>
      <path
        d="M267 326.8c23.2 0 42-3.8 42-8.5 0-4.7-18.8-8.5-42-8.5s-42 3.8-42 8.5c0 4.7 18.8 8.5 42 8.5z"
        fill="#D9F3FF"
      />
      <path
        d="M406 173a18 18 0 010 1 28 28 0 01-1 3l-6 9c-2 2-6 5-11 6s-12 0-22-3a7 7 0 00-5 14c12 4 22 5 30 3 8-1 14-5 19-10 4-4 7-9 8-13a43 43 0 002-4v-2a21 21 0 001 0v-1l-8-1 8 1a7 7 0 00-15-3zm-155 86a7 7 0 015 9 89 89 0 000 43 47 47 0 000 1 7 7 0 01-14 4l7-2-7 2a53 53 0 010-1 92 92 0 01-1-4 121 121 0 01-2-11c0-10 0-23 3-36a7 7 0 019-5zm35 0a7 7 0 00-5 9 89 89 0 011 41 80 80 0 01-1 2 47 47 0 010 1 7 7 0 0014 4l-7-2 7 2a62 62 0 001-5 122 122 0 002-11c1-10 1-23-3-36a7 7 0 00-9-5zm-139-14a38 38 0 011-5 72 72 0 014-12c4-10 11-20 23-25a7 7 0 10-6-14c-17 8-26 22-31 33a87 87 0 00-5 15 75 75 0 00-1 5 50 50 0 000 1l7 1-7-1a7 7 0 0015 2z"
        fill="#2E8ECB"
      />
      <rect x="162" y="54" width="210" height="230" rx="16" fill="#2E8ECB" />
      <rect x="183" y="76" width="168" height="185" rx="13" fill="#0573B8" />
      <path
        d="M337 185l2-1h22l1 1v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 23l2-1h22l1 1v10a2 2 0 01-1 1h-22a2 2 0 01-2-1v-10zm0-69l2-2h22l1 2v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 93l2-2h22l1 2v9a2 2 0 01-1 1h-22a2 2 0 01-2-1v-9zm2-68l1-2h22l2 2v9a2 2 0 01-2 2h-22a2 2 0 01-1-2v-9zm-2-71l2-2h22l1 2v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 23l2-2h22l1 2v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zM227 274a2 2 0 01-2-2v-21l2-2h9l1 2v21a2 2 0 01-1 2h-9zm-23 0a2 2 0 01-2-2v-21l2-2h9l1 2v21a2 2 0 01-1 2h-9zm46 0a2 2 0 01-2-2v-21l2-2h9l1 2v21a2 2 0 01-1 2h-9zm23 0a2 2 0 01-2-2v-21l2-2h9l2 2v21a2 2 0 01-2 2h-9zm23 0a2 2 0 01-2-2v-21l2-2h9l2 2v21a2 2 0 01-2 2h-9zm23 0a2 2 0 01-2-2v-21l2-2h9l2 2v21a2 2 0 01-2 2h-9zM227 85a2 2 0 01-2-2V62l2-2h9l1 2v21a2 2 0 01-1 2h-9zm-23 0a2 2 0 01-2-2V62l2-2h9l1 2v21a2 2 0 01-1 2h-9zm46 0a2 2 0 01-2-2V62l2-2h9l1 2v21a2 2 0 01-1 2h-9zm23 0a2 2 0 01-2-2V62l2-2h9l2 2v21a2 2 0 01-2 2h-9zm23 0a2 2 0 01-2-2V62l2-2h9l2 2v21a2 2 0 01-2 2h-9zm23 0a2 2 0 01-2-2V62l2-2h9l2 2v21a2 2 0 01-2 2h-9zm-150 8l2-2h22l1 2v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 23l2-2h22l1 2v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 23l2-2h22l1 2v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 23l2-1h22l1 1v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 23l2-1h22l1 1v9a2 2 0 01-1 2h-22a2 2 0 01-2-2v-9zm0 23l2-1h22l1 1v10a2 2 0 01-1 1h-22a2 2 0 01-2-1v-10zm0 24l2-2h22l1 2v9a2 2 0 01-1 1h-22a2 2 0 01-2-1v-9z"
        fill="#34ACF8"
      />
      <path d="M292 159a4 4 0 10-9 4 4 0 000 9z" fill="#0A5280" />
      <path d="M290 163a4 4 0 100-9 4 4 0 000 9z" fill="#E7008A" />
      <path
        d="M311 141c4 0 8-4 8-9s-4-9-8-9-7 4-7 9 3 9 7 9zm-42 0c4 0 7-4 7-9s-3-9-7-9c-5 0-8 4-8 9s3 9 8 9z"
        fill="#0A5280"
      />
    </g>

    <g>
      <rect x="577" y="208" width="62" height="62" rx="4" fill="none" stroke="#0A5280" stroke-width="4" />
      <path stroke="#0A5280" stroke-width="4" fill="none" d="M624 240a16 16 0 11-32 0 16 16 0 0132 0z" />
      <rect x="600" y="235" width="5.5" height="11" rx="2" fill="#0A5280" />
      <rect x="611" y="235" width="5.5" height="11" rx="2" fill="#0A5280" />
    </g>

    <g>
      <path
        d="M410 113a6 6 0 015 5l1 1a438 438 0 000 6 649 649 0 0021 128c6 18 14 31 23 38 21 14 46 21 66 25a230 230 0 0024 3 175 175 0 009 1h504l-5-18c-6-23-8-48-7-64a6 6 0 016-6 6 6 0 015 6 252 252 0 0013 82h27v0a31 31 0 011 0 44 44 0 0111 2c3 1 7 3 9 6 3 3 4 7 3 12-1 6-5 9-9 11s-8 2-12 2l-15-2c-9-2-16-9-20-18l-2-2H560v-6 6a112 112 0 01-3 0 185 185 0 01-7-1 241 241 0 01-26-3c-21-4-47-12-70-27-12-9-21-25-28-43a662 662 0 01-21-115 659 659 0 010-17 445 445 0 010-5v-1h-1l6-1-6 1a6 6 0 016-6zm671 218c4 5 8 8 11 9h1l12 2 7-1c2-1 3-1 3-3v-3l-4-3a28 28 0 00-8-1h-22z"
        fill="#82D3F7"
      />
      <path fill="#82D3F7" d="M411 151c-19 0-22-20-22-29 0 0 8 3 22 3 12 0 21-3 21-3s-3 29-21 29z" />
      <path fill="#A7E4FF" d="M435 122s-9 7-25 7-25-7-25-7v-6s9 6 25 6 25-6 25-6v6z" />
      <path fill="#A7E4FF" d="M435 116s-13-5-25-5c-13 0-25 5-25 5s10 9 26 9c15 0 24-9 24-9z" />
      <path stroke="#3CA2E1" stroke-linecap="round" stroke-width="6" d="M404 116l-1-24m12 24l-1-24" />
    </g>

    <g>
      <path
        d="M892.7 118.2l-37.5 204 160.4 .4 75-142-198 -63z"
        fill="#82D3F7"
        stroke="#82D3F7"
        stroke-width="21"
        stroke-linejoin="round"
      />
      <rect x="967" y="216" width="13" height="6" rx="1.3" transform="rotate(-31 967 216)" fill="#126285" />
      <rect x="934" y="235" width="13" height="6" rx="1.32" transform="rotate(-31 934 235)" fill="#126285" />
      <path d="M956.2 237.5l13.3-8" stroke="#126285" stroke-width="4" stroke-linecap="square" stroke-linejoin="round" />
    </g>
  </svg>
);
