import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Row from '@blueprism/ui-core/layout/Row';
import Stack from '@blueprism/ui-core/layout/Stack';
import Button from '@blueprism/ui-core/components/Button';
import FormField from '@blueprism/ui-core/components/FormField';
import RadioButtonGroup from '@blueprism/ui-core/components/RadioButtonGroup';
import RadioButton from '@blueprism/ui-core/components/RadioButton';
import PATDialog from 'components/PATDialog';
import { GroupMembersContext } from 'contexts/GroupMembersContext';
import { GroupContext } from 'contexts/GroupContext';
import { UserContext } from 'contexts/UserContext';
import DialogTitle from 'components/DialogTitle';
import User from 'components/User';
import { config } from 'utils/config';

export default function GroupMemberEditDialog({ selectedRowIds = [], clearSelection = () => {} }) {
  const intl = useIntl();
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const { reloadGroup } = React.useContext(GroupContext);
  const { groupMembers, handleEditMemberClose, editGroupMember } = React.useContext(GroupMembersContext);
  const [role, setRole] = React.useState('');
  const [errorText, setErrorText] = React.useState('');

  const selectedMember = groupMembers?.members?.items.find(x => x.memberId.toString() === selectedRowIds[0]);

  React.useEffect(() => {
    if (!selectedMember) {
      return;
    }

    setRole(selectedMember.privLevel);
    setErrorText('');
  }, [groupMembers.isEditOpen, selectedMember]);

  if (!selectedMember) {
    return null;
  }

  const teamText = ' (' + intl.formatMessage({ id: 'team' }) + ')';
  const name = selectedMember.name + (selectedMember.memberType === 1 ? teamText : '');
  const avatar = selectedMember.avatar && config.imageUrl(selectedMember.avatar);

  function handleRoleChange(event) {
    setRole(parseInt(event.target.value));
  }

  function handleCancelClick() {
    handleEditMemberClose();
  }

  async function handleEditClick() {
    const result = await editGroupMember(selectedMember.memberId, role, clearSelection);
    switch (result?.status) {
      case 403:
        history.push('/login');
        return;
      case 401:
        setErrorText(intl.formatMessage({ id: 'editGroupMember401Error' }));
        return;
      case 500:
      case 400:
        setErrorText(intl.formatMessage({ id: 'editGroupMember500Error' }));
        return;
      default:
        setErrorText('');
        const isReloadRequired = selectedMember.memberType === 0 && selectedMember.memberId === user.details.userId;
        if (isReloadRequired) {
          reloadGroup();
        }
        return;
    }
  }

  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={handleCancelClick}>
          <FormattedMessage id="editMemberCancel" />
        </Button>
        <Button onClick={handleEditClick}>
          <FormattedMessage id="editMemberOK" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="large"
      overlay
      errorText={errorText}
      visible={groupMembers.isEditOpen}
      onClose={handleEditMemberClose}
      aria-labelledby="group-member-edit-dialog-title"
      headerContent={
        <DialogTitle id="group-member-edit-dialog-title">
          <FormattedMessage id="editMemberTitle" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <Stack gap="medium">
        <User userName={name} avatarImage={avatar} />
        <FormField label={intl.formatMessage({ id: 'editMemberRoleHeading' })}>
          <RadioButtonGroup name="role" onChange={handleRoleChange} value={role}>
            <RadioButton
              id="member-role-radio-0"
              name="member-role-radio-0"
              value={1}
              label={intl.formatMessage({ id: `priv1Name` })}
            />
            <RadioButton
              id="member-role-radio-1"
              name="member-role-radio-1"
              value={2}
              label={intl.formatMessage({ id: `priv2Name` })}
            />
          </RadioButtonGroup>
        </FormField>
      </Stack>
    </PATDialog>
  );
}
