import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import PropTypes from 'prop-types';
import { dataStates as tableDataStates } from '../utils/dataStates';
import { spaceManagement } from 'utils/api';

export const GroupContext = createContext();

export default function GroupContextProvider({ children }) {
  const history = useHistory();
  const { getUser } = React.useContext(AuthContext);
  const [groupDetails, setGroupDetails] = useState();
  const [groupId, setGroupId] = useState();
  const [tableStatus, setTableStatus] = React.useState(tableDataStates.INIT);

  useEffect(() => {
    if (groupId && (!groupDetails || groupDetails.groupId !== groupId)) {
      spaceManagement
        .getGroup(getUser, groupId)
        .then(response => {
          setGroupDetails(response);
          setTableStatus(tableDataStates.SUCCESS);
        })
        .catch(err => {
          switch (err?.response?.status) {
            case 403:
              history.push('/login');
              return;
            case 400:
            case 500:
              history.push('/servererror');
              return;
            case 401:
              history.push('/unauthorised');
              return;
            default:
              setTableStatus(tableDataStates.ERROR);
          }
        });
    }
  }, [groupId, groupDetails, getUser, history]);

  const reloadGroup = () => setGroupDetails(null);

  return (
    <GroupContext.Provider
      value={{
        tableStatus,
        groupDetails,
        setGroupId,
        reloadGroup,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
}

GroupContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
