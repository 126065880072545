import * as React from 'react';

export default function useTableRowSelection(initialRows = [], singleSelection = false) {
  const [selectedRowIds, setSelectedRowIds] = React.useState(initialRows);

  function onRowSelectionChange(row) {
    const { id, selected } = row;

    setSelectedRowIds(prevSelectedIds => {
      if (selected) {
        if (singleSelection) return [id];

        return [...prevSelectedIds, id];
      }

      return prevSelectedIds.filter(prevId => prevId !== id);
    });
  }

  function clearSelection() {
    setSelectedRowIds([]);
  }

  return {
    selectedRowIds,
    onRowSelectionChange,
    clearSelection,
  };
}
