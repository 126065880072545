import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import Table from '@blueprism/ui-core/components/Table';
import { AuthContext } from 'contexts/AuthContext';
import TablePagination from 'components/TablePagination';
import User from 'components/User';
import { toUInt } from 'utils/helper';
import { config } from 'utils/config';
import { spaceManagement } from 'utils/api';

export default function SpaceAdmins() {
  const intl = useIntl();
  const history = useHistory();
  const params = useParams();
  const { getUser } = React.useContext(AuthContext);

  const [admins, setAdmins] = React.useState({});

  const spaceId = toUInt(params.spaceId);
  const offset = toUInt(params.offset, 0);
  const limit = toUInt(params.limit, 25);

  React.useEffect(() => {
    spaceManagement
      .getSpaceAdmins(getUser, spaceId, offset, limit)
      .then(response => {
        setAdmins(response);
      })
      .catch(err => console.error(err));
  }, [getUser, spaceId, offset, limit]);

  const handlePreviousData = () =>
    history.push(`/your-spaces/space/${spaceId}/admins/${Math.max(0, offset - limit)}/${limit}`);
  const handleNextData = () => history.push(`/your-spaces/space/${spaceId}/admins/${offset + limit}/${limit}`);
  const handleLimitChange = newLimit => history.push(`/your-spaces/space/${spaceId}/admins/${offset}/${newLimit}`);

  const columns = [
    {
      header: intl.formatMessage({ id: 'spaceMembersColumn1' }),
      accessor: 'userDetails',
    },
  ];

  const mapData = () =>
    admins.items?.map(user => ({
      id: user.memberId.toString(),
      userDetails: <User userName={user.name} avatarImage={user.avatar && config.imageUrl(user.avatar)} />,
    }));

  return (
    <>
      <Table tableLayout="auto" rowData={mapData()} columns={columns} />
      <TablePagination
        limitLabelId="your-spaces-limit"
        itemName="Users"
        limit={limit}
        offset={offset}
        count={admins.count ?? 0}
        onLimitChange={handleLimitChange}
        onNextData={handleNextData}
        onPreviousData={handlePreviousData}
      />
    </>
  );
}
