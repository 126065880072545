import * as React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { TeamsContext } from 'contexts/TeamsContext';
import TablePagination from 'components/TablePagination';
import Link from 'components/Link';
import { toUInt } from 'utils/helper';
import TableLoader from 'components/TableStates/TableLoader';

export default function SpaceTeams({ selectedRowIds = [], onRowSelectionChange = () => {} }) {
  const intl = useIntl();
  const history = useHistory();
  const { tableStatus, team } = React.useContext(TeamsContext);

  const spaceId = team.spaceId;
  const offset = toUInt(team.offset, 0);
  const limit = toUInt(team.limit, 25);
  const count = team?.teams?.count ?? 0;

  function handlePreviousData() {
    history.push(`/your-spaces/space/${spaceId}/teams/${Math.max(0, offset - limit)}/${limit}`);
  }

  function handleNextData() {
    history.push(`/your-spaces/space/${spaceId}/teams/${offset + limit}/${limit}`);
  }

  function handleLimitChange(newLimit) {
    history.push(`/your-spaces/space/${spaceId}/teams/${offset}/${newLimit}`);
  }

  const columns = [
    {
      header: intl.formatMessage({ id: 'spaceTeamsColumn1' }),
      accessor: 'teamDetails',
    },
  ];

  const rowData = () => {
    if (team.teams) {
      return team.teams.items.map(teamItem => ({
        id: teamItem.teamId.toString(),
        team: teamItem,
        teamDetails: (
          <Link id={`team${teamItem.teamId}Link`} href={'/your-spaces/team/' + teamItem.teamId}>
            {teamItem.name}
          </Link>
        ),
      }));
    }

    return [];
  };

  return (
    <>
      <TableLoader
        tableStatus={tableStatus}
        emptyStateTitle={intl.formatMessage({ id: 'teamsTableNoDataTitle' })}
        emptyStateText={intl.formatMessage({ id: 'teamsTableNoDataText' })}
        errorStateText={intl.formatMessage({ id: 'teamsTableDataFetchFail' })}
        rowData={rowData()}
        columns={columns}
        selectRows
        selectedIds={selectedRowIds}
        onRowSelectionChange={onRowSelectionChange}
      />
      <TablePagination
        limitLabelId="space-teams-limit"
        itemName="Teams"
        limit={limit}
        offset={offset}
        count={count}
        onLimitChange={handleLimitChange}
        onNextData={handleNextData}
        onPreviousData={handlePreviousData}
      />
    </>
  );
}
