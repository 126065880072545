import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import SideBarNavItem from '@blueprism/ui-core/components/SideBarNavItem';
import SideBarNavItemContainer from '@blueprism/ui-core/components/SideBarNavItemContainer';
import { default as PdtSidebar } from '@blueprism/ui-core/components/SideBar';
import NavHeader from '@blueprism/ui-core/components/NavHeader';
import Menu from '@blueprism/ui-core/components/Menu';
import Button from '@blueprism/ui-core/components/Button';
import { Processes, Tick, Space } from '@blueprism/ui-icons';
import { AuthContext } from 'contexts/AuthContext';
import { UserContext } from 'contexts/UserContext';
import LinkList from './LinkList';
import SidebarFooter from 'components/Sidebar/SidebarFooter';
import { config } from 'utils/config';

export default function Sidebar() {
  const [collapsed, setCollapsed] = React.useState(false);
  const headerRef = React.useRef();
  const [menuVisible, setMenuVisible] = React.useState(false);
  const { logout } = React.useContext(AuthContext);
  const { user } = React.useContext(UserContext);
  const intl = useIntl();

  function handleMenuClose() {
    setMenuVisible(false);
  }

  function handleAvatarClick() {
    setMenuVisible(menuVisibleValue => !menuVisibleValue);
  }

  function handleEditProfileClick() {
    window.location.href = config.editProfileUrl(user.details.externalUserId);
  }

  function handleLogoutClick() {
    logout();
  }

  const onChange = () => {
    setCollapsed(!collapsed);
  };

  return (
    <PdtSidebar onChange={onChange} collapsed={collapsed}>
      <NavHeader
        label={`${user.details.firstName} ${user.details.lastName}`}
        message={intl.formatMessage({ id: 'headerTitle' })}
        avatarImage={user.details.avatarImage && config.imageUrl(user.details.avatarImage)}
        displayAvatarCogIcon
        ref={headerRef}
        onAvatarClick={handleAvatarClick}
      />

      <Menu visible={menuVisible} anchorRef={headerRef} anchor="bottom" onClose={handleMenuClose}>
        <Button onClick={handleEditProfileClick}>
          <FormattedMessage id="profileMenuEditProfile" />
        </Button>
        <Button onClick={handleLogoutClick}>
          <FormattedMessage id="profileMenuLogout" />
        </Button>
      </Menu>
      <SideBarNavItemContainer>
        <SideBarNavItem
          to="/your-processes"
          label={intl.formatMessage({ id: 'navBarMenuItem1' })}
          icon={<Processes />}
        />
        <SideBarNavItem
          to="/process-assessment"
          label={intl.formatMessage({ id: 'navBarMenuItem2' })}
          icon={<Tick />}
        />
        <SideBarNavItem to="/your-spaces" label={intl.formatMessage({ id: 'navBarMenuItem3' })} icon={<Space />} />
      </SideBarNavItemContainer>
      <LinkList avatarImage={user.details.avatarImage} firstName={user.details.firstName} />
      <SidebarFooter appName={intl.formatMessage({ id: 'headerTitle' })} />
    </PdtSidebar>
  );
}
