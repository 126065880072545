import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import Login from 'pages/Login';
import OidcCallback from 'pages/OidcCallback';
import OidcLogoutCallback from 'pages/OidcLogoutCallback';
import ServerErrorPage from 'pages/Errors/ServerErrorPage';

export default function AuthenticationGuard({ children }) {
  const { isLoggedIn, login } = React.useContext(AuthContext);

  if (isLoggedIn) {
    return children;
  }

  return (
    <Switch>
      <Route path="/login" exact render={props => <Login {...props} login={login} />} />
      <Route path="/servererror" exact component={ServerErrorPage} />
      <Route path="/oidc-callback" exact component={OidcCallback} />
      <Route path="/oidc-logout-callback" exact component={OidcLogoutCallback} />
      <Redirect to="/login" />
    </Switch>
  );
}

AuthenticationGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
