import * as React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AuthContext } from 'contexts/AuthContext';
import { UserContext } from 'contexts/UserContext';
import { spaceManagement } from 'utils/api';
import { dataStates as tableDataStates } from '../utils/dataStates';

export const UsersContext = React.createContext();

export default function UsersContextProvider({ children }) {
  const intl = useIntl();
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const { getUser } = React.useContext(AuthContext);
  const [tableStatus, setTableStatus] = React.useState(tableDataStates.INIT);

  const [userContext, setUserContext] = React.useState({
    isActive: false,
    spaceId: null,
    users: null,
    isAddOpen: false,
    isEditOpen: false,
    isRemoveOpen: false,
    offset: null,
    limit: null,
    alertText: '',
    isAlertOpen: false,
  });

  React.useEffect(() => {
    if (userContext.isActive && !userContext.users && userContext.spaceId) {
      spaceManagement
        .getSpaceUsers(getUser, userContext.spaceId, userContext.offset, userContext.limit)
        .then(response => {
          setUserContext({ ...userContext, users: response });
          setTableStatus(tableDataStates.SUCCESS);
        })
        .catch(err => {
          if (err.response && err.response.status && err.response.status === 401) {
            history.replace(`/your-spaces/space/${userContext.spaceId}/groups`);
          } else {
            console.error(err);
            setTableStatus(tableDataStates.ERROR);
          }
        });
    }
  }, [userContext, history, getUser]);

  const reloadUsers = () => setUserContext({ ...userContext, users: null });
  const openAddDialog = () => setUserContext({ ...userContext, isAddOpen: true });
  const handleAddClose = () => setUserContext({ ...userContext, isAddOpen: false });

  const addSpaceUser = (email, isAdmin, clearSelection = () => {}) =>
    spaceManagement
      .addSpaceUser(getUser, userContext.spaceId, email, isAdmin)
      .then(r => {
        setUserContext({ ...userContext, isAddOpen: false, users: null });
        clearSelection();
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 400:
            return err.response.data;
          case 401:
            return { dialogError: intl.formatMessage({ id: 'addSpaceUser401' }) };
          case 403:
            history.push('/login');
            return;
          case 404:
            return err.response.data;
          case 409:
            return { dialogError: err.response.data };
          case 500:
            return { dialogError: intl.formatMessage({ id: 'addSpaceUser500' }) };
          default:
            setUserContext({ ...userContext, isAddOpen: false });
            break;
        }
      });

  const openEditDialog = selectedUser =>
    setUserContext({
      ...userContext,
      isEditOpen: true,
    });
  const handleEditClose = () => setUserContext({ ...userContext, isEditOpen: false });
  const editSpaceUser = (selectedUser, isAdmin, clearSelection) =>
    spaceManagement
      .editSpaceUser(getUser, userContext.spaceId, selectedUser.member.userId, isAdmin)
      .then(() => {
        if (!isAdmin && selectedUser.member.userId === user.details.userId) {
          setUserContext({
            ...userContext,
            isEditOpen: false,
            users: null,
            isActive: false,
          });
        } else {
          setUserContext({ ...userContext, isEditOpen: false, users: null });
        }
        clearSelection();
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 401:
            return { dialogError: intl.formatMessage({ id: 'editSpaceUser401' }) };
          case 403:
            history.push('/login');
            return;

          case 409:
            setUserContext({
              ...userContext,
              isAlertOpen: true,
              alertText: intl.formatMessage({ id: 'spaceMembers1AdminRequired' }),
              isEditOpen: false,
            });
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'editSpaceUser500' }) };

          default:
            setUserContext({ ...userContext, isEditOpen: false });
            break;
        }
      });

  const openRemoveDialog = () => setUserContext({ ...userContext, isRemoveOpen: true });
  const handleRemoveClose = () => setUserContext({ ...userContext, isRemoveOpen: false });
  const removeSpaceUser = (userId, clearSelection = () => {}) =>
    spaceManagement
      .removeSpaceUser(getUser, userContext.spaceId, userId)
      .then(() => {
        if (userId === user.details.userId) {
          setUserContext({ ...userContext, isRemoveOpen: false });
          history.push(`/your-spaces`);
        } else {
          setUserContext({ ...userContext, isRemoveOpen: false, users: null });
          clearSelection();
        }
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 401:
            return { dialogError: intl.formatMessage({ id: 'removeSpaceUser401' }) };
          case 403:
            history.push('/login');
            return;

          case 409:
            setUserContext({
              ...userContext,
              isAlertOpen: true,
              alertText: intl.formatMessage({ id: 'spaceMembers1AdminRequired' }),
              isRemoveOpen: false,
            });
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'removeSpaceUser500' }) };

          default:
            setUserContext({ ...userContext, isRemoveOpen: false });
            break;
        }
      });

  const onAlertOk = () => setUserContext({ ...userContext, isAlertOpen: false });

  const setUsersActive = isActiveParam => {
    if (isActiveParam === userContext.isActive) {
      return;
    }
    setUserContext({ ...userContext, isActive: isActiveParam });
  };

  const updateUserParams = (spaceId, limit, offset) => {
    if (userContext.spaceId === spaceId && userContext.limit === limit && userContext.offset === offset) {
      return;
    }

    setUserContext({ ...userContext, spaceId, limit, offset, users: null });
  };

  return (
    <UsersContext.Provider
      value={{
        tableStatus,
        userContext,
        openAddDialog,
        handleAddClose,
        addSpaceUser,
        openEditDialog,
        handleEditClose,
        editSpaceUser,
        openRemoveDialog,
        handleRemoveClose,
        removeSpaceUser,
        reloadUsers,
        onAlertOk,
        setUsersActive,
        updateUserParams,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

UsersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
