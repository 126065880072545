import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import TablePagination from 'components/TablePagination';
import User from 'components/User';
import TableLoader from 'components/TableStates/TableLoader';
import { config } from 'utils/config';

const GroupMembersList = ({
  tableStatus,
  data,
  groupId,
  offset,
  limit,
  isAdmin = false,
  selectedRowIds = [],
  onRowSelectionChange = () => {},
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handlePreviousData = () =>
    history.push(`/your-spaces/group/${groupId}/members/${Math.max(0, offset - limit)}/${limit}`);
  const handleNextData = () => history.push(`/your-spaces/group/${groupId}/members/${offset + limit}/${limit}`);
  const handleLimitChange = newLimit => history.push(`/your-spaces/group/${groupId}/members/${offset}/${newLimit}`);

  const count = data ? data.count : 0;

  const teamText = ' (' + intl.formatMessage({ id: 'team' }) + ')';
  const columns = [
    {
      header: intl.formatMessage({ id: 'groupMembersColumn1' }),
      accessor: 'member',
    },
    {
      header: intl.formatMessage({ id: 'groupMembersColumn2' }),
      accessor: 'role',
    },
  ];

  const rowData = () => {
    if (data) {
      return data.items.map(x => ({
        id: x.memberId.toString(),
        memberData: x,
        disableSelect: !isAdmin,
        member: (
          <User
            userName={x.name + (x.memberType === 1 ? teamText : '')}
            avatarImage={x.avatar && config.imageUrl(x.avatar)}
          />
        ),
        role: intl.formatMessage({ id: `priv${x.privLevel}Name` }),
      }));
    }

    return [];
  };

  return (
    <>
      <TableLoader
        tableStatus={tableStatus}
        emptyStateTitle={intl.formatMessage({ id: 'spaceGroupMembersTableNoDataTitle' })}
        emptyStateText={intl.formatMessage({ id: 'spaceGroupMembersTableNoDataText' })}
        errorStateText={intl.formatMessage({ id: 'spaceGroupMembersTableDataFetchFail' })}
        rowData={rowData()}
        columns={columns}
        selectRows
        disableSelectAll
        selectedIds={selectedRowIds}
        onRowSelectionChange={onRowSelectionChange}
      />
      <TablePagination
        itemName="Members"
        limitLabelId="group-members-limit"
        limit={limit}
        offset={offset}
        count={count}
        onLimitChange={handleLimitChange}
        onNextData={handleNextData}
        onPreviousData={handlePreviousData}
      />
    </>
  );
};

GroupMembersList.propTypes = {
  data: PropTypes.object,
  onSelectionChanged: PropTypes.func,
  groupId: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default GroupMembersList;
