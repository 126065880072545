import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { GroupsContext } from 'contexts/GroupsContext';
import TablePagination from 'components/TablePagination';
import Link from 'components/Link';
import TableLoader from 'components/TableStates/TableLoader';
export default function SpaceGroupsList({ selectedRowIds = [], onRowSelectionChange = () => {} }) {
  const intl = useIntl();
  const history = useHistory();
  const { tableStatus, group } = React.useContext(GroupsContext);

  function handleLimitChange(newLimit) {
    history.push(`/your-spaces/space/${group.spaceId}/groups/${group.offset}/${newLimit}`);
  }

  function handleNextData() {
    history.push(`/your-spaces/space/${group.spaceId}/groups/${group.offset + group.limit}/${group.limit}`);
  }

  function handlePreviousData() {
    history.push(
      `/your-spaces/space/${group.spaceId}/groups/${Math.max(0, group.offset - group.limit)}/${group.limit}`
    );
  }

  const count = group.groups?.count ?? 0;

  const columns = [
    {
      header: intl.formatMessage({ id: 'spaceGroupsColumn1' }),
      accessor: 'nameColumn',
    },
  ];

  const rowData = () => {
    if (group.groups?.items?.length > 0) {
      return group.groups.items.map(groupItem => ({
        id: groupItem.id.toString(),
        group: groupItem,
        nameColumn: (
          <Link id={`group${groupItem.id}Link`} href={`/your-spaces/group/${groupItem.id}`}>
            {groupItem.name}
          </Link>
        ),
        disableSelect: !groupItem.isAdmin,
      }));
    }

    return [];
  };

  return (
    <>
      <TableLoader
        tableStatus={tableStatus}
        emptyStateTitle={intl.formatMessage({ id: 'spaceGroupsTableNoDataTitle' })}
        emptyStateText={intl.formatMessage({ id: 'spaceGroupsTableNoDataText' })}
        errorStateText={intl.formatMessage({ id: 'spaceGroupsTableDataFetchFail' })}
        rowData={rowData()}
        columns={columns}
        selectRows
        selectedIds={selectedRowIds}
        onRowSelectionChange={onRowSelectionChange}
      />
      <TablePagination
        limitLabelId="space-groups-limit"
        itemName="Groups"
        limit={group.limit}
        offset={group.offset}
        count={count}
        onLimitChange={handleLimitChange}
        onNextData={handleNextData}
        onPreviousData={handlePreviousData}
      />
    </>
  );
}

SpaceGroupsList.propTypes = {
  selectedRowIds: PropTypes.array,
  onRowSelectionChange: PropTypes.func,
};
