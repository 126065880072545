import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Row, Box, Text } from '@blueprism/ui-core';
import PATLogo from 'components/Sidebar/PATLogo';

const Container = styled(Box)`
  position: relative;
  height: 5.25rem;
  width: 100%;
`;

const Svg = styled.svg(
  props => css`
    position: absolute;
    transition: all 250ms ease;
    bottom: 0;
    z-index: -1;
    ${props.collapsed
      ? css`
          width: 80px;
          height: 64px;
        `
      : css`
          width: 100px;
          height: 92px;
        `}
  `
);

const Title = styled(Text)(
  props => css`
    padding-left: 2rem;
    white-space: nowrap;
    transition: opacity 250ms ease;
    opacity: ${props.collapsed ? '0' : '1'};
  `
);

const StyledRow = styled(Row)(
  props => css`
    height: 100%;
    align-items: center;
    padding: 1rem 0 0 0.5rem;

    svg {
      transition: all 250ms ease;
      width: ${props.collapsed ? '32px' : '48px'};
      padding-top: ${props.collapsed ? '1rem' : '0'};
      flex-shrink: 0;
    }
  `
);

export default function SidebarFooter({ collapsed = false, appName }) {
  return (
    <Container>
      <Svg viewBox="0 0 112 96" collapsed={collapsed}>
        <path
          d="M8.3 1.7C11.2 -1.2 16.2 -1.6 19 1.7L86 77.6C89 81 88.1 86.1 84.3 88.4L17.4 128.8C15 130.2 12.2 130.2 10 128.9L-56.9 88.4C-60.8 86.1 -61.6 80.9 -58.6 77.6L8.3 1.7Z"
          fill="#3CA2E1"
        />
      </Svg>
      <StyledRow collapsed={collapsed}>
        <PATLogo />
        <Title collapsed={collapsed}>{appName}</Title>
      </StyledRow>
    </Container>
  );
}

SidebarFooter.propTypes = {
  collapsed: PropTypes.bool,
  appName: PropTypes.string.isRequired,
};
