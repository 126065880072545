import * as React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AuthContext } from 'contexts/AuthContext';
import { spaceManagement } from 'utils/api';

export const SpaceContext = React.createContext();

export default function SpaceContextProvider({ children }) {
  const history = useHistory();
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const [space, setSpace] = React.useState();
  const [spaceId, setSpaceId] = React.useState();
  const [isRemoveSelfOpen, setIsRemoveSelfOpen] = React.useState(false);
  const [errorText, setErrorText] = React.useState();

  React.useEffect(() => {
    if (spaceId && (!space || space.id !== spaceId)) {
      spaceManagement
        .getSpace(getUser, spaceId)
        .then(response => {
          setSpace(response);
        })
        .catch(err => {
          if (err.response.status === 403) {
            history.push('/login');
          } else if (err.response.status === 401) {
            history.push('/unauthorised');
          } else if (err.response.status === 500) {
            history.push('/servererror');
          }
        });
    }
  }, [spaceId, space, history, getUser]);

  const reloadSpace = () => setSpace(null);

  const openRemoveSelfDialog = () => setIsRemoveSelfOpen(true);
  const handleRemoveSelfClose = () => {
    setIsRemoveSelfOpen(false);
    setErrorText('');
  };
  const removeSelf = () =>
    spaceManagement
      .removeSelfFromSpace(getUser, spaceId)
      .then(() => history.push('/your-spaces/'))
      .catch(err => {
        if (err.response.status === 403) {
          history.push('/login');
        } else if (err.response.status === 401) {
          history.push('/your-spaces');
        } else if (err.response.status === 500) {
          setErrorText(intl.formatMessage({ id: 'leaveSpace500Error' }));
        } else {
          history.push('/servererror');
        }
      });

  return (
    <SpaceContext.Provider
      value={{
        errorText,
        space,
        setSpaceId,
        reloadSpace,
        isRemoveSelfOpen,
        openRemoveSelfDialog,
        handleRemoveSelfClose,
        removeSelf,
      }}
    >
      {children}
    </SpaceContext.Provider>
  );
}

SpaceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
