import React, { createContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { dataStates as tableDataStates } from '../utils/dataStates';
import { spaceManagement } from 'utils/api';

export const GroupMembersContext = createContext();

const GroupMembersContextProvider = props => {
  const history = useHistory();
  const { getUser } = React.useContext(AuthContext);
  const [tableStatus, setTableStatus] = React.useState(tableDataStates.INIT);

  const [groupMembers, setGroupMembers] = useState({
    isActive: false,

    spaceId: null,
    groupId: null,

    members: null,

    isAddOpen: false,
    isEditOpen: false,
    isRemoveOpen: false,
    offset: null,
    limit: null,
  });

  useEffect(() => {
    if (
      !groupMembers.isActive ||
      groupMembers.members ||
      groupMembers.groupId == null ||
      groupMembers.limit == null ||
      groupMembers.offset == null ||
      groupMembers.spaceId == null
    ) {
      return;
    }

    spaceManagement
      .getGroupMembers(getUser, groupMembers.spaceId, groupMembers.groupId, groupMembers.offset, groupMembers.limit)
      .then(response => {
        setTableStatus(tableDataStates.SUCCESS);
        setGroupMembers({ ...groupMembers, members: response });
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 401:
            history.push('/unauthorised');
            return;
          case 403:
            history.push('/login');
            return;
          case 400:
          case 500:
            history.push('/servererror');
            return;
          default:
            console.error(err);
            setTableStatus(tableDataStates.ERROR);

            return;
        }
      });
  }, [groupMembers, getUser, history]);

  const updateGroupMembersParams = (limit, offset) => {
    if (groupMembers.limit === limit && groupMembers.offset === offset) {
      return;
    }

    setGroupMembers({ ...groupMembers, limit, offset, members: null });
  };

  const updateGroupMembersSpace = (spaceId, groupId, isAdmin) => {
    if (spaceId === groupMembers.spaceId && groupId === groupMembers.groupId && isAdmin === groupMembers.isSpaceAdmin) {
      return;
    }
    setGroupMembers({ ...groupMembers, spaceId, groupId, isSpaceAdmin: isAdmin, members: null });
  };

  const openAddMemberDialog = () => setGroupMembers({ ...groupMembers, isAddOpen: true });
  const handleAddMemberClose = () => setGroupMembers({ ...groupMembers, isAddOpen: false });

  const addGroupMember = ({ groupMemberId = 0, groupMemberType, privLevel, clearSelection = () => {} }) =>
    spaceManagement
      .addGroupMember(getUser, groupMembers.groupId, groupMemberId, groupMemberType, privLevel)
      .then(() => {
        setGroupMembers({ ...groupMembers, isAddOpen: false, members: null });
        clearSelection();
      })
      .catch(err => {
        if (err.response) {
          return err.response;
        }

        console.log(err);
      });

  const openRemoveMemberDialog = () => setGroupMembers({ ...groupMembers, isRemoveOpen: true });
  const handleRemoveMemberClose = () => setGroupMembers({ ...groupMembers, isRemoveOpen: false });

  const removeGroupMember = async (groupMemberId, clearSelection) => {
    const groupMemberToRemove = groupMembers.members.items.find(x => x.memberId === groupMemberId);

    try {
      await spaceManagement.removeMemberFromGroup(getUser, groupMembers.groupId, groupMemberToRemove.permissionId);
    } catch (err) {
      if (err.response) {
        return err.response;
      }

      console.log(err);
      return;
    }

    setGroupMembers({ ...groupMembers, isRemoveOpen: false, members: null });
    clearSelection();
  };

  const openEditMemberDialog = () => setGroupMembers({ ...groupMembers, isEditOpen: true });
  const handleEditMemberClose = () => setGroupMembers({ ...groupMembers, isEditOpen: false });

  const editGroupMember = async (groupMemberId, privLevel, clearSelection) => {
    const groupMemberToEdit = groupMembers.members.items.find(x => x.memberId === groupMemberId);
    try {
      await spaceManagement.editGroupMember(getUser, groupMembers.groupId, groupMemberToEdit.permissionId, privLevel);
    } catch (err) {
      if (err.response) {
        return err.response;
      }

      if (err.message) {
        console.log(err.message);
      }
    }

    setGroupMembers({ ...groupMembers, isEditOpen: false, members: null });
    clearSelection();
  };

  const setGroupMembersActive = isActiveParam => {
    if (isActiveParam === groupMembers.isActive) {
      return;
    }
    setGroupMembers({ ...groupMembers, isActive: isActiveParam });
  };

  return (
    <GroupMembersContext.Provider
      value={{
        tableStatus,
        groupMembers,
        updateGroupMembersParams,
        openAddMemberDialog,
        handleAddMemberClose,
        addGroupMember,
        setGroupMembersActive,
        updateGroupMembersSpace,
        openRemoveMemberDialog,
        handleRemoveMemberClose,
        removeGroupMember,
        openEditMemberDialog,
        handleEditMemberClose,
        editGroupMember,
      }}
    >
      {props.children}
    </GroupMembersContext.Provider>
  );
};

export default withRouter(GroupMembersContextProvider);
