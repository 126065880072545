import * as React from 'react';
import styled from 'styled-components';

const LockPrism = styled.g`
  fill: #7d3354;
`;

const Shadows = styled.g`
  fill: #d9f3ff;
`;

const Blue = styled.g`
  fill: #2e8ecb;
`;

export const Unauthorised = () => (
  <svg viewBox="0 0 1280 440">
    <Shadows>
      <ellipse cx="303" cy="425" rx="80" ry="10" />
      <ellipse cx="1010" cy="425" rx="80" ry="10" />
    </Shadows>

    <LockPrism>
      <path
        fill="none"
        stroke="#E287AF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="39"
        d="M1120 167l-14 133m14-136l4-40c6-51-32-98-83-103v0c-52-6-99 32-104 84l-14 138"
      />
      <path
        fill="#CE3779"
        stroke="#CE3779"
        stroke-linejoin="round"
        stroke-width="25"
        d="M1033 100L852 267l151 114 172-81-142-200z"
      />
      <path d="M1034 264a24 24 0 007-15 24 24 0 00-48-5 24 24 0 004 16l-6 19c-3 7 2 15 10 16l21 2c8 1 15-6 14-13l-2-20zM994 206a8 8 0 018-4 8 8 0 018 9l-1 2-4-1 1-2v-1a4 4 0 00-4-4 4 4 0 00-5 3 4 4 0 000 1v2h-4v-2a8 8 0 011-3zm38 7c0-5 4-8 9-7a8 8 0 017 9v2l-4-1v-2a4 4 0 00-8-1v2h-4v-2z" />
    </LockPrism>

    <Blue>
      <path d="M280 354a9 9 0 016 11c-4 13-4 26-3 36a126 126 0 002 12 89 89 0 001 4 9 9 0 01-6 11 9 9 0 01-11-7l8-2-8 2a61 61 0 01-1-1 107 107 0 010-4 143 143 0 01-2-14c-1-11-1-26 3-42a9 9 0 0111-6zm41 0a9 9 0 00-6 11c3 13 3 26 3 36a126 126 0 01-2 12 89 89 0 01-1 3v1a9 9 0 006 11 9 9 0 0011-7l-8-2 8 2a61 61 0 001-5 143 143 0 00-1-56 9 9 0 00-11-6zm84-70a9 9 0 013-12c32-19 45-39 51-54a63 63 0 004-24 24 24 0 000-1l8-1-8 1a9 9 0 0117-3l-9 2 9-2v1a20 20 0 010 2v7c0 6-1 14-5 24-7 19-23 42-58 63a9 9 0 01-12-3zm-259 36a9 9 0 01-15-10l8 5-8-5 1-1v-1a65 65 0 013-2l9-10c8-7 20-17 38-25a9 9 0 117 16 130 130 0 00-43 32v1z" />
      <rect x="174" y="110" width="250" height="274" rx="20" fill="#2E8ECB" />
      <rect x="199" y="138" width="200" height="218" rx="13" fill="#0573B8" />
      <path
        d="M382 267a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zm0 27a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zm0-82a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zm0 110a2 2 0 012-2h25a2 2 0 012 2v10a2 2 0 01-2 2h-25a2 2 0 01-2-2v-10zm1-80a2 2 0 012-2h26a2 2 0 012 2v11a2 2 0 01-2 2h-26a2 2 0 01-2-2v-11zm-1-84a2 2 0 012-2h25a2 2 0 012 2v10a2 2 0 01-2 2h-25a2 2 0 01-2-2v-10zm0 27a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zM251 372a2 2 0 01-2-2v-26a2 2 0 012-2h10a2 2 0 012 2v26a2 2 0 01-2 2h-10zm-28 0a2 2 0 01-1-2v-26a2 2 0 011-2h11a2 2 0 012 2v26a2 2 0 01-2 2h-11zm55 0a2 2 0 01-2-2v-26a2 2 0 012-2h11a2 2 0 012 2v26a2 2 0 01-2 2h-11zm27 0a2 2 0 01-2-2v-26a2 2 0 012-2h11a2 2 0 012 2v26a2 2 0 01-2 2h-11zm28 0a2 2 0 01-2-2v-26a2 2 0 012-2h10a2 2 0 012 2v26a2 2 0 01-2 2h-10zm27 0a2 2 0 01-2-2v-26a2 2 0 012-2h11a2 2 0 012 2v26a2 2 0 01-2 2h-11zM251 148a2 2 0 01-2-2v-25a2 2 0 012-2h10a2 2 0 012 2v25a2 2 0 01-2 2h-10zm-28 0a2 2 0 01-1-2v-25a2 2 0 011-2h11a2 2 0 012 2v25a2 2 0 01-2 2h-11zm55 0a2 2 0 01-2-2v-25a2 2 0 012-2h11a2 2 0 012 2v25a2 2 0 01-2 2h-11zm27 0a2 2 0 01-2-2v-25a2 2 0 012-2h11a2 2 0 012 2v25a2 2 0 01-2 2h-11zm28 0a2 2 0 01-2-2v-25a2 2 0 012-2h10a2 2 0 012 2v25a2 2 0 01-2 2h-10zm27 0a2 2 0 01-2-2v-25a2 2 0 012-2h11a2 2 0 012 2v25a2 2 0 01-2 2h-11zm-177 10a2 2 0 012-2h25a2 2 0 012 2v10a2 2 0 01-2 2h-25a2 2 0 01-2-2v-10zm0 27a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zm0 27a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zm0 28a2 2 0 012-2h25a2 2 0 012 2v10a2 2 0 01-2 2h-25a2 2 0 01-2-2v-10zm0 27a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zm0 27a2 2 0 012-2h25a2 2 0 012 2v11a2 2 0 01-2 2h-25a2 2 0 01-2-2v-11zm0 28a2 2 0 012-2h25a2 2 0 012 2v10a2 2 0 01-2 2h-25a2 2 0 01-2-2v-10z"
        fill="#34ACF8"
      />
      <path d="M323 188a9 9 0 100-18 9 9 0 000 18zM272 188a9 9 0 100-18 9 9 0 000 18z" fill="#0A5280" />
      <path
        d="M453 147a24 24 0 0116 44l18 71-71 17-17-69-98 24a93 93 0 11-22-43 24 24 0 012-1l172-43zM219 288a36 36 0 11-18-71 36 36 0 0118 71z"
        fill="#82D3F7"
        fillRule="evenodd"
      />
      <path d="M142 320a5 5 0 100-10 5 5 0 000 10z" />
      <path d="M472 197a5 5 0 100-10 5 5 0 000 10z" />
    </Blue>
  </svg>
);
