import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import Button from '@blueprism/ui-core/components/Button';
import Stack from '@blueprism/ui-core/layout/Stack';
import Text from '@blueprism/ui-core/components/Text';
import GaugeChart from '@blueprism/ui-charts/components/GaugeChart';
import Row from '@blueprism/ui-core/layout/Row';
import Cluster from '@blueprism/ui-core/layout/Cluster';
import Box from '@blueprism/ui-core/layout/Box';
import { AuthContext } from 'contexts/AuthContext';
import { getIcon } from 'components/YourProcesses/Banner';
import { assessments } from 'utils/api';

const RecommendationHeader = styled(Text)`
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 400;
`;

const Content = styled(Stack)`
  min-width: 90%;
`;

const DescriptionList = styled.dl`
  padding: none;
  margin: none;
`;

const DescriptionTerm = styled.dt`
  padding: none;
  margin: none;
`;

const DescriptionDetail = styled.dd`
  padding: none;
  margin: none;
`;

export default function Recommendation() {
  const { getUser } = React.useContext(AuthContext);
  const [result, setResult] = React.useState({});
  const intl = useIntl();
  const history = useHistory();
  const params = useParams();

  function onClickViewProcesses() {
    history.push('/your-processes');
  }

  function onClickViewSummary() {
    history.push(`/process-assessment/${params.processId}/summary`);
  }

  React.useEffect(() => {
    assessments
      .getRecommendation(getUser, params.processId)
      .then(response => {
        setResult(response);
      })
      .catch(err => {
        if (err.response.status === 403 || err.response.status === 401) {
          history.push('/login');
          return;
        } else if (err.response.status === 500) {
          history.push('/servererror');
          return;
        }
      });
  }, [getUser, params.processId, history]);

  if (!result) return <FormattedMessage id="fetching" />;

  return (
    <Content justify="between" width="100%" align="center">
      <Cluster gap="xs" justify="center" padding="small">
        {result.apScore && (
          <Box padding="small">
            <GaugeChart
              align="right"
              title={intl.formatMessage({ id: 'recommnedationAumationChart' })}
              min={result?.apScoreMin || 0}
              max={result?.apScoreMax || 6}
              value={result.apScore}
            />
          </Box>
        )}
        {result.ieScore && (
          <Box padding="small">
            <GaugeChart
              title={intl.formatMessage({ id: 'recommnedationEaseChart' })}
              min={result?.ieScoreMin || 0}
              max={result?.ieScoreMax || 6}
              value={result.ieScore}
              pattern="navy"
            />
          </Box>
        )}
      </Cluster>
      <Stack width="50%" padding="xl">
        <RecommendationHeader type="h1">
          <FormattedMessage id="recommendationHeader" />
        </RecommendationHeader>

        <DescriptionList>
          <DescriptionTerm>
            <Row align="center" gap="small">
              <Box>{getIcon(result.recommendation)}</Box>
              <Box>
                <Text type="mega">{result.title}</Text>
              </Box>
            </Row>
          </DescriptionTerm>
          <DescriptionDetail>
            <Text type="body">{result.description}</Text>
          </DescriptionDetail>
        </DescriptionList>
      </Stack>

      <Row width="50%" justify="between">
        <Button type="button" onClick={onClickViewSummary}>
          <FormattedMessage id="viewSummary" />
        </Button>

        <Button type="button" id="view-processes-button" onClick={onClickViewProcesses}>
          <FormattedMessage id="viewProcesses" />
        </Button>
      </Row>
    </Content>
  );
}
