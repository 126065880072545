import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Text, Button } from '@blueprism/ui-core';
import { AuthContext } from 'contexts/AuthContext';
import PATDialog from 'components/PATDialog';
import DialogTitle from 'components/DialogTitle';
import { assessments } from 'utils/api';

export default function RecommendationDescriptionDialog({ assessmentId, onClose, open }) {
  const { getUser } = React.useContext(AuthContext);
  const [recommendation, setRecommendation] = React.useState({});

  React.useEffect(() => {
    assessments
      .getRecommendation(getUser, assessmentId)
      .then(response => {
        setRecommendation(response);
      })
      .catch(err => console.error(err));
  }, [getUser, assessmentId]);

  function handleOkClick() {
    onClose();
  }

  function renderFooter() {
    return (
      <Row justify="end">
        <Button onClick={handleOkClick}>
          <FormattedMessage id="recommendationDialogOK" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="small"
      overlay
      visible={open}
      onClose={onClose}
      aria-labelledby="recommendation-dialog"
      headerContent={
        <DialogTitle id="recommendation-dialog">
          <FormattedMessage id="recommendationTitle" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <Text>{recommendation.description}</Text>
    </PATDialog>
  );
}

RecommendationDescriptionDialog.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
