import * as React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import Stack from '@blueprism/ui-core/layout/Stack';
import Breadcrumbs from '@blueprism/ui-core/components/Breadcrumbs';
import Toolbar from '@blueprism/ui-core/components/Toolbar';
import ButtonTabGroup from '@blueprism/ui-core/components/ButtonTabGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import Text from '@blueprism/ui-core/components/Text';
import FolderGroup from '@blueprism/ui-icons/FolderGroup';
import Person from '@blueprism/ui-icons/Person';
import { GroupsContext } from 'contexts/GroupsContext';
import GroupMembersContent from 'components/GroupMembersContent';
import { GroupMembersContext } from 'contexts/GroupMembersContext';
import { GroupContext } from 'contexts/GroupContext';
import GroupsList from 'components/GroupsList';
import GroupNavButtons from 'components/GroupNavButtons';
import GroupMemberButtons from 'components/GroupMemberButtons';
import Link from 'components/Link';
import { toUInt } from 'utils/helper';
import useTableRowSelection from 'utils/useTableRowSelection';

export default function Group() {
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();
  const { tableStatus, groupDetails, setGroupId } = React.useContext(GroupContext);
  const { group, updateGroupParams, setGroupsActive, updateGroupsSpace } = React.useContext(GroupsContext);
  const { groupMembers, updateGroupMembersParams, setGroupMembersActive, updateGroupMembersSpace } = React.useContext(
    GroupMembersContext
  );
  const [activeTab, setActiveTab] = React.useState(0);

  const {
    clearSelection: clearGroupMemberIds,
    selectedRowIds: selectedGroupMemberIds,
    onRowSelectionChange: onGroupMemberSelectionChange,
  } = useTableRowSelection([], true);
  const {
    clearSelection: clearGroupIds,
    selectedRowIds: selectedGroupIds,
    onRowSelectionChange: onGroupSelectionChange,
  } = useTableRowSelection([], true);

  React.useEffect(() => {
    const newGroupId = toUInt(params.groupId);
    if (!group || group.groupId !== newGroupId) {
      setGroupId(newGroupId);
    }
  }, [params.groupId, group, setGroupId]);

  React.useEffect(() => {
    const tabIndex = getIndexFromFragment(params.tab);
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex);
    }
  }, [activeTab, params.tab]);

  React.useEffect(() => {
    const groupId = toUInt(params.groupId);
    const offset = toUInt(params.offset) || 0;
    const limit = toUInt(params.limit) || 25;

    setGroupId(groupId);
    updateGroupParams(limit, offset);
    updateGroupMembersParams(limit, offset);
  }, [params, setGroupId, updateGroupParams, updateGroupMembersParams]);

  React.useEffect(() => {
    if (groupDetails) {
      updateGroupsSpace(groupDetails.spaceId, groupDetails.groupId, groupDetails.privLevel === 2);
      updateGroupMembersSpace(groupDetails.spaceId, groupDetails.groupId, groupDetails.privLevel === 2);
    }
  }, [groupDetails, updateGroupsSpace, updateGroupMembersSpace]);

  React.useEffect(() => {
    setGroupsActive(activeTab === 0);
    setGroupMembersActive(activeTab === 1);
  }, [activeTab, setGroupsActive, setGroupMembersActive]);

  const getIndexFromFragment = index => {
    switch (index) {
      case 'members':
        return 1;
      case 'groups':
      default:
        return 0;
    }
  };

  const getTabUrlFragment = index => {
    switch (index) {
      case 0:
        return 'groups';
      case 1:
        return 'members';
      default:
        return '';
    }
  };

  if (!groupDetails || !group || !groupMembers) {
    return null;
  }

  function handleTabChange(event) {
    const index = Number(event.currentTarget.value);
    setActiveTab(index);

    const fragment = getTabUrlFragment(index);
    history.push(`/your-spaces/group/${group.parentId}/${fragment}/${Math.max(0, group.offset)}/${group.limit}`);
  }

  const renderButtons = () => {
    switch (activeTab) {
      case 0:
        return (
          <GroupNavButtons
            isAdmin={groupDetails.privLevel === 2}
            selectedRowIds={selectedGroupIds}
            clearSelection={clearGroupIds}
          />
        );
      case 1:
        return (
          <GroupMemberButtons
            isAdmin={groupDetails.privLevel === 2}
            selectedRowIds={selectedGroupMemberIds}
            clearSelection={clearGroupMemberIds}
          />
        );
      default:
        return null;
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <GroupsList selectedRowIds={selectedGroupIds} onRowSelectionChange={onGroupSelectionChange} />;
      case 1:
        return (
          <GroupMembersContent
            tableStatus={tableStatus}
            isAdmin={groupDetails.privLevel === 2}
            selectedRowIds={selectedGroupMemberIds}
            onRowSelectionChange={onGroupMemberSelectionChange}
          />
        );
      default:
        return null;
    }
  };

  const getLinkElements = () => {
    const links = [
      { url: '/your-spaces', text: intl.formatMessage({ id: 'spaces' }) },
      { url: `/your-spaces/space/${groupDetails.spaceId}`, text: groupDetails.spaceName },
      { url: `/your-spaces/space/${groupDetails.spaceId}/groups`, text: intl.formatMessage({ id: 'groups' }) },
    ]
      .concat(groupDetails.parentGroups.map(p => ({ url: `/your-spaces/group/${p.id}`, text: p.name })))
      .map(x => (
        <Link key={x.url} href={x.url}>
          {x.text}
        </Link>
      ));

    links.push(<Text key="breadCrumbText"> {groupDetails.name} </Text>);

    return links;
  };

  return (
    <Stack gap="base">
      <Breadcrumbs id="groupBreadcrumb">{getLinkElements()}</Breadcrumbs>
      <Text type="h1">{groupDetails.name}</Text>
      <Toolbar>
        <ButtonTabGroup selectedIndex={activeTab} onChange={handleTabChange}>
          <ToolbarButton label={intl.formatMessage({ id: 'groups' })} icon={<FolderGroup />} />
          <ToolbarButton label={intl.formatMessage({ id: 'members' })} icon={<Person />} />
        </ButtonTabGroup>
        {renderButtons()}
      </Toolbar>
      {renderTabContent()}
    </Stack>
  );
}
