import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@blueprism/ui-core/components/Button';
import Text from '@blueprism/ui-core/components/Text';
import Stack from '@blueprism/ui-core/layout/Stack';
import Row from '@blueprism/ui-core/layout/Row';
import Box from '@blueprism/ui-core/layout/Box';

const ButtonRow = styled(Row)`
  margin-top: 3rem;
`;

//TODO : Remove the Boxes once we've fixed the formatting of the Text component

export default function ProcessAssessmentCreator() {
  const history = useHistory();

  function handleViewExisting() {
    history.push('/your-processes');
  }

  function handleCreateNewClick() {
    history.push('/process-assessment/new');
  }

  return (
    <Stack gap="base">
      <Box width="var(--measure)">
        <Text type="h1">
          <FormattedMessage id="processAssessmentLandingPageHeader" />
        </Text>
      </Box>
      <Box width="var(--measure)">
        <Text type="body">
          <FormattedMessage id="processAssessmentLandingPageText1" />
        </Text>
      </Box>
      <Box>
        <Text type="body">
          <FormattedMessage id="processAssessmentLandingPageText2" />
        </Text>
      </Box>
      <ButtonRow gap="base" justify="end">
        <Button type="button" id="viewExistingProcessesButton" onClick={handleViewExisting}>
          <FormattedMessage id="processAssessmentViewExisting" />
        </Button>
        <Button type="button" id="createNewProcessButton" onClick={handleCreateNewClick}>
          <FormattedMessage id="processAssessmentCreateNew" />
        </Button>
      </ButtonRow>
    </Stack>
  );
}
