import * as React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Person, People, Folder, DoorEnter } from '@blueprism/ui-icons';
import Toolbar from '@blueprism/ui-core/components/Toolbar';
import Text from '@blueprism/ui-core/components/Text';
import Breadcrumbs from '@blueprism/ui-core/components/Breadcrumbs';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import ButtonTabGroup from '@blueprism/ui-core/components/ButtonTabGroup';
import Stack from '@blueprism/ui-core/layout/Stack';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import SpaceUsers from 'pages/SpaceUsers';
import SpaceAdmins from 'pages/SpaceAdmins';
import GroupNavButtons from 'components/GroupNavButtons';
import UsersNavButtons from 'components/UsersNavButtons';
import TeamNavButtons from 'components/TeamNavButtons';
import SpaceGroupsList from 'components/SpaceGroupsList';
import ConfirmDialog from 'components/ConfirmDialog';
import Link from 'components/Link';
import { GroupsContext } from 'contexts/GroupsContext';
import { UsersContext } from 'contexts/UsersContext';
import { TeamsContext } from 'contexts/TeamsContext';
import { SpaceContext } from 'contexts/SpaceContext';
import SpaceTeams from './SpaceTeams';
import { toUInt } from 'utils/helper';
import useTableRowSelection from 'utils/useTableRowSelection';

export default function Space() {
  const params = useParams();
  const history = useHistory();
  const {
    space,
    setSpaceId,
    openRemoveSelfDialog,
    handleRemoveSelfClose,
    removeSelf,
    isRemoveSelfOpen,
    errorText,
  } = React.useContext(SpaceContext);
  const { group, updateGroupParams, setGroupsActive, updateGroupsSpace } = React.useContext(GroupsContext);
  const { setUsersActive, updateUserParams } = React.useContext(UsersContext);
  const { updateTeamParams, setTeamsActive } = React.useContext(TeamsContext);
  const [activeTab, setActiveTab] = React.useState(0);
  const intl = useIntl();

  const {
    clearSelection: clearUserIds,
    selectedRowIds: selectedUserIds,
    onRowSelectionChange: onUserSelectionChange,
  } = useTableRowSelection([], true);
  const {
    clearSelection: clearTeamIds,
    selectedRowIds: selectedTeamIds,
    onRowSelectionChange: onTeamSelectionChange,
  } = useTableRowSelection([], true);
  const {
    clearSelection: clearGroupIds,
    selectedRowIds: selectedGroupIds,
    onRowSelectionChange: onGroupSelectionChange,
  } = useTableRowSelection([], true);

  const tab = (params.tab || 'users').toLowerCase();

  React.useEffect(() => {
    if (space && space.rootGroupId !== group.parentId) {
      updateGroupsSpace(space.id, space.rootGroupId, space.isCurrentUserAdmin);
    }
  }, [space, group, updateGroupsSpace]);

  React.useEffect(() => {
    if (!space) {
      return;
    }

    const tabIndex = getIndexFromFragment(params.tab, space.isCurrentUserAdmin);
    setActiveTab(currentTab => (currentTab === tabIndex ? currentTab : tabIndex));
  }, [params.tab, space]);

  React.useEffect(() => {
    if (!space) {
      return;
    }

    if (space.isCurrentUserAdmin) {
      setUsersActive(activeTab === 0);
      setTeamsActive(activeTab === 1);
      setGroupsActive(activeTab === 2);
    } else {
      setGroupsActive(activeTab === 0);
    }
  }, [activeTab, space, setUsersActive, setGroupsActive, setTeamsActive]);

  React.useEffect(() => {
    const newSpaceId = toUInt(params.spaceId);
    if (!space || space.id !== newSpaceId) {
      setSpaceId(newSpaceId);
    }
  }, [space, setSpaceId, params.spaceId]);

  React.useEffect(() => {
    const spaceId = toUInt(params.spaceId);
    const offset = toUInt(params.offset) || 0;
    const limit = toUInt(params.limit) || 25;

    updateGroupParams(limit, offset);
    updateUserParams(spaceId, limit, offset);
    updateTeamParams(spaceId, limit, offset);
  }, [group, updateGroupParams, updateUserParams, updateTeamParams, params.spaceId, params.offset, params.limit]);

  const getIndexFromFragment = (fragment, isCurrentUserAdmin) => {
    if (isCurrentUserAdmin) {
      switch ((fragment || '').toLowerCase()) {
        case 'teams':
          return 1;
        case 'groups':
          return 2;
        case 'users':
        default:
          return 0;
      }
    }

    switch ((fragment || '').toLowerCase()) {
      case 'groups':
        return 0;
      case 'admins':
      default:
        return 1;
    }
  };

  const getTabText = index => {
    if (space?.isCurrentUserAdmin) {
      switch (index) {
        case 1:
          return 'teams';
        case 2:
          return 'groups';
        case 0:
        default:
          return 'users';
      }
    }

    switch (index) {
      case 1:
        return 'admins';
      case 0:
      default:
        return 'groups';
    }
  };

  if (!space) {
    return null;
  }

  if (tab !== 'groups' && tab !== 'admins' && !space.isCurrentUserAdmin) {
    return <Redirect to={`/your-spaces/space/${space.id}/groups`} push />;
  }

  const handleChange = event => {
    const tabIndex = Number(event.currentTarget.value);
    const tabName = getTabText(tabIndex);
    history.push(`/your-spaces/space/${space.id}/${tabName}`);
  };

  const renderLeaveSpaceButton = () => {
    if (!space.isCurrentUserAdmin || space.adminsCount > 1) {
      return (
        <ButtonGroup>
          <ToolbarButton
            onClick={openRemoveSelfDialog}
            id="spaceRemoveSelf"
            label={intl.formatMessage({ id: 'spaceRemoveSelf' })}
            icon={<DoorEnter />}
          />
        </ButtonGroup>
      );
    }

    return null;
  };
  const renderTabContent = () => {
    if (space.isCurrentUserAdmin) {
      switch (activeTab) {
        case 0:
          return <SpaceUsers selectedRowIds={selectedUserIds} onRowSelectionChange={onUserSelectionChange} />;
        case 1:
          return <SpaceTeams selectedRowIds={selectedTeamIds} onRowSelectionChange={onTeamSelectionChange} />;
        case 2:
          return <SpaceGroupsList selectedRowIds={selectedGroupIds} onRowSelectionChange={onGroupSelectionChange} />;
        default:
          return null;
      }
    }

    switch (activeTab) {
      case 0:
        return <SpaceGroupsList selectedRowIds={selectedGroupIds} onRowSelectionChange={onGroupSelectionChange} />;
      case 1:
        return <SpaceAdmins />;
      default:
        return null;
    }
  };

  const renderButtons = () => {
    if (space.isCurrentUserAdmin) {
      switch (activeTab) {
        case 0:
          return <UsersNavButtons selectedRowIds={selectedUserIds} clearSelection={clearUserIds} />;
        case 1:
          return <TeamNavButtons selectedRowIds={selectedTeamIds} clearSelection={clearTeamIds} />;
        case 2:
          return (
            <GroupNavButtons
              selectedRowIds={selectedGroupIds}
              clearSelection={clearGroupIds}
              isAdmin={space.isCurrentUserAdmin}
            />
          );
        default:
          return null;
      }
    }

    if (activeTab === 0) {
      return (
        <GroupNavButtons
          selectedRowIds={selectedGroupIds}
          clearSelection={clearGroupIds}
          isAdmin={space.isCurrentUserAdmin}
        />
      );
    }
    return null;
  };

  const renderToolbar = () => {
    if (space.isCurrentUserAdmin) {
      return (
        <ButtonTabGroup selectedIndex={activeTab} onChange={handleChange}>
          <ToolbarButton id="usersTab" label={intl.formatMessage({ id: 'users' })} icon={<Person />} />
          <ToolbarButton id="teamsTab" label={intl.formatMessage({ id: 'teams' })} icon={<People />} />
          <ToolbarButton id="groupsTab" label={intl.formatMessage({ id: 'groups' })} icon={<Folder />} />
        </ButtonTabGroup>
      );
    }
    return (
      <ButtonTabGroup selectedIndex={activeTab} onChange={handleChange}>
        <ToolbarButton id="groupsTab" label={intl.formatMessage({ id: 'groups' })} icon={<Folder />} />
        <ToolbarButton id="adminsTab" label={intl.formatMessage({ id: 'admins' })} icon={<People />} />
      </ButtonTabGroup>
    );
  };

  return (
    <Stack gap="base">
      <Breadcrumbs id="spaceBreadcrumb">
        <Link href="/your-spaces">
          <FormattedMessage id="spaces" />
        </Link>
        <Text key="breadCrumbText"> {space.name}</Text>
      </Breadcrumbs>
      <Text type="h1">{space.name}</Text>
      <Toolbar>
        {renderToolbar()}
        <ButtonGroup>
          {renderButtons()}
          {renderLeaveSpaceButton()}
        </ButtonGroup>
      </Toolbar>
      {renderTabContent()}
      <ConfirmDialog
        errorText={errorText}
        title={intl.formatMessage({ id: 'spaceRemoveSelf' })}
        description={intl.formatMessage({ id: 'leaveThisSpaceConfirm' })}
        open={isRemoveSelfOpen}
        onConfirm={removeSelf}
        onCancel={handleRemoveSelfClose}
      />
    </Stack>
  );
}
