import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import PATDialog from 'components/PATDialog';
import Text from '@blueprism/ui-core/components/Text';
import DialogTitle from 'components/DialogTitle';

export default function ConfirmDialog({
  title,
  errorText = '',
  description,
  open = false,
  onClose,
  onCancel,
  onConfirm,
}) {
  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={onCancel}>
          <FormattedMessage id="confirmCancel" />
        </Button>
        <Button onClick={onConfirm}>
          <FormattedMessage id="confirmOK" />
        </Button>
      </Row>
    );
  }
  return (
    <PATDialog
      size="small"
      onClose={onClose}
      visible={open}
      overlay
      errorText={errorText}
      aria-labelledby="confirm-dialog-title"
      headerContent={<DialogTitle id="confirm-dialog-title">{title}</DialogTitle>}
      footerContent={renderFooter()}
    >
      <Text type="body">{description}</Text>
    </PATDialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
