import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Stack from '@blueprism/ui-core/layout/Stack';
import PATDialog from 'components/PATDialog';
import Button from '@blueprism/ui-core/components/Button';
import FormField from '@blueprism/ui-core/components/FormField';
import RadioButtonGroup from '@blueprism/ui-core/components/RadioButtonGroup';
import RadioButton from '@blueprism/ui-core/components/RadioButton';
import Datalist from '@blueprism/ui-core/components/Datalist';
import Option from '@blueprism/ui-core/components/Option';
import DialogTitle from 'components/DialogTitle';
import { GroupMembersSearchContext } from 'contexts/GroupMembersSearchContext';

export default function GroupMemberDialog({ mode = 'add', spaceId, open = false, onClose, onConfirm }) {
  const intl = useIntl();
  const { results, searchForGroupMember, resetSearch, errorText } = React.useContext(GroupMembersSearchContext);
  const [name, setName] = React.useState('');
  const [role, setRole] = React.useState(0);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    setErrors({});
    setName('');
    setRole(0);
  }, [open]);

  React.useEffect(() => {
    setErrors(errorText ? { dialogError: errorText } : {});
  }, [errorText]);

  const getErrorMessage = fieldName => errors[fieldName];
  const isInError = fieldName => errors[fieldName]?.length > 0 ?? false;

  function getSelectedMember(memberName) {
    memberName = memberName?.toLowerCase();
    var found = results.filter(
      member =>
        formatMember(member).toLowerCase() === memberName ||
        member.name?.toLowerCase() === memberName ||
        (member.memberType === 0 && member.email?.toLowerCase() === memberName)
    );

    return found.length === 1 ? found[0] : null;
  }

  function formatMember(member) {
    if (member.memberType === 0) {
      return `${member.name} (${member.email})`;
    }

    return `${member.name} (${intl.formatMessage({ id: 'team' })})`;
  }

  async function handleOkClick() {
    const selectedMember = getSelectedMember(name);
    const error = await onConfirm(selectedMember?.memberId ?? (name ? -1 : 0), selectedMember?.memberType, role);
    setErrors(error || {});
    if (!error) {
      resetSearch();
    }
  }

  function handleTextChange(event) {
    const { value } = event.target;
    setName(value);

    const selectedMember = getSelectedMember(value);
    if (!selectedMember) {
      searchForGroupMember(spaceId, value);
    }
  }

  function handleRoleChange(event) {
    setRole(parseInt(event.target.value));
  }

  function handleCancelClick() {
    resetSearch();
    onClose();
  }

  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={handleCancelClick}>
          <FormattedMessage id={mode + 'MemberCancel'} />
        </Button>
        <Button onClick={handleOkClick}>
          <FormattedMessage id={mode + 'MemberOK'} />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      errorText={getErrorMessage('dialogError')}
      size="large"
      overlay
      visible={open}
      onClose={handleCancelClick}
      aria-labelledby="simple-dialog-title"
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id={mode + 'MemberTitle'} />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <Stack gap="medium">
        <FormField
          errorText={getErrorMessage('memberId')}
          label={intl.formatMessage({ id: mode + 'MemberNameHeading' })}
          helperText={intl.formatMessage({ id: mode + 'MemberNameDetails' })}
          error={isInError('memberId')}
        >
          <Datalist id="addMemberText" value={name} onChange={handleTextChange}>
            {results.map(member => (
              <Option
                id={`${member.memberId}-${member.memberType}`}
                key={`${member.memberId}-${member.memberType}`}
                value={formatMember(member)}
              ></Option>
            ))}
          </Datalist>
        </FormField>

        <FormField
          errorText={getErrorMessage('privLevel')}
          error={isInError('privLevel')}
          label={intl.formatMessage({ id: mode + 'MemberRoleHeading' })}
        >
          <RadioButtonGroup name="role" onChange={handleRoleChange} value={role}>
            <RadioButton
              id={`radio-button-0`}
              name={`radio-button-group-0`}
              value={1}
              label={intl.formatMessage({ id: `priv1Name` })}
            />
            <RadioButton
              id={`radio-button-1`}
              name={`radio-button-group-1`}
              value={2}
              label={intl.formatMessage({ id: `priv2Name` })}
            />
          </RadioButtonGroup>
        </FormField>
      </Stack>
    </PATDialog>
  );
}

GroupMemberDialog.propTypes = {
  mode: PropTypes.string,
  spaceId: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
