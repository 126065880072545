import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Center, Text, Link, Stack } from '@blueprism/ui-core';

const HiddenOverflowStack = styled(Stack)`
  overflow: hidden;
  height: 100vh;
`;

const ErrorPageTemplate = ({ errorMessageText = '', headingText = '', image = null, linkText = '', linkHref = '' }) => (
  <HiddenOverflowStack justify="center">
    <Center intrinsic maxWidth="100%">
      <Stack gap="large" align="center">
        <Text type="h1">{headingText}</Text>
        <Box width="75%">
          <Text type="body">
            {errorMessageText} <Link href={linkHref}>{linkText}</Link>
          </Text>
        </Box>
        {image}
      </Stack>
    </Center>
  </HiddenOverflowStack>
);

ErrorPageTemplate.propTypes = {
  errorMessageText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  image: PropTypes.node,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
};

export default ErrorPageTemplate;
