import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DurationInput from 'components/core/DurationInput';
import FormField from '@blueprism/ui-core/components/FormField';
import ToggleTip from 'components/core/ToggleTip';
import { QuestionMark } from '@blueprism/ui-icons';

export default function AssessmentTimespanQuestion({
  fieldName,
  label,
  helperText,
  value = { hours: '', minutes: '', seconds: '' },
  isInError = false,
  errorHours = false,
  errorMinutes = false,
  errorSeconds = false,
  validationLabel,
  errorText = '',
  onChange,
}) {
  const [time, setTime] = useState({ hours: value.hours, minutes: value.minutes, seconds: value.seconds });

  function handleChange(event) {
    onChange(fieldName, event);
    setTime(event);
  }

  return (
    <FormField label={label} error={isInError} helperText={validationLabel} errorText={errorText}>
      {helperText != null ? <ToggleTip detailsText={helperText} icon={<QuestionMark />} /> : <></>}

      <DurationInput
        id={fieldName}
        name={fieldName}
        value={time}
        onChange={handleChange}
        errorHours={errorHours}
        errorMinutes={errorMinutes}
        errorSeconds={errorSeconds}
      />
    </FormField>
  );
}

AssessmentTimespanQuestion.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  validationLabel: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.object,
  isInError: PropTypes.bool,
  errorHours: PropTypes.bool,
  errorMinutes: PropTypes.bool,
  errorSeconds: PropTypes.bool,
  onChange: PropTypes.func,
};
