import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const primsy = keyframes`
  0%, 66%, 81%, 100% {
    transform: rotate(0deg);
  }

  75%, 80% {
    transform: rotate(5deg);
  }
`;

const primsyShadow = keyframes`
  0%, 66%, 81%, 100% {
    transform: scale(1);
  }

  75%, 80% {
    transform: scale(1.2);
  }
`;

const blueArmsNudge = keyframes`
  0%, 60%, 67%, 100% {
    transform: translateY(0);
  }

  62%, 65% {
    transform: translateY(-15px);
  }
`;

const blueFace = keyframes`
  0%, 17%, 28%, 37%, 48%, 100% {
    transform: translate(0, 0);
  }

  20%, 25% {
    transform: translate(-10px, -2px);
  }

  40%, 45% {
    transform: translate(10px, -2px);
  }
`;

const Blue = styled.g`
  fill: #2e8ecb;

  path:nth-of-type(3) {
    animation: ${blueArmsNudge} 10s ease-out infinite;
  }

  path:nth-of-type(6) {
    animation: ${blueFace} 10s ease infinite;
  }
`;

const Plant = styled.g`
  fill: #fff;
  fill-rule: evenodd;
  stroke: #0a5280;
  stroke-width: 4;
`;

const Shadows = styled.g`
  fill: #d9f3ff;
  opacity: 0.5;

  ellipse:nth-of-type(2) {
    animation: ${primsyShadow} 10s ease infinite;
    transform-origin: 70% 90%;
  }
`;

const Primsy = styled.g`
  animation: ${primsy} 10s ease infinite;
  transform-origin: 70% 85%;
`;

export const NotFound = () => (
  <svg viewBox="0 0 1280 440">
    <Shadows>
      <ellipse cx="265" cy="400" rx="80" ry="10" />
      <ellipse cx="995" cy="390" rx="80" ry="10" />
    </Shadows>

    <Primsy>
      <path
        fill="#82D3F7"
        stroke="#82D3F7"
        strokeLinejoin="round"
        strokeWidth="27"
        d="M994 49L819 247l175 106 175-106L994 49z"
      />
      <path stroke="#126285" strokeWidth="5" fill="none" d="M984 219a10 10 0 0020 0" />
      <rect width="15" height="15" x="1012" y="200" fill="#126285" rx="1.5" />
      <rect width="15" height="15" x="963" y="200" fill="#126285" rx="1.5" />
    </Primsy>

    <Blue>
      <path d="M242 325c6 2 9 7 7 12a114 114 0 000 56 9 9 0 01-18 5l9-3-9 3v-1a30 30 0 010-1 115 115 0 01-3-19c-1-12-1-28 3-45 1-5 6-8 11-7zM287 325c-5 2-8 7-6 12a114 114 0 010 56 9 9 0 0018 5l-9-3 9 3v-1a30 30 0 000-1 113 113 0 003-19c1-12 1-28-3-45-1-5-7-8-12-7z" />
      <path d="M129 84c0-11 10-21 21-21h226c12 0 21 10 21 21v252c0 12-9 21-21 21H150c-11 0-21-9-21-21V84z" />
      <path d="M428 189c-5 0-10 3-10 9a29 29 0 010 1 76 76 0 01-6 20c-6 13-15 26-30 33a9 9 0 108 17c21-10 33-28 39-42a110 110 0 008-27l-10-1 10 1c0-6-4-10-9-11zM99 189c6 0 10 3 11 9a49 49 0 001 6l5 15c5 13 14 26 29 33a9 9 0 01-7 17 79 79 0 01-39-42 110 110 0 01-8-27l9-1-9 1c-1-6 3-10 8-11z" />
      <path
        fill="#0573B8"
        d="M156 109c0-9 7-16 17-16h180c10 0 17 7 17 16v202c0 9-7 17-17 17H173c-10 0-17-8-17-17V109z"
      />
      <path
        fill="#34ACF8"
        d="M352 114c0-1 1-2 3-2h27l2 2v11l-2 2h-27c-2 0-3-1-3-2v-11zM352 143c0-1 1-2 3-2h27l2 2v12l-2 2h-27c-2 0-3-1-3-2v-12zM211 344l-2-2v-28l2-2h12l2 2v28l-2 2h-12zM182 344l-2-2v-28l2-2h12l2 2v28l-2 2h-12zM241 344l-2-2v-28l2-2h11c2 0 3 1 3 2v28c0 1-1 2-3 2h-11zM270 344l-2-2v-28l2-2h12l2 2v28l-2 2h-12zM300 344l-2-2v-28l2-2h11l2 2v28l-2 2h-11zM329 344l-2-2v-28l2-2h12l2 2v28l-2 2h-12zM211 104l-2-2V74l2-2h12l2 2v28l-2 2h-12zM182 104l-2-2V74l2-2h12l2 2v28l-2 2h-12zM241 104l-2-2V74l2-2h11c2 0 3 1 3 2v28c0 1-1 2-3 2h-11zM270 104l-2-2V74l2-2h12l2 2v28l-2 2h-12zM300 104l-2-2V74l2-2h11l2 2v28l-2 2h-11zM329 104l-2-2V74l2-2h12l2 2v28l-2 2h-12zM138 114l2-2h28l2 2v11l-2 2h-28l-2-2v-11zM138 143l2-2h28l2 2v12l-2 2h-28l-2-2v-12zM138 173c0-2 1-3 2-3h28c1 0 2 1 2 3v11l-2 2h-28l-2-2v-11zM138 202l2-2h28l2 2v12l-2 2h-28l-2-2v-12zM138 231l2-2h28l2 2v12l-2 2h-28l-2-2v-12zM138 261l2-2h28l2 2v11l-2 2h-28l-2-2v-11zM138 290l2-2h28l2 2v12l-2 2h-28l-2-2v-12zM352 231c0-1 1-2 3-2h27l2 2v12l-2 2h-27c-2 0-3-1-3-2v-12zM352 261c0-1 1-2 3-2h27l2 2v11l-2 2h-27c-2 0-3-1-3-2v-11zM352 173c0-2 1-3 3-3h27c1 0 2 1 2 3v11l-2 2h-27c-2 0-3-1-3-2v-11zM352 290c0-1 1-2 3-2h27l2 2v12l-2 2h-27c-2 0-3-1-3-2v-12zM354 204l2-2h28l2 2v12l-2 2h-28l-2-2v-12z"
      />
      <path
        fill="#0A5280"
        d="M290 196a10 10 0 100-20 10 10 0 000 20zM235 196a10 10 0 100-20 10 10 0 000 20zM273 214c1 0 2-1 2-3a12 12 0 00-25 0 3 3 0 106 0 7 7 0 1114 0c0 2 1 3 3 3z"
      />
    </Blue>

    <Plant>
      <path
        strokeWidth="3"
        d="M858 226c0-58-24-72-76-72 0 56 34 72 76 72zM908 239c0-49 22-58 62-58 1 47-20 58-62 58zM892 174c0-55 25-66 71-66 1 54-23 66-71 66zM863 150c-38 0-45-18-45-50 36 0 45 17 45 50zM882 101c-6-45 15-57 52-61 6 43-13 56-52 61z"
      />
      <path
        fill="#0A5280"
        strokeWidth="0"
        d="M878 108c0-10 10-29 11-29 1-1 4-1 5 1l-9 22-1 6v74l8-14a55 55 0 012-3 105 105 0 0113-18l10-7a3 3 0 012 6l-8 5-7 9a99 99 0 00-7 11l-13 23v66c7-11 15-20 21-27 7-11 16-22 28-27a3 3 0 113 5 58 58 0 00-26 26c-8 9-18 20-26 35v26a3 3 0 01-6 0v-49c-6-11-13-17-18-21l-4-4a3 3 0 010-1 89 89 0 00-35-34 3 3 0 113-6c14 7 26 20 37 37l3 3c4 4 9 8 14 15v-61l-3-9a99 99 0 00-14-19v-1l-18-20a3 3 0 014-4l18 20a68 68 0 013 3 105 105 0 0110 13v-52z"
      />
      <path d="M830 307c-1-4 3-8 7-8h86c4 0 7 4 7 8l-6 87c-1 3-4 6-7 6h-74c-4 0-7-3-7-6l-6-87z" />
      <path strokeLinejoin="round" d="M826 299h110v18H826z" />
      <path d="M824 397l1-1h111c1 0 2 0 1 1l-1 5-1 1H826l-1-1-1-5z" />
    </Plant>
  </svg>
);
