import React from 'react';

const PATLogo = () => (
  <svg viewBox="0 0 96 96">
    <path
      stroke="#fff"
      strokeWidth="4"
      strokeLinejoin="round"
      d="M53 15c-3-3-7-3-10 0L10 54l1 1-1-1c-3 3-2 7 1 9l34 20c2 2 4 2 6 0l34-20c3-2 4-6 1-9L53 15z"
    />
    <path fill="#0B75B7" d="M51 16h-6L11 55c-2 2-1 5 1 6l34 21h4l34-21c2-1 3-4 1-6L51 16z" />
    <path
      fill="#0B75B7"
      d="M24 53v-4h-4v4h4zm0 13v4h4v-4h-4zm-9 0h-4v4h4v-4zm0-37v-4h-4v4h4zm22 2l-2 3 2-3zm5 4l-3 2 3-2zm-2 15l3 3-3-3zm-16-3h-4v4h4v-4zm9-2l-2-3 2 3zm-3-9v-4 4zm-6 0v-4h-4v4h4zm-4 17v13h8V53h-8zm4 9h-9v8h9v-8zm-5 4V29h-8v37h8zm-4-33h15v-8H15v8zm15 0l5 1 4-7-9-2v8zm5 1l4 3 6-4c-1-3-3-5-6-6l-4 7zm4 3l1 5h8c0-4-1-6-3-9l-6 4zm1 5c0 2-1 4-3 5l6 6c3-3 5-7 5-11h-8zm-3 5c-1 2-4 2-8 2v8c6 0 10-1 14-4l-6-6zm-8 2h-5v8h5v-8zm-5 2h6v-8h-6v8zm6 0l6-3-5-6-1 1v8zm6-3l3-6h-8l5 6zm3-6c0-3-1-5-3-7l-6 5 1 2h8zm-3-7c-1-2-4-3-6-3v8l6-5zm-6-3h-6v8h6v-8zm-10 4v11h8V36h-8z"
    />
    <path
      fill="#fff"
      d="M24 53v13h-9V29h15l7 2 5 4 2 7c0 3-1 6-4 8s-6 3-11 3h-5zm0-6h6l3-2 2-3-2-5-3-1h-6v11zM55 61h-9l-2 5h-7l10-28h7l10 28h-7l-2-5zm-7-6h6l-3-9-3 9z"
    />
  </svg>
);

export default PATLogo;
