import React from 'react';
import PropTypes from 'prop-types';
import { QuestionMark } from '@blueprism/ui-icons';
import styled, { css } from 'styled-components';
import { CheckBox, FormField, Box, Stack } from '@blueprism/ui-core';
import ToggleTip from '../components/core/ToggleTip';
import NumberInput from '../components/core/NumberInput/NumberInput';

const Fieldset = styled(Stack).attrs(() => ({ element: 'fieldset', gap: 'base' }))`
  border: none;
`;

const Legend = styled.legend(
  ({ theme }) => css`
    font-size: ${theme.font.size.bodyL}rem;
    margin-left: ${theme.globalSizes.large};
  `
);

const ToggleTipLeftMargin = styled(Box)(
  ({ theme }) => css`
    margin-left: ${theme.globalSizes.large};
  `
);

const StyledBox = styled(Box)(
  props => css`
    margin-left: ${props.theme.globalSizes.large};
  `
);

const AssessmentNumberOptionQuestion = ({
  value,
  label,
  questionText,
  helperText,
  maxValue,
  iDontKnow,
  fieldName,
  handleChange,
  isInError,
  validationLabel,
  errorText = '',
}) => {
  const handleInputChange = event => {
    handleChange(fieldName, event.target.value !== '' ? { value: event.target.value, iDontKnow: false } : null);
  };

  const handleDontKnowChanged = event => {
    if (event.target.checked) {
      handleChange(fieldName, { value: '', iDontKnow: true });
    } else {
      handleChange(fieldName, null);
    }
  };

  const dontKnowSelected = value !== null && value.iDontKnow;
  return (
    <Fieldset>
      <Legend>{questionText}</Legend>
      <ToggleTipLeftMargin>
        {helperText && <ToggleTip detailsText={helperText} icon={<QuestionMark />} />}
      </ToggleTipLeftMargin>

      <FormField
        label={label}
        error={isInError}
        errorText={errorText}
        validationLabel={validationLabel}
        htmlFor={fieldName}
      >
        <NumberInput
          id={fieldName}
          name={fieldName}
          error={isInError}
          value={dontKnowSelected || !value ? '' : value.value}
          max={maxValue}
          onChange={handleInputChange}
          disabled={dontKnowSelected}
        />
      </FormField>
      <StyledBox>
        <CheckBox
          id={`${fieldName}_dontKnow`}
          label={iDontKnow}
          checked={dontKnowSelected}
          onChange={handleDontKnowChanged}
          value="-1"
        />
      </StyledBox>
    </Fieldset>
  );
};

AssessmentNumberOptionQuestion.propTypes = {
  value: PropTypes.object,
  label: PropTypes.string,
  questionText: PropTypes.string,
  helperText: PropTypes.string,
  maxValue: PropTypes.number,
  iDontKnow: PropTypes.string,
  fieldName: PropTypes.string,
  handleChange: PropTypes.func,
  isInError: PropTypes.bool,
  validationLabel: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default AssessmentNumberOptionQuestion;
