import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormField } from '@blueprism/ui-core';
import NumberInput from 'components/core/NumberInput/NumberInput';

export default function NumberFilter({
  enabled,
  fieldName,
  labelMin = '',
  labelMax = '',
  helperTextMin = '',
  helperTextMax = '',
  width = '4rem',
  isInErrorMin,
  isInErrorMax,
  errorTextMin,
  errorTextMax,
  onChange = () => {},
}) {
  const [localValues, setLocalValues] = React.useState({ minValue: '', maxValue: '' });

  function handleChange(newValues) {
    var filter = {};
    if (newValues[`${fieldName}Min`]) {
      filter[`${fieldName}Min`] = newValues[`${fieldName}Min`];
    }

    if (newValues[`${fieldName}Max`]) {
      filter[`${fieldName}Max`] = newValues[`${fieldName}Max`];
    }
    onChange({
      [`${fieldName}`]: {
        ...filter,
      },
    });
  }

  function handleMinValueChange({ target }) {
    const newValues = { ...localValues, minValue: target.value };
    setLocalValues(newValues);
    handleChange({
      [`${fieldName}Min`]: newValues.minValue,
      [`${fieldName}Max`]: newValues.maxValue,
    });
  }

  function handleMaxValueChange({ target }) {
    const newValues = { ...localValues, maxValue: target.value };
    setLocalValues(newValues);
    handleChange({
      [`${fieldName}Min`]: newValues.minValue,
      [`${fieldName}Max`]: newValues.maxValue,
    });
  }

  React.useEffect(() => {
    if (enabled && (localValues.minValue || localValues.maxvalue)) {
      onChange({
        [`${fieldName}`]: { [`${fieldName}Min`]: localValues.minValue, [`${fieldName}Max`]: localValues.maxValue },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <>
      <FormField
        gap="base"
        htmlFor={`${fieldName}Min`}
        label={labelMin}
        helperText={helperTextMin}
        error={isInErrorMin}
        errorText={errorTextMin}
      >
        <Box width={width} data-testid="minValue">
          <NumberInput id={`${fieldName}Min`} value={localValues.minValue} onChange={handleMinValueChange} />
        </Box>
      </FormField>
      <FormField
        gap="base"
        htmlFor={`${fieldName}Max`}
        label={labelMax}
        helperText={helperTextMax}
        error={isInErrorMax}
        errorText={errorTextMax}
      >
        <Box width={width} data-testid="maxValue">
          <NumberInput id={`${fieldName}Max`} value={localValues.maxValue} onChange={handleMaxValueChange} />
        </Box>
      </FormField>
    </>
  );
}

NumberFilter.propTypes = {
  enabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  labelMin: PropTypes.string,
  labelMax: PropTypes.string,
  helperTextMin: PropTypes.string,
  helperTextMax: PropTypes.string,
  width: PropTypes.string,
  isInErrorMin: PropTypes.bool,
  isInErrorMax: PropTypes.bool,
  errorTextMin: PropTypes.arrayOf(PropTypes.string),
  errorTextMax: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
