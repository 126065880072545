import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Text from '@blueprism/ui-core/components/Text';

const StyledText = styled(Text)(
  props => css`
    font-size: ${props.theme.font.size.h4}rem;
    line-height: 1.2;
  `
);

const DialogTitle = ({ children, ...rest }) => (
  <StyledText type="h1" {...rest}>
    {children}
  </StyledText>
);

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DialogTitle;
