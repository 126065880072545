import React, { useState } from 'react';
import Text from '@blueprism/ui-core/components/Text';
import InputField from '@blueprism/ui-core/components/InputField';
import FormField from '@blueprism/ui-core/components/FormField';
import ToggleTip from 'components/core/ToggleTip';
import { QuestionMark } from '@blueprism/ui-icons';

const AssessmentTagsQuestion = props => {
  const fieldName = props.question.fieldName;

  const [value, setValue] = useState(props.value.map(x => x.tag).join() || '');

  const handleChange = e => {
    setValue(e.target.value);
    props.handleChange(
      props.question.fieldName,
      e.target.value
        .split(',')
        .map(x => x.trim())
        .filter(x => x !== '')
        .map(x => ({ tag: x }))
    );
  };
  return (
    <>
      {props.question.questionText && <Text type="body">{props.question.questionText}</Text>}
      {props.question.helperText && <ToggleTip detailsText={props.question.helperText} icon={<QuestionMark />} />}

      <FormField
        error={props.isInError}
        label={props.question.label}
        helperText={props.validationLabel}
        errorText={props.errorText}
      >
        <InputField name={fieldName} id={fieldName} value={value} onChange={handleChange} />
      </FormField>
    </>
  );
};

export default AssessmentTagsQuestion;
