import React from 'react';
import PropTypes from 'prop-types';
import { FormField, InputField } from '@blueprism/ui-core';

export default function TagsFilter({ enabled, fieldName, label = '', helperText = '', onChange = () => {} }) {
  const [localValue, setLocalValue] = React.useState([]);

  const splitValue = value =>
    value
      .split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== '');

  function handleTextChange(event) {
    const { value } = event.target;
    setLocalValue(value);
    onChange({ [fieldName]: splitValue(value) });
  }

  React.useEffect(() => {
    if (enabled && localValue.length) {
      onChange({ [fieldName]: splitValue(localValue) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <FormField gap="base" htmlFor={fieldName} label={label} helperText={helperText}>
      <InputField id={fieldName} value={localValue} onChange={handleTextChange} />
    </FormField>
  );
}

TagsFilter.propTypes = {
  enabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
};
