import React from 'react';
import { useIntl } from 'react-intl';
import ErrorPageTemplate from './ErrorPageTemplate';
import { Unauthorised } from 'components/ErrorSvgs';

export default function UnauthorisedErrorPage() {
  const intl = useIntl();

  return (
    <ErrorPageTemplate
      headingText={intl.formatMessage({ id: '401header' })}
      errorMessageText={intl.formatMessage({ id: '401messageBody' })}
      image={<Unauthorised />}
    />
  );
}
