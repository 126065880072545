import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@blueprism/ui-core/components/Button';
import DialogBody from '../../components/DialogBody';
import DialogFooter from '../../components/DialogFooter';
import Row from '@blueprism/ui-core/layout/Row';
import FormField from '@blueprism/ui-core/components/FormField';
import DatalistIndexed from '@blueprism/ui-core/components/DatalistIndexed';
import Option from '@blueprism/ui-core/components/Option';
import ArrowLeft from '@blueprism/ui-icons/ArrowLeft';
import ArrowRight from '@blueprism/ui-icons/ArrowRight';

export default function RegistrationQuestionDataList({
  index,
  fieldName,
  onChange = () => {},
  onPreviousClick = () => {},
  onValidate = () => {},
  data = {},
  options = {},
  isInError = () => {},
  getErrorMessage = () => {},
  hidden = false,
}) {
  const intl = useIntl();
  const handleChanges = event => onChange(fieldName, event.target.value);

  async function handleNextClick() {
    try {
      await onValidate(fieldName, true);
    } catch (err) {
      return;
    }
  }

  return hidden ? null : (
    <>
      <DialogBody padding="none" gap="base">
        <FormField
          label={intl.formatMessage({ id: 'registrationQuestion-' + fieldName })}
          error={isInError(fieldName)}
          errorText={getErrorMessage(fieldName)}
          isFieldset
        >
          <DatalistIndexed
            id={`fieldName-datalist-indexed`}
            onChange={handleChanges}
            defaultValue={options[data[fieldName]]}
          >
            {Object.entries(options).map(([key, value]) => (
              <Option key={key} value={key}>
                {value}
              </Option>
            ))}
          </DatalistIndexed>
        </FormField>
      </DialogBody>
      <DialogFooter element="footer">
        <Row justify="between">
          <Button id="previousButton" onClick={() => onPreviousClick(index - 1)} iconLeft={<ArrowLeft />}>
            <FormattedMessage id="previous" />
          </Button>
          <Button id="continueButton" onClick={handleNextClick} iconRight={<ArrowRight />}>
            <FormattedMessage id="continue" />
          </Button>
        </Row>
      </DialogFooter>
    </>
  );
}

RegistrationQuestionDataList.propTypes = {
  index: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  onPreviousPage: PropTypes.func,
  handleChange: PropTypes.func,
  onValidate: PropTypes.func,
  data: PropTypes.object,
  options: PropTypes.object,
  getErrorMessage: PropTypes.func,
  isInError: PropTypes.func,
};
