import * as React from 'react';
import PropTypes from 'prop-types';

export default function useDebounceCallback(delay = 500) {
  const debounceHandler = React.useRef();

  React.useEffect(() => () => clearTimeout(debounceHandler.current), []);

  return function debounceChange(callback, value) {
    clearTimeout(debounceHandler.current);

    debounceHandler.current = setTimeout(() => {
      callback(value);
    }, delay);
  };
}

useDebounceCallback.propTypes = {
  delay: PropTypes.number,
};
