import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PATDialog from 'components/PATDialog';
import Button from '@blueprism/ui-core/components/Button';
import Row from '@blueprism/ui-core/layout/Row';
import { GroupMembersContext } from 'contexts/GroupMembersContext';
import { GroupContext } from 'contexts/GroupContext';
import { UserContext } from 'contexts/UserContext';
import DialogTitle from 'components/DialogTitle';
import User from 'components/User';
import { config } from 'utils/config';

export default function GroupMemberRemoveDialog({ selectedRowIds = [], clearSelection = () => {} }) {
  const intl = useIntl();
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const { groupDetails } = React.useContext(GroupContext);
  const { groupMembers, handleRemoveMemberClose, removeGroupMember } = React.useContext(GroupMembersContext);
  const [errorText, setErrorText] = React.useState('removeMemberMessage');

  const selectedMember = groupMembers?.members?.items.find(x => x.memberId.toString() === selectedRowIds[0]);
  const teamText = ' (' + intl.formatMessage({ id: 'team' }) + ')';
  const name = selectedMember?.name + (selectedMember?.memberType === 1 ? teamText : '');
  const avatar = selectedMember?.avatar && config.imageUrl(selectedMember?.avatar);

  React.useEffect(() => {
    setErrorText(intl.formatMessage({ id: 'removeMemberMessage' }));
  }, [groupMembers.isRemoveOpen, intl]);

  function handleCancelClick() {
    handleRemoveMemberClose();
  }

  async function handleOkClick() {
    const result = await removeGroupMember(selectedMember.memberId, clearSelection);
    switch (result?.status) {
      case 403:
        history.push('/login');
        return;
      case 401:
        setErrorText(intl.formatMessage({ id: 'removeGroupMember401Error' }));
        return;
      case 500:
      case 400:
        setErrorText(intl.formatMessage({ id: 'removeGroupMember500Error' }));
        return;
      default:
        setErrorText(intl.formatMessage({ id: 'removeMemberMessage' }));
        const isRedirectRequired = selectedMember.memberType === 0 && selectedMember.memberId === user.details.userId;
        if (isRedirectRequired) {
          history.push(`/your-spaces/space/${groupDetails.spaceId}`);
        }
        return;
    }
  }

  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={handleCancelClick}>
          <FormattedMessage id="editSpaceUserClose" />
        </Button>
        <Button onClick={handleOkClick}>
          <FormattedMessage id="editSpaceUserOK" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="small"
      overlay
      onClose={handleRemoveMemberClose}
      errorText={errorText}
      aria-labelledby="remove-group-member-title"
      visible={groupMembers.isRemoveOpen}
      headerContent={
        <DialogTitle id="remove-group-member-title">
          <FormattedMessage id="removeSpaceUserHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <User userName={name} avatarImage={avatar} />
    </PATDialog>
  );
}
