import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import { PersonPlus, PersonRemove, KeyWithDots } from '@blueprism/ui-icons';
import { GroupMembersContext } from 'contexts/GroupMembersContext';
import GroupMemberDialog from 'components/GroupMemberDialog';
import GroupMemberRemoveDialog from 'components/GroupMemberRemoveDialog';
import GroupMemberEditDialog from 'components/GroupMemberEditDialog';

const GroupMemberButtons = ({ selectedRowIds = [], clearSelection = () => {}, isAdmin }) => {
  const intl = useIntl();
  const history = useHistory();

  const {
    groupMembers,
    openAddMemberDialog,
    openEditMemberDialog,
    handleAddMemberClose,
    addGroupMember,
    groupMemberSearch,
    openRemoveMemberDialog,
  } = useContext(GroupMembersContext);

  if (!groupMembers.spaceId) {
    return null;
  }

  const areEditButtonsDisabled =
    !(
      selectedRowIds.length === 1 &&
      (groupMembers.members?.items.some(member => member.memberId.toString() === selectedRowIds[0]) ?? false)
    ) || !isAdmin;

  const renderButtons = () => {
    return (
      <ButtonGroup>
        <ToolbarButton
          disabled={!isAdmin}
          id="groupMemberAdd"
          onClick={openAddMemberDialog}
          label={intl.formatMessage({ id: 'groupMemberAdd' })}
          icon={<PersonPlus />}
        />
        <ToolbarButton
          disabled={areEditButtonsDisabled}
          id="groupMemberRemove"
          onClick={openRemoveMemberDialog}
          label={intl.formatMessage({ id: 'groupMemberRemove' })}
          icon={<PersonRemove />}
        />
        <ToolbarButton
          disabled={areEditButtonsDisabled}
          id="groupMemberEdit"
          onClick={openEditMemberDialog}
          label={intl.formatMessage({ id: 'groupMemberEdit' })}
          icon={<KeyWithDots />}
        />
      </ButtonGroup>
    );
  };

  async function handleAddGroupMember(memberId, memberType, role) {
    const response = await addGroupMember({
      groupMemberId: memberId,
      groupMemberType: memberType,
      privLevel: role,
      clearSelection,
    });

    if (response) {
      switch (response.status) {
        case 400:
        case 409:
          return response.data;
        case 401:
          return { dialogError: intl.formatMessage({ id: 'addGroupMember401Error' }) };
        case 403:
          history.push('/login');
          return;
        case 500:
          return { dialogError: intl.formatMessage({ id: 'addGroupMember500Error' }) };
        default:
          console.log(response);
          return;
      }
    }
  }

  return (
    <>
      {renderButtons()}
      <GroupMemberDialog
        mode="add"
        spaceId={groupMembers.spaceId}
        open={groupMembers.isAddOpen}
        onConfirm={handleAddGroupMember}
        onClose={handleAddMemberClose}
        groupMemberSearch={groupMemberSearch}
      />
      <GroupMemberRemoveDialog selectedRowIds={selectedRowIds} clearSelection={clearSelection} />
      <GroupMemberEditDialog selectedRowIds={selectedRowIds} clearSelection={clearSelection} />
    </>
  );
};

GroupMemberButtons.propTypes = {
  isAdmin: PropTypes.bool,
};

export default GroupMemberButtons;
