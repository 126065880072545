import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Stack from '@blueprism/ui-core/layout/Stack';
import Row from '@blueprism/ui-core/layout/Row';
import FormField from '@blueprism/ui-core/components/FormField';
import Button from '@blueprism/ui-core/components/Button';
import InputField from '@blueprism/ui-core/components/InputField';
import PATDialog from 'components/PATDialog';
import DialogTitle from 'components/DialogTitle';

export default function SpaceAddDialog({
  title = '',
  currentName = '',
  mode = 'Add',
  open = false,
  onClose,
  onConfirm,
}) {
  const intl = useIntl();
  const history = useHistory();
  const [name, setName] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [busy, setBusy] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setName(currentName);
      setErrors({});
      setBusy(false);
    }
  }, [currentName, open]);

  function handleTextChange(event) {
    setName(event.target.value);
  }

  function handleModalClose() {
    onClose();
  }

  function handleCloseClick() {
    handleModalClose();
  }

  async function handleOkClick() {
    if (busy) {
      return;
    }

    try {
      setBusy(true);
      await onConfirm(name);
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          if (mode === 'Edit') {
            setErrors({ api: intl.formatMessage({ id: 'editSpace401' }) });
          }
          break;
        case 403:
          history.push('/login');
          break;
        case 500:
          setErrors({ api: intl.formatMessage({ id: `${mode.toLowerCase()}Space500` }) });
          break;
        default:
          if (err.response?.data) {
            setErrors(err.response.data);
          }
          break;
      }
      setBusy(false);
    }
  }

  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={handleCloseClick}>
          <FormattedMessage id="addSpaceClose" />
        </Button>
        <Button onClick={handleOkClick} aria-disabled={busy}>
          <FormattedMessage id={`spaceButtonOK${mode}`} />
        </Button>
      </Row>
    );
  }

  const getErrorMessage = fieldName => errors[fieldName];
  const isInError = fieldName => errors[fieldName]?.length > 0 ?? false;

  return (
    <PATDialog
      size="small"
      overlay
      errorText={getErrorMessage('api')}
      visible={open}
      onClose={handleModalClose}
      aria-labelledby="simple-dialog-title"
      headerContent={<DialogTitle id="simple-dialog-title">{title}</DialogTitle>}
      footerContent={renderFooter()}
    >
      <Stack gap="small">
        <FormField
          label={intl.formatMessage({ id: 'addSpaceInputTitle' })}
          helperText={intl.formatMessage({ id: `space${mode}HelperText` })}
          error={isInError('name')}
          errorText={getErrorMessage('name')}
        >
          <InputField value={name} error={errors.length > 0} onChange={handleTextChange} aria-labelledby="label" />
        </FormField>
      </Stack>
    </PATDialog>
  );
}

SpaceAddDialog.propTypes = {
  title: PropTypes.string,
  currentName: PropTypes.string,
  mode: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
