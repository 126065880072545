import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormField } from '@blueprism/ui-core';
import DateInput from '../core/DateInput';

export default function DateFilter({
  enabled,
  fieldName,
  labelMin = '',
  labelMax = '',
  helperTextMin = '',
  helperTextMax,
  isInErrorMin = false,
  isInErrorMax = false,
  errorTextMin = '',
  errorTextMax = '',
  onChange = () => {},
}) {
  const [localValues, setLocalValues] = React.useState({ [`${fieldName}Min`]: '', [`${fieldName}Max`]: '' });

  function handleValuesChange(newValues) {
    var filter = {};
    if (newValues[`${fieldName}Min`]) {
      filter[`${fieldName}Min`] = newValues[`${fieldName}Min`];
    }

    if (newValues[`${fieldName}Max`]) {
      filter[`${fieldName}Max`] = newValues[`${fieldName}Max`];
    }
    onChange({
      [`${fieldName}`]: {
        ...filter,
      },
    });
  }

  function handleMinValueChange(date) {
    const newValues = {
      [`${fieldName}Min`]: date,
      [`${fieldName}Max`]: localValues[`${fieldName}Max`],
    };
    setLocalValues(newValues);
    handleValuesChange(newValues);
  }

  function handleMaxValueChange(date) {
    const newValues = {
      [`${fieldName}Min`]: localValues[`${fieldName}Min`],
      [`${fieldName}Max`]: date,
    };

    setLocalValues(newValues);
    handleValuesChange(newValues);
  }

  React.useEffect(() => {
    if (enabled && (localValues[`${fieldName}Min`] || localValues[`${fieldName}Max`])) {
      handleValuesChange({
        [`${fieldName}Min`]: localValues[`${fieldName}Min`],
        [`${fieldName}Max`]: localValues[`${fieldName}Max`],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <Stack gap="medium">
      <FormField
        gap="base"
        htmlFor={`${fieldName}Min`}
        label={labelMin}
        helperText={helperTextMin}
        error={isInErrorMin}
        errorText={errorTextMin}
        isFieldset
      >
        <DateInput id={`${fieldName}Min`} error={isInErrorMin} onChange={handleMinValueChange} />
      </FormField>
      <FormField
        gap="base"
        htmlFor={`${fieldName}Max`}
        label={labelMax}
        helperText={helperTextMax}
        error={isInErrorMax}
        errorText={errorTextMax}
        isFieldset
      >
        <DateInput id={`${fieldName}Max`} error={isInErrorMax} onChange={handleMaxValueChange} />
      </FormField>
    </Stack>
  );
}

DateFilter.propTypes = {
  enabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  labelMin: PropTypes.string,
  labelMax: PropTypes.string,
  helperTextMin: PropTypes.string,
  helperTextMax: PropTypes.string,
  isInErrorMin: PropTypes.bool,
  isInErrorMax: PropTypes.bool,
  errorTextMin: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  errorTextMax: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
};
