import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ButtonGroup, Toolbar, ToolbarButton, Badge } from '@blueprism/ui-core';
import { FolderGroup, Filter as FilterIcon, ArrangeVertical, StateChange, Expand, Export } from '@blueprism/ui-icons';
import { AuthContext } from 'contexts/AuthContext';
import { YourProcessesContext, defaultColumns } from 'contexts/YourProcessesContextProvider';
import ColumnToggleDrawer from './ColumnToggleDrawer';
import TableFilterDrawer from './TableFilterDrawer';
import GroupSelectorDialog from 'components/GroupSelector';
import SetStatusDialog from 'components/SetStatusDialog';
import { saveAs } from 'file-saver';
import { assessments } from 'utils/api';

export default function YourProcessesTableToolbar({
  selectedRowId,
  selectedColumns = [],
  onSelectedColumnsChange = () => {},
  onShowDetailsChanged = () => {},
}) {
  const intl = useIntl();
  const { state, columns, requestUpdate } = React.useContext(YourProcessesContext);
  const { getUser } = React.useContext(AuthContext);
  const [isGroupSelectorDialogOpen, setIsGroupSelectorDialogOpen] = React.useState(false);
  const [isSetStatusDialogOpen, setIsSetStatusDialogOpen] = React.useState(false);
  const [columnToggleDrawerDocked, setColumnToggleDrawerDocked] = React.useState(true);
  const [filterTableDrawerDocked, setFilterTableDrawerDocked] = React.useState(true);
  const [filters, setFilters] = React.useState({});
  const [filterCount, setFilterCount] = React.useState(0);

  const selectedAssessment = state.assessments.items.find(row => row.id.toString() === selectedRowId) ?? {};

  function handleEditTableDrawerToggleClick() {
    setFilterTableDrawerDocked(true);
    setColumnToggleDrawerDocked(drawerDocked => !drawerDocked);
  }

  function handleFilterTableDrawerToggleClick() {
    setColumnToggleDrawerDocked(true);
    setFilterTableDrawerDocked(drawerDocked => !drawerDocked);
  }

  function handleChangeGroupClick() {
    setIsGroupSelectorDialogOpen(true);
  }

  function handleSetStatusClick() {
    setIsSetStatusDialogOpen(true);
  }

  function handleExportClick() {
    function generateFileName() {
      const now = new Date();
      return `${intl.formatMessage({ id: 'yourProcesses_export_filename' })}-${now.getFullYear()}-${
        now.getMonth() + 1
      }-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;
    }

    assessments.exportAssessments(getUser, filters).then(response => {
      const blob = new Blob([response], { type: 'text/csv', encoding: 'UTF-8' });
      saveAs(blob, generateFileName());
    });
  }

  function handleGroupSelectorDialogClose() {
    setIsGroupSelectorDialogOpen(false);
  }

  function handleSetStatusDialogClose() {
    setIsSetStatusDialogOpen(false);
  }

  function handleSetStatusDialogConfirm() {
    requestUpdate({});
    setIsSetStatusDialogOpen(false);
  }

  function handleColumnToggerDrawerClose() {
    setColumnToggleDrawerDocked(true);
  }

  function handleFilterDrawerClose() {
    setFilterTableDrawerDocked(true);
  }

  function handleViewDetailsClick() {
    onShowDetailsChanged(true);
  }

  async function handleGroupSelectorDialogConfirm(newGroupId) {
    try {
      await assessments.changeGroup(getUser, selectedAssessment.id, newGroupId);
    } catch (err) {
      console.log(err);
    }
    requestUpdate({});
  }

  function handleSelectedColumnsChange(newColumns) {
    onSelectedColumnsChange(newColumns);
  }

  function handleFiltersReset() {
    handleFiltersChange({});
  }

  function handleFiltersChange(changedFilters) {
    setFilterCount(
      Object.entries(changedFilters).filter(
        ([key]) => !!changedFilters[key] && (typeof changedFilters[key] !== 'string' || changedFilters[key].length > 1)
      ).length
    );
    setFilters(changedFilters);
    requestUpdate({ filters: changedFilters });
  }

  return (
    <>
      <Toolbar>
        <ButtonGroup>
          <ToolbarButton
            active={!filterTableDrawerDocked}
            onClick={handleFilterTableDrawerToggleClick}
            label={intl.formatMessage({
              id: 'yourProcesses_button_filter' + (filterCount === 0 ? '' : 'ed') + 'Table',
            })}
            icon={<FilterIcon />}
          >
            <Badge
              count={filterCount}
              ariaLabel={`${filterCount} ${intl.formatMessage({ id: 'yourProcesses_button_filteredTable' })}`}
            />
          </ToolbarButton>
          <ToolbarButton
            active={!columnToggleDrawerDocked}
            onClick={handleEditTableDrawerToggleClick}
            label={intl.formatMessage({ id: 'yourProcesses_button_editTable' })}
            icon={<ArrangeVertical />}
          />
        </ButtonGroup>
        <ButtonGroup>
          <ToolbarButton
            disabled={!selectedAssessment.id}
            onClick={handleChangeGroupClick}
            label={intl.formatMessage({ id: 'yourProcesses_button_changeGroup' })}
            icon={<FolderGroup />}
          />
          <ToolbarButton
            disabled={!selectedAssessment.id}
            onClick={handleViewDetailsClick}
            label={intl.formatMessage({ id: 'yourProcesses_button_viewDetails' })}
            icon={<Expand />}
          />
          <ToolbarButton
            disabled={!selectedAssessment.id}
            onClick={handleSetStatusClick}
            label={intl.formatMessage({ id: 'yourProcesses_button_setStatus' })}
            icon={<StateChange />}
          />
          <ToolbarButton
            onClick={handleExportClick}
            label={intl.formatMessage({ id: 'yourProcesses_button_export' })}
            icon={<Export />}
          />
        </ButtonGroup>

        <ColumnToggleDrawer
          docked={columnToggleDrawerDocked}
          columns={columns}
          selectedColumns={selectedColumns}
          defaultColumns={defaultColumns}
          onClose={handleColumnToggerDrawerClose}
          onSelectedColumnsChange={handleSelectedColumnsChange}
        />

        <TableFilterDrawer
          docked={filterTableDrawerDocked}
          validationErrors={state.validationErrors}
          onClose={handleFilterDrawerClose}
          onFiltersReset={handleFiltersReset}
          onFiltersChange={handleFiltersChange}
        />
      </Toolbar>
      {selectedAssessment?.id && (
        <>
          <SetStatusDialog
            assessmentId={selectedAssessment.id}
            assessmentTitle={selectedAssessment.processTitle}
            isOpen={isSetStatusDialogOpen}
            onClose={handleSetStatusDialogClose}
            onConfirm={handleSetStatusDialogConfirm}
          />
          <GroupSelectorDialog
            currentGroupId={selectedAssessment.folderId}
            isOpen={isGroupSelectorDialogOpen}
            onClose={handleGroupSelectorDialogClose}
            onConfirm={handleGroupSelectorDialogConfirm}
          />
        </>
      )}
    </>
  );
}

YourProcessesTableToolbar.propTypesd = {
  selectedRowId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  onSelectedColumnsChange: PropTypes.func,
};
