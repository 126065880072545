import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@blueprism/ui-core/components/Button';
import Text from '@blueprism/ui-core/components/Text';
import ArrowRight from '@blueprism/ui-icons/ArrowRight';
import Row from '@blueprism/ui-core/layout/Row';
import Box from '@blueprism/ui-core/layout/Box';
import DialogTitle from 'components/DialogTitle';
import DialogBody from '../DialogBody';
import DialogFooter from '../DialogFooter';
import { globalSizes } from '@blueprism/ui-core/theme/units';

/**
 *
 * A TourPage component is a "Wizard page" for use within an AvatarProgressModal
 *
 * it handles its visibility based upon the "hidden" prop - which is passed in and calulated within the AvatarProgressModal
 */
const TourPage = ({
  description = '',
  gap = 'base',
  hidden = false,
  isLast = false,
  onClose = () => {},
  onContinue = () => {},
  preTitle = '',
  title = '',
}) =>
  hidden ? null : (
    <>
      <DialogBody padding="none" gap={gap}>
        <Box element="header">
          <Text type="h6">{preTitle}</Text>
          <DialogTitle>{title}</DialogTitle>
        </Box>
        <Box padding="none">
          <Text type="body">{description}</Text>
        </Box>
      </DialogBody>
      <DialogFooter element="footer" justify="between">
        <Row justify="between">
          <Button type="button" onClick={onClose}>
            <FormattedMessage id="tourSkipTour" />
          </Button>
          {isLast ? (
            <Button type="button" onClick={onClose}>
              <FormattedMessage id="tourCompleteTour" />
            </Button>
          ) : (
            <Button type="button" onClick={onContinue} iconRight={<ArrowRight />}>
              <FormattedMessage id="tourTakeTour" />
            </Button>
          )}
        </Row>
      </DialogFooter>
    </>
  );

TourPage.propTypes = {
  /**
   * The bulk of the dialog's body content
   */
  description: PropTypes.string,
  /**
   * the vertical spacing between the page's child elements
   */
  gap: PropTypes.oneOf(Object.keys(globalSizes)),
  /**
   * Height of the content - this is passed from the Dialog and used to define the height of the content Stack, which in turn defines when the Body content shoudl scroll
   */
  height: PropTypes.string,
  /**
   * is this the last page in the wizard? - can be used to display a different button
   */
  isLast: PropTypes.bool,
  /**
   * show this child or not
   */
  hidden: PropTypes.bool,
  /**
   * callback for skip/cancel buttons
   */
  onClose: PropTypes.func,
  /**  Callback for next buttons */
  onContinue: PropTypes.func,
  /** used in this instance as our content has two titles - this is the "little one" */
  preTitle: PropTypes.string,
  /** The main title for the Wizard page */
  title: PropTypes.string,
};

export default TourPage;
