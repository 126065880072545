import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import FormField from '@blueprism/ui-core/components/FormField';
import Datalist from '@blueprism/ui-core/components/Datalist';
import Option from '@blueprism/ui-core/components/Option';
import { AuthContext } from 'contexts/AuthContext';
import PATDialog from 'components/PATDialog';
import DialogTitle from 'components/DialogTitle';
import { spaceManagement } from 'utils/api';
import useDebounce from 'utils/useDebounce';

export default function TeamUserAddDialog({ spaceId, open = false, onClose, onConfirm }) {
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const [search, setSearch] = React.useState('');
  const [lastExecutedSearch, setLastExecutedSearch] = React.useState(null);
  const [results, setResults] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const debouncedSearchTerm = useDebounce(search, 500);

  const formatUser = user => `${user.name} (${user.email})`;
  const getSelectedMember = searchText => results && results.find(x => searchText === formatUser(x));

  React.useEffect(() => {
    setSearch('');
    setErrors({});
  }, [open]);

  React.useEffect(() => {
    if (debouncedSearchTerm && lastExecutedSearch !== debouncedSearchTerm) {
      const selectedAnswer = results && results.some(x => debouncedSearchTerm === formatUser(x));
      if (!selectedAnswer) {
        setLastExecutedSearch(debouncedSearchTerm);
        spaceManagement
          .spaceUserSearch(getUser, spaceId, debouncedSearchTerm)
          .then(response => {
            setResults(response);
          })
          .catch(err => {
            switch (err?.response?.status) {
              case 400:
              case 500:
                setErrors({ api: intl.formatMessage({ id: '500TeamDialogText' }) });
                return;
              case 401:
                setErrors({ api: intl.formatMessage({ id: '401TeamDialogText' }) });
                return;
              default:
                console.log(err);
                return;
            }
          });
      }
    }
  }, [debouncedSearchTerm, lastExecutedSearch, results, getUser, spaceId, intl]);

  const handleClose = () => onClose();
  const handleCloseClick = () => onClose();
  const handleAdd = async () => {
    const selectedAnswer = getSelectedMember(search);
    const result = await onConfirm(selectedAnswer?.userId);
    setErrors(result || {});
  };

  const handleTextChange = event => setSearch(event.target.value);
  const getErrorMessage = fieldName => errors[fieldName];
  const isInError = fieldName => errors[fieldName]?.length > 0 ?? false;

  function renderFooter() {
    return (
      <Row justify="between">
        <Button id="cancelButton" onClick={handleCloseClick}>
          <FormattedMessage id="addTeamUserClose" />
        </Button>
        <Button id="okButton" onClick={handleAdd}>
          <FormattedMessage id="addTeamUserOk" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="small"
      overlay
      visible={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id="addTeamUserHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
      errorText={getErrorMessage('api')}
    >
      <FormField
        error={isInError('userId')}
        errorText={getErrorMessage('userId')}
        label={intl.formatMessage({ id: 'addTeamUserSubHeader' })}
        helperText={intl.formatMessage({ id: 'addTeamUserHelperText' })}
      >
        <Datalist id="userSearchInput" onChange={handleTextChange} value={search || ''} aria-labelledby="label">
          {results && results.map(user => <Option key={user.userId} value={formatUser(user)} />)}
        </Datalist>
      </FormField>
    </PATDialog>
  );
}

TeamUserAddDialog.propTypes = {
  open: PropTypes.bool,
  spaceId: PropTypes.number,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
