import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { UserManager } from 'oidc-client';
import { config } from 'utils/config';

const createUserManager = () =>
  new UserManager({
    client_id: 'pdtApp',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/oidc-callback`,
    response_type: 'code',
    scope: 'openid profile pdtAPI',
    authority: config.authServerUrl,
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/silent_renew`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/oidc-logout-callback`,
    filterProtocolClaims: true,
    loadUserInfo: true,
  });

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const history = useHistory();
  const [userManager, setUserManager] = useState(null);

  const [loggedInState, setLoggedInState] = useState({
    isLoggedIn: false,
    hasCheckedLoggedInState: false,
  });

  useEffect(() => {
    setUserManager(createUserManager());
  }, []);

  useEffect(() => {
    if (!userManager) {
      return;
    }

    userManager.events.addSilentRenewError(async ev => {
      try {
        await userManager.querySessionStatus();
        await userManager.signinRedirect();
      } catch (err) {
        console.log(err);
        history.push('/servererror');
      }
    });

    if (!loggedInState.hasCheckedLoggedInState) {
      userManager
        .getUser()
        .then(u =>
          setLoggedInState({
            isLoggedIn: u != null,
            hasCheckedLoggedInState: true,
          })
        )
        .catch(err => console.error(err));
    }
  }, [userManager, loggedInState, history]);

  if (!loggedInState.hasCheckedLoggedInState) {
    return null;
  }

  const login = () => {
    userManager.signinRedirect().catch(err => history.push('/servererror'));
  };

  const logout = async () => {
    try {
      await userManager.querySessionStatus();
      await userManager.signoutRedirect();
    } catch (err) {
      console.log(err);
      history.push('/servererror');
    }
  };

  const recheckLoggedInState = () => {
    setLoggedInState({ hasCheckedLoggedInState: false });
  };

  const getUser = () => {
    return userManager.getUser();
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        recheckLoggedInState,
        userManager,
        isLoggedIn: loggedInState.isLoggedIn,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
