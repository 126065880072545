import React from 'react';
import { FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import PATDialog from 'components/PATDialog';
import { GroupsContext } from 'contexts/GroupsContext';
import DialogTitle from 'components/DialogTitle';

export default function GroupRemoveDialog({ selectedGroup, onConfirm = () => {} }) {
  const { group, handleRemoveClose } = React.useContext(GroupsContext);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (group.isRemoveOpen && selectedGroup) {
      setErrors({});
    }
  }, [group.isRemoveOpen, selectedGroup]);

  function handleCancelClick() {
    handleRemoveClose();
    setErrors({});
  }

  const handleOkClick = async () => {
    const error = await onConfirm(selectedGroup.id);
    setErrors(error ?? {});
  };

  const getErrorMessage = fieldName => errors[fieldName];

  function renderFooter() {
    return (
      <Row justify="between">
        <Button id="cancelButton" onClick={handleCancelClick}>
          <FormattedMessage id="removeGroupClose" />
        </Button>
        <Button id="okButton" onClick={handleOkClick}>
          <FormattedMessage id="removeGroupOK" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="small"
      overlay
      errorText={getErrorMessage('dialogError') ?? getErrorMessage('error400')}
      onClose={handleRemoveClose}
      aria-labelledby="remove-group-title"
      visible={group.isRemoveOpen && selectedGroup ? true : false}
      headerContent={
        <DialogTitle id="remove-group-title">
          <FormattedMessage id="removeGroupHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <FormattedMessage id="removeGroupMessage" values={{ groupName: selectedGroup?.name }} />
    </PATDialog>
  );
}
