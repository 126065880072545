import * as React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Drawer, ToolbarButton } from '@blueprism/ui-core';
import { Cross, Refresh } from '@blueprism/ui-icons';
import { AuthContext } from 'contexts/AuthContext';
import DrawerContent from './DrawerContent';
import Filter from './Filter';
import FilterFactory from './FilterFactory';
import { yourProcesses } from 'utils/api';

export default function TableFilterDrawer({
  docked = false,
  validationErrors = {},
  onClose = () => {},
  onFiltersReset = () => {},
  onFiltersChange = () => {},
}) {
  const intl = useIntl();
  const history = useHistory();
  const { getUser } = React.useContext(AuthContext);
  const [metaData, setMetaData] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const updatedFilters = React.useRef({});

  function handleFilterValuesChange(newFilters) {
    updatedFilters.current = { ...updatedFilters.current, ...newFilters };
    const changedFilters = Object.entries(updatedFilters.current)
      .filter(([fieldName, value]) => {
        let removeValue = !!value;

        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            removeValue = value.length !== 0;
          } else {
            removeValue = Object.keys(value).length !== 0;
          }
        }

        return removeValue;
      })
      .reduce((acc, [fieldName, value]) => ({ ...acc, [fieldName]: value }), {});

    onFiltersChange(changedFilters);
  }

  function handleDrawerClose() {
    onClose();
  }

  function handleResetFiltersClick() {
    setSelectedFilters([]);
    updatedFilters.current = {};
    onFiltersReset();
  }

  function handleSelectedFiltersChange(fieldName, checked) {
    setSelectedFilters(prevSelectedFilters => {
      if (checked) {
        return [...prevSelectedFilters, fieldName];
      }

      const siblingFilters = Object.entries(updatedFilters.current).filter(([sibling]) =>
        sibling.startsWith(fieldName)
      );

      if (siblingFilters.length > 0) {
        handleFilterValuesChange(siblingFilters.reduce((acc, [name]) => ({ ...acc, [name]: '' }), {}));
      }
      return prevSelectedFilters.filter(filter => filter !== fieldName);
    });
  }

  React.useEffect(() => {
    yourProcesses
      .getFilterMetaData(getUser)
      .then(response => {
        setMetaData(response.filters);
      })
      .catch(err => {
        switch (err.response?.status) {
          case 500:
            history.push('/servererror');
            return;
          case 403:
            history.push('/login');
            return;
          case 401:
            history.push('/unauthorised');
            return;
          default:
            console.log(err);
        }
      });
  }, [getUser, history]);

  return (
    <Drawer anchor="right" docked={docked} initialWidth={450} fixed>
      <DrawerContent
        title={intl.formatMessage({ id: 'yourProcesses_drawer_filter_title' })}
        buttons={
          <>
            <ToolbarButton
              label={intl.formatMessage({ id: 'yourProcessesColumnFilter_button_resetFilters' })}
              icon={<Refresh />}
              onClick={handleResetFiltersClick}
            />
            <ToolbarButton
              label={intl.formatMessage({ id: 'yourProcessesColumnFilter_button_close' })}
              icon={<Cross />}
              onClick={handleDrawerClose}
            />
          </>
        }
      >
        <form noValidate>
          {metaData.map(filter => (
            <Filter
              key={filter.fieldName}
              enabled={selectedFilters.includes(filter.fieldName)}
              fieldName={filter.fieldName}
              title={filter.filterLabel}
              onToggle={handleSelectedFiltersChange}
            >
              <FilterFactory
                enabled={selectedFilters.includes(filter.fieldName)}
                filter={filter}
                validationErrors={validationErrors}
                onFilterValuesChange={handleFilterValuesChange}
              />
            </Filter>
          ))}
        </form>
      </DrawerContent>
    </Drawer>
  );
}

TableFilterDrawer.propTypes = {
  docked: PropTypes.bool,
  validationErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  onClose: PropTypes.func,
  onFiltersReset: PropTypes.func,
  onFiltersChange: PropTypes.func,
};
