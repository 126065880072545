import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { AuthContext } from 'contexts/AuthContext';
import AvatarProgressModal from '../../components/AvatarProgressModal';
import RegistrationQuestionRadio from 'pages/RegistrationQuestions/RegistrationQuestionRadio';
import RegistrationQuestionDataList from 'pages/RegistrationQuestions/RegistrationQuestionDataList';
import AccessRequest from 'pages/RegistrationQuestions/AccessRequest';
import AnimatedBackground from 'components/AnimatedBackground';
import { config } from 'utils/config';
import { user as userApi } from 'utils/api';

export default function RegistrationQuestions() {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { getUser } = useContext(AuthContext);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [errors, setErrors] = useState({});

  const [registrationOptions, setRegistrationOptions] = useState({});
  const [registrationDetails, setRegistrationDetails] = useState({
    relationshipWithBP: {},
    reasonForPDTUse: {},
    heardAboutPDTFrom: {},
    industry: {},
  });

  useEffect(() => {
    userApi.getRegistrationDetails(getUser).then(response => {
      setRegistrationDetails({ ...response.details });
      setRegistrationOptions(response.options);
    });
  }, [getUser]);

  async function handleValidation(fieldName, checkIsRegistered) {
    const regDTO = {
      [fieldName]: registrationDetails[fieldName],
      returnTrueIfRegistered: checkIsRegistered,
    };

    try {
      const response = await userApi.postRegistrationDetails(getUser, regDTO);
      setErrors({});
      if (checkIsRegistered && response) {
        setUser(prevUser => ({ ...prevUser, isRegistered: true, isFirstVisit: true }));
        history.push('/');
      }
    } catch (err) {
      setErrors(err.response.data);
      throw err;
    }
  }

  const getErrorMessage = fieldName => errors[fieldName];

  const isInError = fieldName => errors[fieldName] != null && errors[fieldName].length > 0;

  const handleChange = (key, value) => {
    setRegistrationDetails(prevRegistrationDetails => ({ ...prevRegistrationDetails, [key]: value }));
  };

  const handleNextClick = newIndex => {
    setPageIndex(newIndex);
  };

  const handlePreviousClick = newIndex => {
    setErrors({});
    setPageIndex(newIndex);
  };

  return (
    <AnimatedBackground>
      <AvatarProgressModal
        padding="medium"
        visible
        page={pageIndex}
        width="32rem"
        avatarImage={user.details.avatarImage && config.imageUrl(user.details.avatarImage)}
        height="36rem"
      >
        <AccessRequest index={0} user={user} onNextClick={handleNextClick} />
        <RegistrationQuestionRadio
          index={1}
          fieldName="relationshipWithBP"
          options={registrationOptions.relationshipWithBP}
          getErrorMessage={getErrorMessage}
          isInError={isInError}
          data={registrationDetails}
          onChange={handleChange}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
          onValidate={handleValidation}
        />
        <RegistrationQuestionRadio
          index={2}
          fieldName="reasonForPDTUse"
          options={registrationOptions.reasonForPDTUse}
          getErrorMessage={getErrorMessage}
          isInError={isInError}
          data={registrationDetails}
          onChange={handleChange}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
          onValidate={handleValidation}
        />
        <RegistrationQuestionRadio
          index={3}
          fieldName="heardAboutPDTFrom"
          options={registrationOptions.heardAboutPDTFrom}
          getErrorMessage={getErrorMessage}
          isInError={isInError}
          data={registrationDetails}
          onChange={handleChange}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
          onValidate={handleValidation}
        />
        <RegistrationQuestionDataList
          index={4}
          fieldName="industry"
          options={registrationOptions.industry}
          getErrorMessage={getErrorMessage}
          isInError={isInError}
          data={registrationDetails}
          onChange={handleChange}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
          onValidate={handleValidation}
        />
      </AvatarProgressModal>
    </AnimatedBackground>
  );
}
