import * as React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Drawer, Switch, ToolbarButton } from '@blueprism/ui-core';
import { Cross, Refresh } from '@blueprism/ui-icons';
import DrawerContent from './DrawerContent';

export default function ColumnToggleDrawer({
  docked = false,
  columns = [],
  defaultColumns = [],
  selectedColumns = [],
  onClose = () => {},
  onSelectedColumnsChange = () => {},
}) {
  const intl = useIntl();

  const getNewSelectedColumns = (accessor, checked) =>
    checked ? [...selectedColumns, accessor] : selectedColumns.filter(selectedColumn => selectedColumn !== accessor);

  function handleCloseClick() {
    onClose();
  }

  function handleToggleColumnChange(event) {
    const { name, checked } = event.target;
    const newValues = getNewSelectedColumns(name, checked);
    onSelectedColumnsChange(newValues);
  }

  function handleToggleResetClick() {
    const filteredColumns = columns
      .filter(column => defaultColumns.some(defaultColumn => defaultColumn === column.accessor))
      .map(column => column.accessor);
    onSelectedColumnsChange(filteredColumns);
  }

  return (
    <Drawer anchor="right" docked={docked} initialWidth={450} fixed>
      <DrawerContent
        title={intl.formatMessage({ id: 'yourProcessesColumnFilter_text_title' })}
        buttons={
          <>
            <ToolbarButton
              onClick={handleToggleResetClick}
              label={intl.formatMessage({ id: 'yourProcessesColumnFilter_button_reset' })}
              icon={<Refresh />}
            />
            <ToolbarButton
              onClick={handleCloseClick}
              label={intl.formatMessage({ id: 'yourProcessesColumnFilter_button_close' })}
              icon={<Cross />}
            />
          </>
        }
      >
        {columns.map(column => (
          <Switch
            displayType="justified"
            align="right"
            key={column.accessor}
            id={column.accessor}
            label={column.header}
            name={column.accessor}
            onChange={handleToggleColumnChange}
            checked={selectedColumns.includes(column.accessor)}
          />
        ))}
      </DrawerContent>
    </Drawer>
  );
}

ColumnToggleDrawer.propTypes = {
  docked: PropTypes.bool,
  columns: PropTypes.array,
  defaultColumns: PropTypes.array,
  selectedColumns: PropTypes.array,
  onClose: PropTypes.func,
  onSelectedColumnsChange: PropTypes.func,
};
