import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled, { css, ThemeContext } from 'styled-components';
import Stack from '@blueprism/ui-core/layout/Stack';
import Box from '@blueprism/ui-core/layout/Box';

const Details = styled(Stack)(
  props => css`
    color: ${props.theme.color.text};
    border: 0;
    outline: none;
    cursor: pointer;
  `
);

const Summary = styled.summary(
  ({ theme, globalSizes, active }) => css`
    background-color: ${active ? theme.color.action : 'transparent'};
    color: ${active ? theme.color.paper : theme.color.action};
    text-align: left;
    display: flex;
    flex-direction: row;
    border-radius: ${globalSizes.smallest};
    outline: none;
    transition: 0.2s box-shadow;
    padding: ${globalSizes.xs};
    align-items: center;
    width: auto;

    svg {
      margin-right: ${globalSizes.xs};
    }

    :hover {
      box-shadow: 0 0 0 2px ${theme.color.hover};
    }

    :focus {
      box-shadow: 0 0 0 4px ${theme.color.focus};
    }

    ::-webkit-details-marker {
      display: none;
    }
  `
);

const Span = styled(Box)(
  ({ globalSizes, icon }) => css`
    margin-left: ${icon === null ? 0 : globalSizes.base};
  `
);

export default function ToggleTip({ summaryText = '', hideText = '', detailsText, icon = null, onClick = () => {} }) {
  const [active, setActive] = useState(false);
  const intl = useIntl();
  const { color, globalSizes } = React.useContext(ThemeContext);
  const translatedSummaryText = summaryText || intl.formatMessage({ id: 'helperMessage' });
  const translatedHideText = hideText || intl.formatMessage({ id: 'helperHideMessage' });

  function handleClick(event) {
    setActive(isActive => !isActive);
    onClick(event);
  }

  return (
    <Details element="details" gap="small">
      <Summary tabindex="0" active={active} onClick={handleClick} globalSizes={globalSizes}>
        {React.Children.map(icon, child =>
          React.cloneElement(icon, { color: active ? color.paper : color.action, size: 16 })
        )}
        {active ? translatedHideText : translatedSummaryText}
      </Summary>
      <Span padding="none" icon={icon} globalSizes={globalSizes}>
        {detailsText}
      </Span>
    </Details>
  );
}

ToggleTip.propTypes = {
  summaryText: PropTypes.string,
  hideText: PropTypes.string,
  detailsText: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};
