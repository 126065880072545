import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import * as translations from 'translations';

function getBrowserLanguage() {
  const browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language || 'en';

  return {
    locale: browserLanguage,
    language: browserLanguage.toLowerCase().split(/[_-]+/)[0],
  };
}

// TODO: Dynamically import required translations
const getMessages = language =>
  localStorage.getItem('usePseudoTranslations') === 'true'
    ? translations.pseudo
    : translations[language] ?? translations.en;

export default function LanguageProvider({ children }) {
  const browser = getBrowserLanguage();

  return (
    <IntlProvider locale={browser.locale} messages={getMessages(browser.language)}>
      {children}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
