import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { AuthContext } from 'contexts/AuthContext';
import { UserContext } from 'contexts/UserContext';
import useDebounceCallback from 'utils/useDebounceCallback';
import { getPaging } from 'utils/helper';
import { dataStates as assessmentStates } from '../utils/dataStates';
import { yourProcesses } from 'utils/api';
import { isEqual } from 'lodash';

export const defaultColumns = ['Recommendation', 'Title', 'Group'];

export const initialState = {
  paging: { offset: 0, limit: 25, filters: {} },
  assessments: {
    items: [],
    count: 0,
  },
  summaries: [1, 2, 3].map(id => ({ recommendation: id, assessments: 0, hours: 0 })),
  validationErrors: {},
  error: {},
};

function getColumns(sorting, intl) {
  const tempColumns = [
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_recommendationColumn' }),
      accessor: 'Recommendation',
      disableSort: true,
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_titleColumn' }),
      accessor: 'Title',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_typeColumn' }),
      accessor: 'Type',
      disableSort: true,
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_analystColumn' }),
      accessor: 'Analyst',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_ownerColumn' }),
      accessor: 'Owner',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_processIdColumn' }),
      accessor: 'ProcessId',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_businessFunctionColumn' }),
      accessor: 'BusinessFunction',
      disableSort: true,
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_reasonForAutomatingColumn' }),
      accessor: 'ReasonForAutomating',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_createdDateColumn' }),
      accessor: 'CreateDate',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_groupColumn' }),
      accessor: 'Group',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_capitalSavingsColumn' }),
      accessor: 'CapitalSavings',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_hoursSavingsColumn' }),
      accessor: 'HoursSavings',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_apScoreColumn' }),
      accessor: 'ApScore',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_ieScoreColumn' }),
      accessor: 'IeScore',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_statusColumn' }),
      accessor: 'Status',
      disableSort: true,
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_spaceColumn' }),
      accessor: 'Space',
    },
    {
      header: intl.formatMessage({ id: 'yourProcesses_table_tagsColumn' }),
      accessor: 'Tags',
      disableSort: true,
    },
  ];

  if (sorting === null || Object.keys(sorting).length === 0) return tempColumns;

  return tempColumns.map(column =>
    column.accessor === sorting.column ? { ...column, sortDirection: sorting.direction } : column
  );
}

export const YourProcessesContext = React.createContext();

export default function YourProcessesContextProvider({ children, debounceDelay }) {
  const intl = useIntl();
  const { user } = React.useContext(UserContext);
  const { getUser } = React.useContext(AuthContext);
  const [state, setState] = React.useState(initialState);
  const status = React.useRef(assessmentStates.INIT);
  const errorCode = React.useRef('');
  const columns = React.useRef([]);

  const args = React.useRef({ limit: 25, offset: 0, sorting: null, filters: {} });
  const debounceCallback = useDebounceCallback(debounceDelay);

  function getStoredColumnsForUser() {
    const storedValues = JSON.parse(localStorage.getItem(`${user.details.userId}-columns`));
    return {
      selectedColumns: storedValues?.selectedColumns ?? defaultColumns,
      sorting: storedValues?.sorting ?? null,
    };
  }

  function setStoredColumnsForUser({ selectedColumns, sorting = state.sorting }) {
    const newValue = JSON.stringify({
      selectedColumns,
      sorting,
    });

    localStorage.setItem(`${user.details.userId}-columns`, newValue);
  }

  function requestUpdate({
    offset = args.current.offset,
    limit = args.current.limit,
    filters,
    sorting = args.current.sorting,
  }) {
    status.current = assessmentStates.FETCHING;
    const useDebounce = !!filters || limit !== args.current.limit;

    if (filters) {
      // filters may have nested objects containing the filter values we need to send to the server.
      // This pulls those values into the top level filter object.
      const flattenFilters = (obj, parent, res = {}) => {
        for (let key in obj) {
          if (typeof obj[key] == 'object' && !(obj[key] instanceof Array)) {
            flattenFilters(obj[key], key, res);
          } else {
            res[key] = obj[key];
          }
        }
        return res;
      };

      filters = flattenFilters(filters);
      offset = 0;
    } else {
      filters = args.current.filters;
    }

    args.current = { ...args.current, offset, limit, filters, sorting };

    const applyChange = newArgs => {
      const isFullRequest = !isEqual(newArgs.filters, state.paging.filters) || state.assessments.items.length === 0;
      const api = isFullRequest ? yourProcesses.postFullAssessments : yourProcesses.postAssessments;

      api(getUser, newArgs)
        .then(response => {
          window.history.replaceState(null, null, `/your-processes?offset=${newArgs.offset}&limit=${newArgs.limit}`);

          status.current = assessmentStates.SUCCESS;
          setState(prevState => ({
            ...prevState,
            ...(isFullRequest ? response : { assessments: { ...response } }),
            paging: args.current,
            validationErrors: {},
          }));
        })
        .catch(err => {
          status.current = assessmentStates.ERROR;
          errorCode.current = err.response?.status;
          setState(prevState => ({
            ...prevState,
            error: err.message,
            paging: args.current,
            validationErrors: err.response?.data ?? {},
          }));
        });
    };

    if (useDebounce) {
      debounceCallback(applyChange, args.current);
    } else {
      applyChange(args.current);
    }
  }

  React.useEffect(() => {
    const { sorting } = getStoredColumnsForUser();
    const { limit, offset } = getPaging();

    columns.current = getColumns(sorting, intl);
    requestUpdate({ sorting, filters: {}, limit, offset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <YourProcessesContext.Provider
      value={{
        state,
        status: status.current,
        errorCode: status.current === assessmentStates.ERROR ? errorCode.current : null,
        columns: columns.current,
        getStoredColumnsForUser,
        setStoredColumnsForUser,
        requestUpdate,
      }}
    >
      {children}
    </YourProcessesContext.Provider>
  );
}

YourProcessesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  debounceDelay: PropTypes.number,
};
