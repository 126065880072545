import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from '@blueprism/ui-core/components/RadioButton';
import RadioButtonGroup from '@blueprism/ui-core/components/RadioButtonGroup';
import FormField from '@blueprism/ui-core/components/FormField';
import ToggleTip from 'components/core/ToggleTip';
import { QuestionMark } from '@blueprism/ui-icons';

export default function AssessmentRadioQuestion({
  fieldName = '',
  label = '',
  helperText = '',
  toggleTipText = '',
  options = {},
  value = '',
  errorText = '',
  isInError = false,
  standalone = false,
  onChange = () => {},
}) {
  function handleChange(event) {
    onChange(fieldName, event.target.value);
  }

  return (
    <FormField
      label={label}
      htmlFor={fieldName}
      helperText={helperText}
      errorText={errorText}
      error={isInError}
      standalone={standalone}
      isFieldset
      aria-describedby={fieldName + '-helper'}
    >
      {standalone ? <ToggleTip detailsText={toggleTipText} icon={<QuestionMark />} /> : <></>}

      <RadioButtonGroup name={fieldName} onChange={handleChange} value={String(value)}>
        {Object.entries(options).map(([key, optionValue]) => (
          <RadioButton
            id={`radio-button-${key}`}
            key={key}
            name={`radio-button-group-${fieldName}-${key}`}
            value={key}
            label={optionValue}
          />
        ))}
      </RadioButtonGroup>
    </FormField>
  );
}

AssessmentRadioQuestion.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  toggleTipText: PropTypes.string,
  options: PropTypes.object,
  value: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isInError: PropTypes.bool,
  standalone: PropTypes.bool,
  onChange: PropTypes.func,
};
