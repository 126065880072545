import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Table from '@blueprism/ui-core/components/Table';
import TablePagination from 'components/TablePagination';
import User from 'components/User';
import { config } from 'utils/config';

export default function TeamUsers({
  users = [],
  count = 0,
  selectedRowIds = [],
  onSelectionChanged = () => {},
  onPaginationChanged = () => {},
  teamId,
  offset,
  limit,
}) {
  const intl = useIntl();
  const history = useHistory();

  const rowData = React.useMemo(
    () =>
      users.map(user => ({
        id: user.userId.toString(),
        user: (
          <User avatarImage={user.avatar && config.imageUrl(user.avatar)} userName={user.name}>
            {`${user.name}${user.isCurrentUser ? ` (${intl.formatMessage({ id: 'memberYou' })})` : ''}`}
          </User>
        ),
      })),
    [intl, users]
  );

  const columns = React.useMemo(
    () => [
      {
        header: intl.formatMessage({ id: 'teamMembersColumn1' }),
        accessor: 'user',
      },
    ],
    [intl]
  );

  function handlePagingUrlChange({ newOffset, newLimit }) {
    history.push(`/your-spaces/team/${teamId}/users/${Math.max(0, newOffset)}/${Math.max(0, newLimit)}`);
    onPaginationChanged();
  }

  function handlePagingPreviousClick() {
    handlePagingUrlChange({ newOffset: offset - limit, newLimit: limit });
  }

  function handlePagingNextClick() {
    handlePagingUrlChange({ newOffset: offset + limit, newLimit: limit });
  }

  function handlePagingLimitChange(value) {
    // TODO: Should probably debounce this!
    handlePagingUrlChange({ newOffset: offset, newLimit: value });
  }

  return (
    <>
      {users && (
        <Table
          rowData={rowData}
          columns={columns}
          selectRows
          tableLayout="auto"
          selectedIds={selectedRowIds}
          onRowSelectionChange={onSelectionChanged}
        />
      )}
      <TablePagination
        limitLabelId="team-users-limit"
        itemName="Users"
        limit={limit}
        offset={offset}
        count={count}
        onLimitChange={handlePagingLimitChange}
        onNextData={handlePagingNextClick}
        onPreviousData={handlePagingPreviousClick}
      />
    </>
  );
}

TeamUsers.propTypes = {
  users: PropTypes.array,
  count: PropTypes.number,
  onSelectionChanged: PropTypes.func,
  teamId: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
};
