import React from 'react';
import { FormattedMessage } from 'react-intl';
import AvatarProgressModal from '../components/AvatarProgressModal';
import { AuthContext } from 'contexts/AuthContext';
import Box from '@blueprism/ui-core/layout/Box';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import ArrowRight from '@blueprism/ui-icons/ArrowRight';
import DialogBody from 'components/DialogBody';
import Text from '@blueprism/ui-core/components/Text';
import DialogTitle from 'components/DialogTitle';
import DialogFooter from 'components/DialogFooter';
import AnimatedBackground from 'components/AnimatedBackground';

export default function Login() {
  const { login } = React.useContext(AuthContext);

  return (
    <AnimatedBackground>
      <AvatarProgressModal visible gap="medium" padding="medium" width="30rem" height="20rem">
        <DialogBody padding="none">
          <Box element="header">
            <Text type="h6">
              <FormattedMessage id="loginSubTitle" />
            </Text>
            <DialogTitle>
              <FormattedMessage id="loginTitle" />
            </DialogTitle>
          </Box>
          <Box padding="none">
            <Text type="body">
              <FormattedMessage id="loginDescription" />
            </Text>
          </Box>
        </DialogBody>
        <DialogFooter>
          <Row justify="end">
            <Button onClick={login} id="login-button" iconRight={<ArrowRight />}>
              <FormattedMessage id="loginButton" />
            </Button>
          </Row>
        </DialogFooter>
      </AvatarProgressModal>
    </AnimatedBackground>
  );
}
