import React from 'react';
import { Stack, Row, Text, Link, Box } from '@blueprism/ui-core';
import Alert from '@blueprism/ui-icons/Alert';
import Cross from '@blueprism/ui-icons/CrossLarge';
import { useIntl } from 'react-intl';
import { maintenance } from 'utils/api';
import { AuthContext } from 'contexts/AuthContext';
import styled, { css, ThemeContext } from 'styled-components';

const Container = styled(Row)(
  props => css`
    border-radius: 0.125rem;
    align-items: center;
    background: ${props.theme.color.warning};
    color: white;
    padding: ${props.theme.globalSizes.xs};
    align-items: flex-start;

    svg {
      margin-right: ${props.theme.globalSizes.xs};
    }
  `
);

const CloseButtonContainer = styled(Stack)(
  props => css`
    margin-left: auto;
    flex: none;
    order: 2;
  `
);

const NotificationText = styled(Text)(
  props => css`
    font-size: ${props.theme.font.size.h5}rem;
    line-height: 1.2;
  `
);

export default function MaintenanceNotificationBanner() {
  const theme = React.useContext(ThemeContext);
  const { getUser } = React.useContext(AuthContext);
  const [maintenanceTime, setMaintenanceTime] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const intl = useIntl();

  React.useEffect(() => {
    maintenance
      .getNextMaintenanceTime(getUser)
      .then(response => {
        setMaintenanceTime(response.maintenanceTime ?? null);
        setIsOpen(!!response.maintenanceTime);
      })
      .catch(err => console.log(err));
  }, [getUser]);

  function handleClose(event) {
    event.preventDefault();
    setIsOpen(false);
  }

  if (isOpen) {
    var dateParts = intl.formatDateToParts(maintenanceTime, {
      month: 'long',
      weekday: 'long',
      day: 'numeric',
      timeZoneName: 'short',
    });

    return (
      <Container theme={theme}>
        <Box padding="none">
          <Alert size={24} color={theme.color.paper} />
        </Box>

        <NotificationText themeColor="paper">
          {intl.formatMessage(
            { id: 'maintenanceNotificationText' },
            {
              weekday: dateParts.find(x => x.type === 'weekday').value,
              day: dateParts.find(x => x.type === 'day').value,
              month: dateParts.find(x => x.type === 'month').value,
              time: intl.formatTime(new Date(maintenanceTime)),
              timezone: dateParts.find(x => x.type === 'timeZoneName').value,
            }
          )}
        </NotificationText>
        <CloseButtonContainer>
          <Link onClick={handleClose}>
            <Cross size={24} color={theme.color.paper} />
          </Link>
        </CloseButtonContainer>
      </Container>
    );
  } else {
    return <></>;
  }
}
