import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import Row from '@blueprism/ui-core/layout/Row';
import Box from '@blueprism/ui-core/layout/Box';
import Text from '@blueprism/ui-core/components/Text';
import Alert from '@blueprism/ui-icons/Alert';

const Container = styled(Row)(
  ({ theme }) => css`
    border-radius: 0.125rem;
    align-items: center;
    background: ${theme.color.warning};
    color: white;
    padding: ${theme.globalSizes.xs};
    align-items: flex-start;

    svg {
      margin-right: ${theme.globalSizes.xs};
    }
  `
);

export default function ErrorMessage({ label }) {
  const theme = React.useContext(ThemeContext);

  return (
    <Container role="alert">
      <Box padding="none">
        <Alert size={16} color={theme.color.paper} />
      </Box>
      <Text type="body" themeColor="paper">
        {label}
      </Text>
    </Container>
  );
}

ErrorMessage.propTypes = {
  label: PropTypes.string,
};
