import * as React from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import Row from '@blueprism/ui-core/layout/Row';
import Stack from '@blueprism/ui-core/layout/Stack';
import Box from '@blueprism/ui-core/layout/Box';
import Tick from '@blueprism/ui-icons/Tick';
import Alert from '@blueprism/ui-icons/Alert';
import Asterisk from '@blueprism/ui-icons/Asterisk';
import Link from 'components/Link';

export default function ProcessAssessmentSummaryEntry({
  fieldValue = '',
  questionText = '',
  link = '',
  isAnswered = false,
  linkEnabled = false,
  isMandatory = false,
}) {
  const { color } = React.useContext(ThemeContext);

  function renderIcon() {
    if (isAnswered) {
      return <Tick color={color.action} size={24} />;
    }

    if (isMandatory) {
      return <Alert size={24} color={color.warning} />;
    }

    return <Asterisk size={24} color={color.text} />;
  }

  return (
    <Row justify="start" gap="base" align="center" alignContent="center" width="100%">
      <Box>{renderIcon()}</Box>
      <Stack justify="start" align="start" spacing="smallest">
        <Box>
          <dt>{questionText}</dt>
        </Box>
        <Box>
          <dd>
            {linkEnabled && <Link href={link}>{fieldValue}</Link>} {!linkEnabled && <>{fieldValue}</>}
          </dd>
        </Box>
      </Stack>
    </Row>
  );
}

ProcessAssessmentSummaryEntry.propTypes = {
  fieldValue: PropTypes.string,
  questionText: PropTypes.string,
  link: PropTypes.string,
  isAnswered: PropTypes.bool,
  linkEnabled: PropTypes.bool,
  isMandatory: PropTypes.bool,
};
