import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserContext } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import Text from '@blueprism/ui-core/components/Text';
import Button from '@blueprism/ui-core/components/Button';
import DialogBody from '../../components/DialogBody';
import DialogFooter from '../../components/DialogFooter';
import DialogTitle from '../../components/DialogTitle';
import Row from '@blueprism/ui-core/layout/Row';
import ArrowRight from '@blueprism/ui-icons/ArrowRight';

export default function AccessRequest({ index, hidden = false, onNextClick = () => {} }) {
  const { user } = React.useContext(UserContext);
  return hidden ? null : (
    <>
      <DialogBody padding="none" gap="base">
        <div aria-hidden="true">
          <Text horizontalAlign="justify">
            <FormattedMessage id="accessRequestWelcome" values={{ firstName: user.details.firstName }} />
          </Text>
        </div>
        <DialogTitle>
          <FormattedMessage id="accessRequestWelcomeTitle" />
        </DialogTitle>
        <Text>
          <FormattedMessage id="accessRequestWelcomeIntro" />
        </Text>
      </DialogBody>
      <DialogFooter element="footer">
        <Row justify="end">
          <Button type="button" id="getStartedButton" iconRight={<ArrowRight />} onClick={() => onNextClick(index + 1)}>
            <FormattedMessage id="accessRequestWelcomeGetStarted" />
          </Button>
        </Row>
      </DialogFooter>
    </>
  );
}

AccessRequest.propTypes = {
  index: PropTypes.number.isRequired,
  onNextClick: PropTypes.func,
};
