import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Text from '@blueprism/ui-core/components/Text';
import Stack from '@blueprism/ui-core/layout/Stack';
import YourProcessesContextProvider from 'contexts/YourProcessesContextProvider';
import Banner from './Banner';
import YourProcessesTableContainer from './YourProcessesTableContainer';
import useTableRowSelection from 'utils/useTableRowSelection';
import YourProcessesDetails from './YourProcessesDetails';

const debounceDelay = 500;

const YourProcesses = () => {
  const { selectedRowIds, onRowSelectionChange } = useTableRowSelection([], true);
  const [showDetails, setShowDetails] = React.useState(false);

  function handleRowSelectionChange(event) {
    onRowSelectionChange(event);
  }

  function handleShowDetailsChanged(newShowDetails) {
    setShowDetails(newShowDetails);
  }

  function renderContent() {
    if (showDetails) {
      return <YourProcessesDetails onShowDetailsChanged={handleShowDetailsChanged} assessmentId={selectedRowIds[0]} />;
    }

    return (
      <>
        <Text type="h1">
          <FormattedMessage id="yourProcessesTitle" />
        </Text>
        <Banner />
        <YourProcessesTableContainer
          selectedRowIds={selectedRowIds}
          onRowSelectionChange={handleRowSelectionChange}
          onShowDetailsChanged={handleShowDetailsChanged}
        />
      </>
    );
  }

  return (
    <Stack gap="base">
      <YourProcessesContextProvider debounceDelay={debounceDelay}>{renderContent()}</YourProcessesContextProvider>
    </Stack>
  );
};
export default YourProcesses;
