import axios from 'axios';
import { createOffsetLimitFragment } from 'utils/helper';
import { config } from 'utils/config';

function generateRequestConfig(currentUser, options = { headers: {} }) {
  if (currentUser) {
    options.headers['Authorization'] = `Bearer ${currentUser.access_token}`;
  }

  if (localStorage.getItem('usePseudoTranslations') === 'true') {
    options.headers['Accept-Language'] = 'qps-ploc';
  }

  return options;
}

async function httpPost(getUser, url, data, customOptions) {
  const currentUser = await getUser();
  const options = generateRequestConfig(currentUser, customOptions);
  const result = await axios.post(config.apiUrl + url, data, options);
  return result.data;
}

async function httpGet(getUser, url, customOptions) {
  const currentUser = await getUser();
  const options = generateRequestConfig(currentUser, customOptions);
  const result = await axios.get(config.apiUrl + url, options);
  return result.data;
}

const isEmailInPortal = (getUser, email) => httpGet(getUser, `/api/user/isemailinportal/${email}`);
const getRegistrationDetails = getUser => httpGet(getUser, '/api/user/RegistrationDetails');

async function postRegistrationDetails(getUser, regDTO) {
  const result = await httpPost(getUser, '/api/user/RegistrationDetails', regDTO);
  return result;
}

const getUserDetails = getUser => httpGet(getUser, '/api/user/UserDetails');
const getRefreshedUserDetails = getUser =>
  httpGet(getUser, '/api/user/RefreshedUserDetails', {
    headers: { 'Cache-Control': 'no-cache' },
  });

const getSpaceAdmins = (getUser, spaceId, offset, limit) =>
  httpGet(getUser, `/api/space/${spaceId}/admins/${offset}/${limit}`);

const spaceUserSearch = (getUser, spaceId, searchTerm) =>
  httpGet(getUser, `/api/space/${spaceId}/usersearch/${searchTerm}`);

const spaceMemberSearch = (getUser, spaceId, searchTerm) =>
  httpPost(getUser, `/api/space/${spaceId}/membersearch`, { name: searchTerm });

async function getTeam(getUser, teamId) {
  const result = await httpGet(getUser, `/api/team/${teamId}`);
  return result;
}

async function addMemberToTeam(getUser, userId, teamId) {
  const result = await httpPost(getUser, `/api/team/${teamId}/adduser`, { userId, teamId });
  return result;
}

async function removeMemberFromTeam(getUser, userId, teamId) {
  const result = await httpPost(getUser, `/api/team/${teamId}/removeuser/${userId}`);
  return result;
}

async function getTeamMembers(getUser, teamId, offset, limit) {
  const result = await httpGet(getUser, `/api/team/${teamId}/users${createOffsetLimitFragment(offset, limit)}`);
  return result;
}

const getGroupMembers = (getUser, spaceId, groupId, offset, limit) =>
  httpGet(getUser, `/api/space/${spaceId}/group/${groupId}/members${createOffsetLimitFragment(offset, limit)}`);

const addGroupMember = (getUser, groupId, memberId, memberType, privLevel) =>
  httpPost(getUser, `/api/group/${groupId}/members/add`, {
    folderId: groupId,
    memberId: memberId,
    memberType: memberType,
    privLevel: privLevel,
  });

async function removeMemberFromGroup(getUser, groupId, permissionId) {
  const result = await httpPost(getUser, `/api/group/${groupId}/members/remove/${permissionId}`, {});
  return result;
}

async function editGroupMember(getUser, groupId, permissionId, privLevel) {
  const result = await httpPost(getUser, `/api/group/${groupId}/members/edit`, {
    permissionId: permissionId,
    folderId: groupId,
    privLevel,
  });
  return result;
}

const removeSelfFromSpace = (getUser, spaceId) => httpPost(getUser, `/api/space/${spaceId}/removeself`);
const getSpace = (getUser, spaceId) => httpGet(getUser, `/api/space/${spaceId}`);

async function getSpaces(getUser, offset, limit) {
  return await httpGet(getUser, `/api/groupmanagement/spaces/${offset}/${limit}`);
}

async function getGroupContent(getUser, spaceId, groupId, offset, limit) {
  const result = await httpGet(
    getUser,
    `/api/space/${spaceId}/group-content/${groupId}${createOffsetLimitFragment(offset, limit)}`
  );
  return result;
}

const addGroup = (getUser, parentGroupId, groupName) =>
  httpPost(getUser, `/api/group/${parentGroupId}/add`, { FolderId: parentGroupId, Name: groupName });

const editGroup = (getUser, groupId, newName) =>
  httpPost(getUser, `/api/group/${groupId}/edit`, { FolderId: groupId, Name: newName });

const removeGroup = (getUser, groupId) => httpPost(getUser, `/api/group/${groupId}/remove`);

async function getGroup(getUser, groupId) {
  const result = await httpGet(getUser, `/api/group/${groupId}`);
  return result;
}

const getSpaceTeams = (getUser, spaceId, offset, limit) =>
  httpGet(getUser, `/api/space/${spaceId}/teams${createOffsetLimitFragment(offset, limit)}`);

const addTeam = (getUser, spaceId, teamName) => httpPost(getUser, `/api/space/${spaceId}/team/add`, { name: teamName });

const editTeam = (getUser, teamId, teamName) =>
  httpPost(getUser, `/api/team/${teamId}/edit`, { teamId: teamId, name: teamName });

const removeTeam = (getUser, teamId) => httpPost(getUser, `/api/team/${teamId}/remove`);

const getSpaceUsers = (getUser, spaceId, offset, limit) =>
  httpGet(getUser, `/api/space/${spaceId}/users${createOffsetLimitFragment(offset, limit)}`);

const addSpaceUser = (getUser, spaceId, email, isAdmin) =>
  httpPost(getUser, `/api/space/${spaceId}/adduser`, { email: email, isAdmin: isAdmin });

const editSpaceUser = (getUser, spaceId, userId, isAdmin) =>
  httpPost(getUser, `/api/space/${spaceId}/edituser`, {
    userId: userId,
    isAdmin: isAdmin,
  });

const removeSpaceUser = (getUser, spaceId, userId) =>
  httpPost(getUser, `/api/space/${spaceId}/removeuser`, { UserId: userId });

async function addSpace(getUser, name) {
  const result = await httpPost(getUser, '/api/groupmanagement/addspace', { name });
  return result;
}

async function editSpace(getUser, spaceId, name) {
  const result = await httpPost(getUser, `/api/space/${spaceId}`, { name });
  return result;
}

async function removeSpace(getUser, spaceId) {
  const result = await httpPost(getUser, `/api/space/${spaceId}/remove`);
  return result;
}

async function getStatusForChanging(getUser, assessmentId) {
  const result = await httpGet(getUser, `/api/processassessment/getstatusforchanging/${assessmentId}`);
  return result;
}

async function postStatus(getUser, assessmentId, status) {
  const result = await httpPost(getUser, `/api/processassessment/updatestatus`, { id: assessmentId, status });
  return result;
}

const postFullAssessments = (getUser, request) => httpPost(getUser, `/api/assessments/full`, request);

const postAssessments = (getUser, request) => httpPost(getUser, `/api/assessments`, request);

const getFilterMetaData = getUser => httpGet(getUser, '/api/processassessment/getfiltermetadata');

async function exportAssessments(getUsers, filters) {
  return httpPost(getUsers, '/api/assessments/export', filters, { headers: {}, responseType: 'blob' });
}

const getFormattedSummaryAnswers = (getUser, id) =>
  httpGet(getUser, `/api/processassessment/getformattedsummaryanswers/${id}`);

const getRecommendation = (getUser, assessmentId) =>
  httpGet(getUser, `/api/processassessment/getrecommendation/${assessmentId}`);

async function changeGroup(getUser, assessmentId, newGroupId) {
  const result = await httpPost(getUser, `/api/processassessment/changefolder`, {
    id: Number(assessmentId),
    folderId: newGroupId,
  });
  return result;
}

const getNewAssessment = getUser => httpGet(getUser, '/api/processassessment');
const getAssessment = (getUser, id) => httpGet(getUser, `/api/processassessment/${id}`);
const getAssessmentMetaData = getUser => httpGet(getUser, '/api/processassessment/getmetadata');
const addAssessment = (getUser, groupId, assessment) => httpPost(getUser, `/api/group/${groupId}`, assessment);
const editAssessment = (getUser, assessment) => httpPost(getUser, '/api/processassessment', assessment);

const getNextMaintenanceTime = getUser => httpGet(getUser, '/api/maintenance/next');

export const assessments = {
  addAssessment,
  editAssessment,
  getStatusForChanging,
  postStatus,
  exportAssessments,
  getFormattedSummaryAnswers,
  getRecommendation,
  changeGroup,
  getNewAssessment,
  getAssessment,
  getAssessmentMetaData,
};

export const user = {
  isEmailInPortal,
  getRegistrationDetails,
  postRegistrationDetails,
  getRefreshedUserDetails,
  getUserDetails,
};

export const spaceManagement = {
  getSpace,
  getSpaces,
  getGroup,
  getGroupContent,
  getTeam,
  getTeamMembers,
  getSpaceTeams,
  getSpaceUsers,
  addSpace,
  addTeam,
  addGroup,
  addMemberToTeam,
  addSpaceUser,
  editSpace,
  editGroup,
  editTeam,
  editGroupMember,
  editSpaceUser,
  removeSpace,
  removeGroup,
  removeTeam,
  removeSpaceUser,
  removeMemberFromTeam,
  spaceUserSearch,
  spaceMemberSearch,
  getSpaceAdmins,
  removeSelfFromSpace,
  getGroupMembers,
  addGroupMember,
  removeMemberFromGroup,
};

export const yourProcesses = {
  postFullAssessments,
  postAssessments,
  getFilterMetaData,
};

export const maintenance = {
  getNextMaintenanceTime,
};
