import * as React from 'react';
import { generatePath } from 'react-router';
import PropTypes from 'prop-types';
import ProcessAssessmentSummaryEntry from './ProcessAssessmentSummaryEntry';
import AssessmentBenefitsSummary from './AssessmentBenefitsSummary';
import Stack from '@blueprism/ui-core/layout/Stack';
import Text from '@blueprism/ui-core/components/Text';
import { questionType } from 'pages/ProcessAssessment';

const getQuestionText = question =>
  (question.type === questionType.ENUM && !!question.helperText) || question.type === questionType.SLIDER
    ? question.questionText
    : question.label;

export default function AssessmentSummarySection({
  processId = '',
  processData = {},
  header = '',
  metaData = {},
  questions = [],
  filterIncomplete = false,
  formattedSummary = {},
  pageMetaData = {},
  currentPageType = 0,
}) {
  function getLinkToQuestionPage(questionName) {
    const pageName = Object.values(metaData.data.pages).find(
      page => page.type === 1 && page.questions.includes(questionName)
    ).pageName;
    const path = `/process-assessment/:processId/${pageName}`;
    return generatePath(path, { processId: processId });
  }

  const getFormattedAnswer = question => formattedSummary[question.fieldName];

  function getQuestions(questionName) {
    const question = metaData.data[questionName];
    const answer = getFormattedAnswer(question);
    const isAnswered = !processData.missingFields || !processData.missingFields.includes(question.fieldName);
    const isMandatory = question.isMandatory;
    if (filterIncomplete && isAnswered) {
      return null;
    }

    const questionText = getQuestionText(question);
    const questionLink = getLinkToQuestionPage(questionName);

    return (
      <ProcessAssessmentSummaryEntry
        key={questionName}
        questionText={questionText}
        link={questionLink}
        linkEnabled={!processData.isReadonly}
        fieldName={questionName}
        isAnswered={isAnswered}
        isMandatory={isMandatory}
        fieldValue={answer}
      />
    );
  }

  const questionElements = questions.map(getQuestions);

  if (!questionElements.some(x => x !== null)) {
    return null;
  }

  function getSummaryTemplate() {
    if (pageMetaData.summaryTemplate === 1) {
      return <AssessmentBenefitsSummary processData={processData} />;
    }

    return null;
  }

  return (
    <>
      <Stack gap="base">
        {header && <Text type="h3">{header}</Text>}
        <dl>
          <Stack gap="medium">{questionElements}</Stack>
        </dl>
      </Stack>
      {currentPageType === 2 && getSummaryTemplate()}
    </>
  );
}

AssessmentSummarySection.propTypes = {
  processId: PropTypes.string,
  processData: PropTypes.object,
  header: PropTypes.string,
  metaData: PropTypes.object,
  questions: PropTypes.array,
  filterIncomplete: PropTypes.bool,
  formattedSummary: PropTypes.object,
  pageMetaData: PropTypes.object,
  currentPageType: PropTypes.number,
};
