export const toUInt = (value, defValue = 0) => {
  const num = Number(value);

  if (isNaN(num) || num < 1) {
    return defValue;
  }

  return Math.floor(+num);
};

// returns true if the string is null, whitespace or parsable to a float within the specified range.
export const isEmptyOrFloatWithinRange = (str, min = 0, max = 2147483647) => {
  if (str !== null && str.toString().trim().length > 0) {
    var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(str)) return false;

    var floatVal = parseFloat(str, 10);
    if (isNaN(floatVal)) {
      return false;
    }

    if (floatVal < min || floatVal > max) {
      return false;
    }
  }
  return true;
};

// returns true if the string is null, whitespace or parsable to an integer within the specified range.
export const isEmptyOrIntWithinRange = (str, min = 0, max = 2147483647) => {
  if (str !== null && str.toString().trim().length > 0) {
    var intRegex = /^-?\d+$/;
    if (!intRegex.test(str)) return false;

    var intVal = parseInt(str, 10);
    if (parseFloat(str) !== intVal || isNaN(intVal)) {
      return false;
    }

    if (intVal < min || intVal > max) {
      return false;
    }
  }
  return true;
};

export const createOffsetLimitFragment = (offset, limit) => {
  const offsetFragment = offset || offset === 0 ? '/' + offset : '';
  const limitFragment = limit || limit === 0 ? '/' + limit : '';
  return `${offsetFragment}${limitFragment}`;
};

export function getPaging() {
  const query = new URLSearchParams(window.location.search);
  return { offset: toUInt(query.get('offset'), 0), limit: toUInt(query.get('limit'), 25) };
}

export const isValidEmail = email => {
  if (!!email === false) {
    return false;
  }

  const at = email.lastIndexOf('@');
  return at !== -1 && !!email.substring(0, at).trim() === true && email.substring(at).includes('.');
};
