import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Text from '@blueprism/ui-core/components/Text';
import Button from '@blueprism/ui-core/components/Button';
import PATDialog from 'components/PATDialog';
import ErrorMessage from 'components/ErrorMessage';
import DialogTitle from 'components/DialogTitle';

export default function SpaceTeamRemoveDialog({ open = false, teamId, teamName, onClose, onConfirm }) {
  const [dialogErrorMessage, setDialogErrorMessage] = React.useState({});
  const intl = useIntl();

  React.useEffect(() => {
    setDialogErrorMessage({});
  }, [open]);

  function handleClose() {
    onClose();
    setDialogErrorMessage('');
  }

  async function handleOkClick() {
    const error = await onConfirm(teamId);
    setDialogErrorMessage(error ?? {});
    if (!error) {
      onClose();
    }
  }

  function renderFooter() {
    return (
      <Row justify="between">
        <Button id="cancelButton" onClick={handleClose}>
          <FormattedMessage id="removeSpaceTeamClose" />
        </Button>
        <Button id="okButton" onClick={handleOkClick}>
          <FormattedMessage id="removeSpaceTeamOK" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="small"
      overlay
      visible={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      errorText={dialogErrorMessage?.dialogError}
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id="removeSpaceTeamHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      {!dialogErrorMessage && open && <ErrorMessage label={intl.formatMessage({ id: 'removeSpaceTeamToggleText' })} />}
      <Text id="teamNameText" aria-describedby="remove-space-team-details">
        {teamName}
      </Text>
    </PATDialog>
  );
}

SpaceTeamRemoveDialog.propTypes = {
  open: PropTypes.bool,
  teamId: PropTypes.number,
  teamName: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
