import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Avatar from '@blueprism/ui-core/components/Avatar';
import Text from '@blueprism/ui-core/components/Text';
import Row from '@blueprism/ui-core/layout/Row';
import Box from '@blueprism/ui-core/layout/Row';

const UserNameText = styled(Text)(
  props => css`
    font-weight: ${props.theme.font.weight.bold};
  `
);

const AvatarContainer = styled(Box)`
  flex-shrink: 0;
`;

const User = ({ avatarImage, userName }) => (
  <Row align="center" gap="small">
    <AvatarContainer width="4rem" height="4rem">
      <Avatar image={avatarImage} title={userName} />
    </AvatarContainer>
    <UserNameText>{userName}</UserNameText>
  </Row>
);

User.propTypes = {
  avatarImage: PropTypes.string,
  userName: PropTypes.string,
};

export default User;
