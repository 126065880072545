export const findBaseUrl = pathname => pathname.split(/[/?#]/)[1] ?? '';

export function getUrlTitle(url, intl) {
  switch (url) {
    case 'your-processes':
      return intl.formatMessage({ id: 'pageTitleYourProcesses' });

    case 'process-assessment':
      return intl.formatMessage({ id: 'pageTitleProcessAssessment' });

    case 'your-spaces':
      return intl.formatMessage({ id: 'pageTitleYourSpaces' });

    default:
      return url;
  }
}
