import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import DialogModal from '@blueprism/ui-core/components/DialogModal';
import Text from '@blueprism/ui-core/components/Text';
import DialogTitle from 'components/DialogTitle';
import ErrorMessage from 'components/ErrorMessage';

export default function ErrorDialog({ title, bodyText, errorText, open = false, buttonText, onClose, onOk }) {
  function renderFooter() {
    return (
      <Row justify="end">
        <Button onClick={onOk}>{buttonText || <FormattedMessage id="alertOK" />}</Button>
      </Row>
    );
  }
  return (
    <DialogModal
      visible={open}
      overlay
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      headerContent={<DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      footerContent={renderFooter()}
    >
      <ErrorMessage label={errorText} />
      <Text type="body">{bodyText}</Text>
    </DialogModal>
  );
}

ErrorDialog.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  open: PropTypes.bool,
  buttonText: PropTypes.string,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
};
