import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PATDialog from 'components/PATDialog';
import Switch from '@blueprism/ui-core/components/Switch';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import { UserContext } from 'contexts/UserContext';
import { SpaceContext } from 'contexts/SpaceContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { UsersContext } from 'contexts/UsersContext';
import ErrorMessage from 'components/ErrorMessage';
import DialogTitle from 'components/DialogTitle';
import User from 'components/User';
import { config } from 'utils/config';

export default function SpaceUserEditDialog({
  selectedUser = { member: {}, canEdit: false },
  clearSelection = () => {},
}) {
  const { user } = React.useContext(UserContext);
  const { userContext, editSpaceUser, handleEditClose } = React.useContext(UsersContext);
  const { reloadSpace } = React.useContext(SpaceContext);
  const { group, updateGroupsSpace } = React.useContext(GroupsContext);
  const intl = useIntl();

  const [isAdmin, setIsAdmin] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const displayWarningMessage =
    selectedUser.canEdit &&
    selectedUser.member.isAdmin &&
    !userContext.currentUserHasAccess &&
    selectedUser.member.userId === user.details?.userId;
  const warning = displayWarningMessage && intl.formatMessage({ id: 'spaceAdminRemovalWarning' });

  React.useEffect(() => {
    setErrors({});
  }, [userContext.isEditOpen]);

  React.useEffect(() => {
    setIsAdmin(selectedUser.canEdit ? selectedUser.member.isAdmin : false);
  }, [selectedUser]);

  async function handleOkClick() {
    const error = await editSpaceUser(selectedUser, isAdmin, clearSelection);
    if (!!error === false) {
      reloadSpace();
    }
    setErrors(error ?? {});

    if (displayWarningMessage) {
      if (group.groups) {
        updateGroupsSpace(group.spaceId, group.parentId, isAdmin);
      }
    }
  }

  function handleCancelClick() {
    handleEditClose();
  }

  function handleCheckedChange() {
    setIsAdmin(isAdminValue => !isAdminValue);
  }

  const getErrorMessage = fieldName => errors[fieldName];

  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={handleCancelClick}>
          <FormattedMessage id="editSpaceUserClose" />
        </Button>
        <Button onClick={handleOkClick}>
          <FormattedMessage id="editSpaceUserOK" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="medium"
      overlay
      visible={userContext.isEditOpen}
      onClose={handleEditClose}
      aria-labelledby="simple-dialog-title"
      errorText={getErrorMessage('dialogError')}
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id="editSpaceUserHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <User
        userName={selectedUser.member.name}
        avatarImage={selectedUser.member.avatar && config.imageUrl(selectedUser.member.avatar)}
      />
      <Switch
        id="is-admin-switch"
        align="right"
        displayType="justified"
        label={intl.formatMessage({ id: 'editSpaceUserToggleText' })}
        checked={isAdmin}
        onChange={handleCheckedChange}
        value="checkedB"
        name="admin"
      />
      {warning && <ErrorMessage label={warning} />}
    </PATDialog>
  );
}
