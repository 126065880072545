import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, Row, ButtonGroup } from '@blueprism/ui-core';
import styled, { css } from 'styled-components';

const StyledTitle = styled(Text)(
  ({ theme }) => css`
    font-size: ${theme.font.size.h5}rem;
    font-weight: ${theme.font.weight.black};
  `
);

const StickyHeader = styled(Row)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    background: ${theme.color.paper};
    z-index: ${theme.layer.hovering};
  `
);

const DrawerContent = ({ title, buttons, children }) => (
  <Stack padding="base" gap="large">
    <StickyHeader element="header" justify="between" align="center">
      <StyledTitle>{title}</StyledTitle>
      <ButtonGroup>{buttons}</ButtonGroup>
    </StickyHeader>
    {children}
  </Stack>
);

DrawerContent.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.node,
  children: PropTypes.node,
};

export default DrawerContent;
