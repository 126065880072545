import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Stack, Box } from '@blueprism/ui-core';
import TablePagination from 'components/TablePagination';
import { YourProcessesContext } from 'contexts/YourProcessesContextProvider';
import TableLoader from 'components/TableStates/TableLoader';
import { toUInt } from 'utils/helper';
const TableWrapper = styled(Box)`
  overflow-x: auto;
`;

export default function YourProcessesTable({
  selectedColumns = [],
  selectedRowIds = [],
  onRowSelectionChange = () => {},
}) {
  const intl = useIntl();
  const { columns, status, state, requestUpdate, setStoredColumnsForUser } = React.useContext(YourProcessesContext);
  const [paging, setPaging] = React.useState(state.paging);
  const rowData = React.useMemo(
    () => ({
      paging: state.paging,
      data: state.assessments.items.map(assessment => ({
        id: assessment.id.toString(),
        Recommendation: assessment.recommendation.title,
        Title: assessment.processTitle,
        Type: assessment.processType,
        Analyst: assessment.processAnalyst,
        Owner: assessment.processOwner,
        ProcessId: assessment.processId,
        BusinessFunction: assessment.businessFunction,
        ReasonForAutomating: assessment.reasonForAutomating,
        CreateDate: intl.formatDate(assessment.createdAt),
        Group: assessment.folderName,
        CapitalSavings:
          assessment.currency && assessment.potentialSavingCost !== null
            ? intl.formatNumber(assessment.potentialSavingCost, {
                style: 'currency',
                currency: assessment.currency,
              })
            : assessment.potentialSavingCost,
        HoursSavings: assessment.potentialSavingHours,
        ApScore: assessment.recommendation.apScore
          ? intl.formatNumber(assessment.recommendation.apScore, { minimumFractionDigits: 1 })
          : null,
        IeScore: assessment.recommendation.ieScore
          ? intl.formatNumber(assessment.recommendation.ieScore, { minimumFractionDigits: 1 })
          : null,
        Status: assessment.status,
        Space: assessment.space,
        Tags: assessment.tags,
      })),
    }),
    [intl, state.assessments.items, state.paging]
  );

  React.useEffect(() => {
    setPaging(currentPaging =>
      currentPaging.limit === rowData.paging.limit && currentPaging.offset === rowData.paging.offset
        ? currentPaging
        : rowData.paging
    );
  }, [rowData.paging]);

  function handleTablePagingChange({ offset = paging.offset, limit = paging.limit }) {
    setPaging({ offset, limit });
    requestUpdate({ offset, limit });
  }

  function handlePageLimitChange(limit) {
    handleTablePagingChange({ limit: toUInt(limit, 25) });
  }

  function handleNextPageClick() {
    handleTablePagingChange({ offset: paging.offset + paging.limit });
  }

  function handlePreviousPageClick() {
    handleTablePagingChange({ offset: Math.max(0, paging.offset - paging.limit) });
  }

  function handleHeaderClick(sortColumn) {
    const [column, direction] = Object.entries(sortColumn)[0];
    const sorting = direction === 'none' ? null : { column, direction };

    setStoredColumnsForUser({ selectedColumns, sorting });
    requestUpdate({ sorting });
  }

  function handleRowSelectionChange(row) {
    onRowSelectionChange(row);
  }

  return (
    <Stack gap="base">
      <TableWrapper>
        <TableLoader
          tableStatus={status}
          emptyStateTitle={intl.formatMessage({ id: 'yourProcessesTableNoDataTitle' })}
          emptyStateText={intl.formatMessage({ id: 'yourProcessesTableNoDataText' })}
          errorStateText={intl.formatMessage({ id: 'yourProcessesTableDataFetchFail' })}
          selectRows
          sortColumns
          tableLayout="auto"
          selectHeaderLabel="Selected"
          rowData={rowData.data}
          columns={columns.filter(column => selectedColumns.includes(column.accessor))}
          selectedIds={selectedRowIds}
          onRowSelectionChange={handleRowSelectionChange}
          onHeaderClick={handleHeaderClick}
        />
      </TableWrapper>
      <TablePagination
        limitLabelId="your-processes-limit"
        count={state.assessments.count}
        offset={paging.offset}
        limit={paging.limit}
        itemName="Assessments"
        onLimitChange={handlePageLimitChange}
        onNextData={handleNextPageClick}
        onPreviousData={handlePreviousPageClick}
      />
    </Stack>
  );
}

YourProcessesTable.propTypes = {
  selectedColumns: PropTypes.array,
  selectedRowIds: PropTypes.arrayOf(PropTypes.string),
  onRowSelectionChange: PropTypes.func,
};
