import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import DialogModal from '@blueprism/ui-core/components/DialogModal';
import Text from '@blueprism/ui-core/components/Text';
import DialogTitle from 'components/DialogTitle';

export default function AlertDialog({ title, text, open = false, buttonText, onClose, onOk }) {
  function renderFooter() {
    return (
      <Row justify="end">
        <Button id="ok-button" onClick={onOk}>
          {buttonText || <FormattedMessage id="alertOK" />}
        </Button>
      </Row>
    );
  }
  return (
    <DialogModal
      visible={open}
      overlay
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      headerContent={<DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      footerContent={renderFooter()}
    >
      <Text type="body">{text}</Text>
    </DialogModal>
  );
}

AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
