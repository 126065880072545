import React from 'react';
import Text from '@blueprism/ui-core/components/Text';
import DatalistIndexed from '@blueprism/ui-core/components/DatalistIndexed';
import Option from '@blueprism/ui-core/components/Option';
import FormField from '@blueprism/ui-core/components/FormField';
import ToggleTip from 'components/core/ToggleTip';
import { QuestionMark } from '@blueprism/ui-icons';

const AssessmentSelectQuestion = props => {
  const metaData = props.metaData;
  const enumName = props.question.enum;
  const fieldName = props.question.fieldName;

  const handleChange = e => {
    if (e.target.value != null) {
      props.handleChange(fieldName, e.target.value);
    } else {
      var textInput = document.getElementById(fieldName);
      var val = textInput.value?.trim() ?? '';
      props.handleChange(fieldName, val);
    }
  };

  const datalistProps = {};
  datalistProps['id'] = fieldName;
  datalistProps['name'] = fieldName;
  datalistProps['label'] = props.question.label;
  datalistProps['onChange'] = handleChange;
  datalistProps['error'] = props.isInError;

  if (props.value !== null && metaData[enumName][props.value]) {
    datalistProps['value'] = metaData[enumName][props.value];
  }

  return (
    <>
      {props.question.questionText && <Text type="body">{props.question.questionText}</Text>}
      {props.question.helperText && <ToggleTip detailsText={props.question.helperText} icon={<QuestionMark />} />}
      <FormField
        label={props.question.label}
        error={props.isInError}
        helperText={props.validationLabel}
        errorText={props.errorText}
      >
        <DatalistIndexed {...datalistProps}>
          {Object.keys(metaData[enumName]).map(x => (
            <Option key={x} value={x}>
              {metaData[enumName][x]}
            </Option>
          ))}
        </DatalistIndexed>
      </FormField>
    </>
  );
};

export default AssessmentSelectQuestion;
