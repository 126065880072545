import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TourPage from './TourPage';
import AvatarProgressModal from 'components/AvatarProgressModal';
import { config } from 'utils/config';
export default function Tour({ visible = false, avatarImage, firstName = '', onClose = () => {} }) {
  const intl = useIntl();
  const [pageIndex, setPageIndex] = React.useState(0);

  React.useEffect(() => {
    if (visible) {
      setPageIndex(0);
    }
  }, [visible]);

  function handleOnCloseClick() {
    onClose();
  }

  function handleOnContinueClick() {
    setPageIndex(pageIndexValue => pageIndexValue + 1);
  }

  return (
    <AvatarProgressModal
      visible={visible}
      avatarImage={avatarImage && config.imageUrl(avatarImage)}
      page={pageIndex}
      onClose={onClose}
      overlay
      height="25rem"
      width="30rem"
    >
      <TourPage
        preTitle={intl.formatMessage({ id: 'tourWelcomeUser' }, { firstName })}
        title={intl.formatMessage({ id: 'loginTitle' })}
        description={intl.formatMessage({ id: 'loginDescription' })}
        onClose={handleOnCloseClick}
        onContinue={handleOnContinueClick}
      />
      <TourPage
        id="menuItemYourProcesses"
        preTitle={intl.formatMessage({ id: 'tourPage1H1' })}
        title={intl.formatMessage({ id: 'tourPage1H2' })}
        description={intl.formatMessage({ id: 'tourPage1Content' })}
        onClose={handleOnCloseClick}
        onContinue={handleOnContinueClick}
      />
      <TourPage
        id="menuItemProcessAsessment"
        preTitle={intl.formatMessage({ id: 'tourPage2H1' })}
        title={intl.formatMessage({ id: 'tourPage2H2' })}
        description={intl.formatMessage({ id: 'tourPage2Content' })}
        onClose={handleOnCloseClick}
        onContinue={handleOnContinueClick}
      />
      <TourPage
        id="menuItemGroupManagement"
        preTitle={intl.formatMessage({ id: 'tourPage3H1' })}
        title={intl.formatMessage({ id: 'tourPage3H2' })}
        description={intl.formatMessage({ id: 'tourPage3Content' })}
        onClose={handleOnCloseClick}
        isLast
      />
    </AvatarProgressModal>
  );
}

Tour.propTypes = {
  visible: PropTypes.bool,
  avatarImage: PropTypes.string,
  firstName: PropTypes.string,
  onClose: PropTypes.func,
};
