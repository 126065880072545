import React, { useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from 'contexts/AuthContext';

const OidcCallback = props => {
  const { userManager, recheckLoggedInState } = useContext(AuthContext);

  useEffect(() => {
    userManager
      .signinRedirectCallback()
      .then(user => recheckLoggedInState())
      .catch(error => {
        console.log(error);
        window.location.href = '/login';
      });
  }, [userManager, recheckLoggedInState]);

  return (
    <div>
      <FormattedMessage id="logonCallbackRedirect" />
    </div>
  );
};

export default OidcCallback;
