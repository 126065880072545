import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from 'contexts/AuthContext';
import { config } from 'utils/config';

export default function OidcLogoutCallback() {
  const { userManager } = React.useContext(AuthContext);

  React.useEffect(() => {
    userManager
      .signoutRedirectCallback()
      .then(() => (window.location.href = config.logoutUrl))
      .catch(error => console.log(error));
  }, [userManager]);

  return (
    <div>
      <FormattedMessage id="logonCallbackRedirect" />
    </div>
  );
}
