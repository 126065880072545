import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { QuestionMark } from '@blueprism/ui-icons';
import FormField from '@blueprism/ui-core/components/FormField';
import Text from '@blueprism/ui-core/components/Text';
import ToggleTip from 'components/core/ToggleTip';
import NumberInput from './core/NumberInput/NumberInput';

const AssessmentNumberQuestion = ({
  value,
  label,
  questionText,
  helperText,
  maxValue,
  fieldName,
  handleChange,
  isInError,
  validationLabel,
  errorText = '',
}) => {
  const [localValue, setValue] = useState(value !== null ? value : '');

  const handleInputChange = event => {
    setValue(event.target.value);
    handleChange(fieldName, event.target.value);
  };
  return (
    <>
      {questionText && <Text type="body">{questionText}</Text>}
      <FormField label={label} error={isInError} helperText={validationLabel} errorText={errorText}>
        <>{helperText && <ToggleTip detailsText={helperText} icon={<QuestionMark />} />}</>
        <NumberInput
          name={fieldName}
          id={fieldName}
          value={localValue}
          max={maxValue}
          error={isInError}
          onChange={handleInputChange}
        />
      </FormField>
    </>
  );
};

AssessmentNumberQuestion.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  questionText: PropTypes.string,
  helperText: PropTypes.string,
  maxValue: PropTypes.number,
  fieldName: PropTypes.string,
  handleChange: PropTypes.func,
  isInError: PropTypes.bool,
  validationLabel: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default AssessmentNumberQuestion;
