import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '@blueprism/ui-core/components/Text';
import InputField from '@blueprism/ui-core/components/InputField';
import FormField from '@blueprism/ui-core/components/FormField';
import ToggleTip from 'components/core/ToggleTip';
import { QuestionMark } from '@blueprism/ui-icons';

export default function AssessmentTextQuestion({
  fieldName = '',
  value = '',
  question = {},
  validationLabel = '',
  errorText = '',
  isInError = false,
  onChange,
}) {
  function handleChange(event) {
    onChange(fieldName, event.target.value);
  }

  return (
    <>
      {question.questionText && <Text type="body">{question.questionText}</Text>}
      {question.helperText && <ToggleTip detailsText={question.helperText} icon={<QuestionMark />} />}
      <FormField label={question.label} error={isInError} helperText={validationLabel} errorText={errorText}>
        <InputField type="text" name={fieldName} id={fieldName} value={value} onChange={handleChange} />
      </FormField>
    </>
  );
}

AssessmentTextQuestion.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  question: PropTypes.object,
  validationLabel: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isInError: PropTypes.bool,
  onChange: PropTypes.func,
};
