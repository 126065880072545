import React from 'react';
import PropTypes from 'prop-types';
import { default as UiCoreLink } from '@blueprism/ui-core/components/Link';
import { useHistory } from 'react-router-dom';

export default function Link({ children, href = '', useReplace = false, ...rest }) {
  const history = useHistory();

  function handleOnClick(event) {
    event.preventDefault();
    if (useReplace) {
      history.replace(href);
      return;
    }

    history.push(href);
  }

  return (
    <UiCoreLink href={href} onClick={handleOnClick} {...rest}>
      {children}
    </UiCoreLink>
  );
}

Link.propTypes = {
  /** The content describing the `Link`. This is usually a string but can also be a component tree. */
  children: PropTypes.node.isRequired,
  useReplace: PropTypes.bool,
  /** The URL destination. */
  href: PropTypes.string,
};
