import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DatalistIndexed from '@blueprism/ui-core/components/DatalistIndexed';
import Option from '@blueprism/ui-core/components/Option';
import FormField from '@blueprism/ui-core/components/FormField';
import Cluster from '@blueprism/ui-core/layout/Cluster';
import NumberInput from 'components/core/NumberInput/NumberInput';

const InputContainer = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
`;

const Label = styled.label(
  props => css`
    font-size: ${props.theme.font.size.caption}rem;
    line-height: 1rem;
    min-height: 2rem;
    padding: 0.5rem 0;
    flex-grow: 1;
  `
);

function CurrencyInput({
  id,
  label,
  helperText = '',
  errorText = '',
  currencyValue = '',
  currencyLabel = '',
  currencyAriaLabel,
  currencyData = [],
  currencyError = false,
  amountValue = '',
  amountLabel = '',
  amountAriaLabel,
  amountError = false,
  onCurrencyChange = () => {},
  onAmountChange = () => {},
}) {
  return (
    <FormField
      label={label}
      error={amountError || currencyError}
      errorText={errorText}
      helperText={helperText}
      isFieldset
    >
      <Cluster padding="none" gap="small">
        <InputContainer>
          <Label htmlFor={`currency-datalist-indexed-${id}`} aria-label={currencyAriaLabel}>
            {currencyLabel}
          </Label>
          <DatalistIndexed
            id={`currency-datalist-indexed-${id}`}
            defaultValue={currencyValue}
            error={currencyError}
            onChange={onCurrencyChange}
          >
            {currencyData.map(([key, value]) => (
              <Option key={value} value={key}>
                {value}
              </Option>
            ))}
          </DatalistIndexed>
        </InputContainer>
        <InputContainer>
          <Label htmlFor={`currency-input-field-${id}`} aria-label={amountAriaLabel}>
            {amountLabel}
          </Label>
          <NumberInput
            id={`currency-input-field-${id}`}
            value={amountValue}
            error={amountError}
            onChange={onAmountChange}
          />
        </InputContainer>
      </Cluster>
    </FormField>
  );
}

CurrencyInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  currencyValue: PropTypes.string,
  currencyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  currencyAriaLabel: PropTypes.string,
  currencyError: PropTypes.bool,
  currencyData: PropTypes.array,
  amountValue: PropTypes.string,
  amountLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  amountAriaLabel: PropTypes.string,
  amountError: PropTypes.bool,
  onCurrencyChange: PropTypes.func,
  onAmountChange: PropTypes.func,
};

export default CurrencyInput;
