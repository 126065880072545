import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Row, Placeholder } from '@blueprism/ui-core';

const OpacityRow = styled(Row)(
  props => css`
    opacity: ${props.opacity};
  `
);

const LoadingState = ({ lines = 6, pauseAnimation = false }) =>
  Array.from({ length: lines + 1 }, (_, index) => {
    const height = index === 0 ? '30px' : '3.5rem';
    return (
      <OpacityRow gap="base" key={index} data-testid={`loading-${index}`} opacity={1.2 - index / lines}>
        <Placeholder height={height} width="25%" pauseAnimation={pauseAnimation} />
        <Placeholder height={height} width="25%" pauseAnimation={pauseAnimation} />
        <Placeholder height={height} width="25%" pauseAnimation={pauseAnimation} />
        <Placeholder height={height} width="25%" pauseAnimation={pauseAnimation} />
      </OpacityRow>
    );
  });

export default LoadingState;

LoadingState.propTypes = {
  lines: PropTypes.number,
  pauseAnimation: PropTypes.bool,
};
