import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row, Stack, InputFieldLabel } from '@blueprism/ui-core';
import NumberInput from '../NumberInput/NumberInput';

const StyledInputFieldLabel = styled(InputFieldLabel)`
  div:nth-child(1) {
    text-transform: capitalize;
    div:nth-child(1) {
      display: none;
    }
  }
`;

const padZeros = (value, numberOfZeros = 2) => String(value).padStart(numberOfZeros, '0');

export function toISOLocalString({ year, month, day }) {
  const newDate = new Date(year, month, day);

  if (isNaN(newDate) || year < 1000) {
    return 'Invalid Date';
  }

  const timezoneOffset = newDate.getTimezoneOffset();
  const posOffset = Math.abs(timezoneOffset);
  const dateOffset = `${padZeros(Math.floor(posOffset / 60))}:${padZeros(posOffset % 60)}`;

  return `${padZeros(year)}-${padZeros(month)}-${padZeros(day)}T00:00:00.000${
    timezoneOffset <= 0 ? '+' : '-'
  }${dateOffset}`;
}

const isPassableDate = ({ year, month, day }) =>
  !!day === false || !!month === false || !!year === false ? false : true;

const isOneFieldEmpty = ({ year, month, day }) => (day === '' || month === '' || year === '' ? true : false);

export default function DateInput({ id, error = false, onChange = () => {} }) {
  const intl = useIntl();
  const [date, setDate] = React.useState({ day: '', month: '', year: '' });
  const isDateValid = React.useRef(false);
  const previousDate = React.useRef({ day: '', month: '', year: '' });

  const dateParts = React.useRef(
    (() =>
      Intl.DateTimeFormat(intl.locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
        hour12: true,
        timeZone: intl.timezone,
      })
        .formatToParts(new Date())
        .filter(part => ['day', 'month', 'year'].some(key => key === part.type))
        .map(part => part.type))()
  );

  function handleChange(event) {
    const { name, value } = event.target;
    setDate(prevDate => ({ ...prevDate, [name]: value }));
  }

  function handleBlur(event) {
    const { value, name } = event.target;
    if (previousDate.current[name] === value) {
      return;
    }

    if (isOneFieldEmpty(date)) {
      if (isDateValid.current) {
        onChange('');
        previousDate.current = { day: '', month: '', year: '' };
        isDateValid.current = false;
      }

      return;
    }

    if (isPassableDate(date)) {
      onChange(toISOLocalString(date));
      isDateValid.current = true;
      previousDate.current = { ...date };
    }
  }

  return (
    <Row width="100%" justify="start" gap="base">
      {dateParts.current.map(type => (
        <Stack key={`${type}-input`}>
          <StyledInputFieldLabel label={intl.formatMessage({ id: type })} htmlFor={`${id}-${type}`} />
          <NumberInput
            id={`${id}-${type}`}
            name={type}
            value={date[type]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error}
            max={2000}
          />
        </Stack>
      ))}
    </Row>
  );
}

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  onChange: PropTypes.func,
};
