import * as React from 'react';
import PropTypes from 'prop-types';
import TextFilter from './TextFilter';
import DateFilter from './DateFilter';
import CheckboxFilter from './CheckboxFilter';
import TagsFilter from './TagsFilter';
import NumberFilter from './NumberFilter';
import CurrencyFilter from './CurrencyFilter';

export const filterType = {
  TEXT: 0,
  CHECKBOX: 1,
  NUMBER: 2,
  DATE: 3,
  CURRENCY: 4,
  TAG: 5,
};

export default function FilterFactory({ enabled, filter, validationErrors = {}, onFilterValuesChange = () => {} }) {
  const isInError = fieldName => !!validationErrors[fieldName];

  function handleFilterValuesChange(changedValues) {
    onFilterValuesChange(changedValues);
  }

  switch (filter.filterType) {
    case filterType.TEXT:
      return (
        <TextFilter
          enabled={enabled}
          fieldName={filter.fieldName}
          label={filter.textInputLabel}
          helperText={filter.textInputHelperText}
          onChange={handleFilterValuesChange}
        />
      );

    case filterType.CHECKBOX:
      return (
        <CheckboxFilter
          enabled={enabled}
          fieldName={filter.fieldName}
          label={filter.checkboxOptionsLabel}
          helperText={filter.checkboxOptionsHelperText}
          options={filter.options}
          onChange={handleFilterValuesChange}
        />
      );

    case filterType.NUMBER:
      return (
        <NumberFilter
          enabled={enabled}
          fieldName={filter.fieldName}
          width="8rem"
          labelMin={filter.numberInputMinLabel}
          helperTextMin={filter.numberInputMinHelperText}
          labelMax={filter.numberInputMaxLabel}
          helperTextMax={filter.numberInputMaxHelperText}
          isInErrorMin={isInError(`Filters.${filter.fieldName}Min`)}
          isInErrorMax={isInError(`Filters.${filter.fieldName}Max`)}
          errorTextMin={validationErrors[`Filters.${filter.fieldName}Min`]}
          errorTextMax={validationErrors[`Filters.${filter.fieldName}Max`]}
          onChange={handleFilterValuesChange}
        />
      );

    case filterType.DATE:
      return (
        <DateFilter
          enabled={enabled}
          fieldName={filter.fieldName}
          labelMin={filter.dateFromLabel}
          labelMax={filter.dateToLabel}
          helperTextMin={filter.dateFromHelperText}
          helperTextMax={filter.dateToHelperText}
          isInErrorMin={isInError(`Filters.${filter.fieldName}Min`)}
          isInErrorMax={isInError(`Filters.${filter.fieldName}Max`)}
          errorTextMin={validationErrors[`Filters.${filter.fieldName}Min`]}
          errorTextMax={validationErrors[`Filters.${filter.fieldName}Max`]}
          onChange={handleFilterValuesChange}
        />
      );

    case filterType.CURRENCY:
      return (
        <CurrencyFilter
          enabled={enabled}
          fieldName={filter.fieldName}
          labelCurrency={filter.currencyInputLabel}
          helperTextCurrency={filter.currencyInputHelperText}
          currencyOptions={filter.currencyOptions}
          helperTextMin={filter.minimumSavingHelperText}
          labelMin={filter.minimumSavingLabel}
          helperTextMax={filter.maximumSavingHelperText}
          labelMax={filter.maximumSavingLabel}
          isInErrorCurrency={isInError(`Filters.${filter.fieldName}Currency`)}
          isInErrorMin={isInError(`Filters.${filter.fieldName}MinAmount`)}
          isInErrorMax={isInError(`Filters.${filter.fieldName}MaxAmount`)}
          errorTextCurrency={validationErrors[`Filters.${filter.fieldName}Currency`]}
          errorTextMin={validationErrors[`Filters.${filter.fieldName}MinAmount`]}
          errorTextMax={validationErrors[`Filters.${filter.fieldName}MaxAmount`]}
          onChange={handleFilterValuesChange}
        />
      );

    case filterType.TAG:
      return (
        <TagsFilter
          enabled={enabled}
          fieldName={filter.fieldName}
          label={filter.textInputLabel}
          helperText={filter.textInputHelperText}
          onChange={handleFilterValuesChange}
        />
      );

    default:
      return null;
  }
}

FilterFactory.propTypes = {
  enabled: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  validationErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  onFilterValuesChange: PropTypes.func,
};
