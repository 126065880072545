import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { InputField } from '@blueprism/ui-core';

const InputFieldRightAligned = styled(InputField)(
  ({ fieldLength, theme }) => css`
    text-align: right;
    width: ${fieldLength > 0 ? fieldLength + 1 + 'rem' : '100%'};
    min-width: ${theme.globalSizes.xxl}rem;
  `
);

export default function NumberInput({ max = null, ...rest }) {
  let fieldLength = 0;
  if (max !== null) {
    if (max.toString().length <= 10) {
      fieldLength = max.toString().length;
    }
  }
  return <InputFieldRightAligned type="text" pattern="d*" fieldLength={fieldLength} {...rest} />;
}

NumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
};
