import React, { useState } from 'react';
import Option from '@blueprism/ui-core/components/Option';
import Text from '@blueprism/ui-core/components/Text';
import Datalist from '@blueprism/ui-core/components/Datalist';
import FormField from '@blueprism/ui-core/components/FormField';
import ToggleTip from 'components/core/ToggleTip';
import { QuestionMark } from '@blueprism/ui-icons';

const AssessmentDataListQuestion = props => {
  const [value, setValue] = useState(props.value || '');

  const metaData = props.metaData;
  const enumName = props.question.enum;
  const fieldName = props.question.fieldName;
  const handleChange = e => {
    setValue(e.target.value);
    props.handleChange(fieldName, e.target.value);
  };

  return (
    <>
      {props.question.questionText && <Text type="body">{props.question.questionText}</Text>}
      {props.question.helperText && <ToggleTip detailsText={props.question.helperText} icon={<QuestionMark />} />}
      <FormField
        label={props.question.label}
        error={props.isInError}
        helperText={props.validationLabel}
        errorText={props.errorText}
      >
        <Datalist id={fieldName} name={fieldName} value={value} error={props.isInError} onChange={handleChange}>
          {Object.keys(metaData[enumName]).map(x => (
            <Option key={x} value={metaData[enumName][x]}>
              {metaData[enumName][x]}
            </Option>
          ))}
        </Datalist>
      </FormField>
    </>
  );
};

export default AssessmentDataListQuestion;
