import React from 'react';

import YourProcessesToolbar from './YourProcessesTableToolbar';
import YourProcessesTable from './YourProcessesTable';
import { YourProcessesContext } from 'contexts/YourProcessesContextProvider';

export default function YourProcessesTableContainer({
  onRowSelectionChange = () => {},
  selectedRowIds = [],
  onShowDetailsChanged = () => {},
}) {
  const { getStoredColumnsForUser, setStoredColumnsForUser } = React.useContext(YourProcessesContext);

  const [selectedColumns, setSelectedColumns] = React.useState(getStoredColumnsForUser().selectedColumns);

  function handleSelectedColumnsChange(columns) {
    setStoredColumnsForUser({ selectedColumns: columns });
    setSelectedColumns(columns);
  }

  return (
    <>
      <YourProcessesToolbar
        selectedRowId={selectedRowIds[0]}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={handleSelectedColumnsChange}
        onShowDetailsChanged={onShowDetailsChanged}
      />
      <YourProcessesTable
        selectedColumns={selectedColumns}
        selectedRowIds={selectedRowIds}
        onRowSelectionChange={onRowSelectionChange}
      />
    </>
  );
}
