import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import PATDialog from 'components/PATDialog';
import { SpaceContext } from 'contexts/SpaceContext';
import ErrorMessage from 'components/ErrorMessage';
import DialogTitle from 'components/DialogTitle';
import User from 'components/User';
import { config } from 'utils/config';

export default function SpaceUserRemoveDialog({ user, open = false, onConfirm, onClose }) {
  const intl = useIntl();
  const { reloadSpace } = React.useContext(SpaceContext);

  const { name, avatar, email } = user;
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    setErrors({});
  }, [open]);

  function handleClose() {
    onClose();
  }

  function handleCloseClick() {
    onClose();
  }

  const handleOkClick = async () => {
    const error = await onConfirm(user.userId);
    if (!!error === false) {
      reloadSpace();
    }
    setErrors(error ?? {});
  };

  const getErrorMessage = fieldName => errors[fieldName];
  const isInError = fieldName => errors[fieldName] != null && errors[fieldName].length > 0;

  function renderFooter() {
    return (
      <Row justify="between">
        <Button id="cancelButton" onClick={handleCloseClick}>
          <FormattedMessage id="removeSpaceUserClose" />
        </Button>
        <Button id="okButton" onClick={handleOkClick}>
          <FormattedMessage id="removeSpaceUserOK" />
        </Button>
      </Row>
    );
  }
  return (
    <PATDialog
      size="small"
      overlay
      errorText={getErrorMessage('dialogError')}
      visible={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id="removeSpaceUserHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      {!isInError('dialogError') && <ErrorMessage label={intl.formatMessage({ id: 'removeSpaceUserToggleText' })} />}
      <User userName={`${name} ${email ? `(${email})` : null}`} avatarImage={avatar && config.imageUrl(avatar)} />
    </PATDialog>
  );
}

SpaceUserRemoveDialog.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};
