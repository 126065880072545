import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import { FolderAdd, FolderDelete, FolderEdit } from '@blueprism/ui-icons';
import { GroupsContext } from 'contexts/GroupsContext';
import GroupNameDialog from 'components/GroupNameDialog';
import GroupRemoveDialog from 'components/GroupRemoveDialog';

const GroupNavButtons = ({ selectedRowIds = [], clearSelection = () => {}, isAdmin }) => {
  const intl = useIntl();
  const {
    group,
    openAddDialog,
    handleAddClose,
    addGroup,
    openEditDialog,
    handleEditClose,
    editGroup,
    openRemoveDialog,
    removeGroup,
  } = useContext(GroupsContext);

  if (!group.spaceId) {
    return null;
  }

  const currentGroup =
    selectedRowIds.length === 1
      ? group.groups?.items.find(groupItem => groupItem.id.toString() === selectedRowIds[0])
      : null;

  const renderButtons = () => {
    return (
      <ButtonGroup>
        <ToolbarButton
          disabled={!isAdmin}
          onClick={openAddDialog}
          id="spaceGroupAdd"
          label={intl.formatMessage({ id: 'spaceGroupAdd' })}
          icon={<FolderAdd />}
        />
        <ToolbarButton
          disabled={!currentGroup}
          onClick={openRemoveDialog}
          id="spaceGroupRemove"
          label={intl.formatMessage({ id: 'spaceGroupRemove' })}
          icon={<FolderDelete />}
        />
        <ToolbarButton
          disabled={!currentGroup}
          onClick={openEditDialog}
          id="spaceGroupEdit"
          label={intl.formatMessage({ id: 'spaceGroupEdit' })}
          icon={<FolderEdit />}
        />
      </ButtonGroup>
    );
  };

  function handleAddGroup(groupParam, text) {
    return addGroup(groupParam, text, clearSelection);
  }

  function handleRemoveConfirm(groupId) {
    return removeGroup(groupId, clearSelection);
  }

  function handleEditGroup(id, name) {
    return editGroup(id, name, clearSelection);
  }
  return (
    <>
      {renderButtons()}
      <GroupNameDialog
        mode="add"
        open={group.isAddOpen}
        onConfirm={handleAddGroup}
        onClose={handleAddClose}
        parentGroupId={group.parentId}
      />
      <GroupNameDialog
        mode="edit"
        open={group.isEditOpen}
        onConfirm={handleEditGroup}
        onClose={handleEditClose}
        group={currentGroup}
      />
      <GroupRemoveDialog onConfirm={handleRemoveConfirm} selectedGroup={currentGroup} />
    </>
  );
};

export default GroupNavButtons;
