import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import NumberInput from '../NumberInput/NumberInput';
import InputFieldLabel from '@blueprism/ui-core/components/InputFieldLabel';
import Row from '@blueprism/ui-core/layout/Row';
import Stack from '@blueprism/ui-core/layout/Stack';

const StyledInputFieldLabel = styled(InputFieldLabel)`
  div:nth-child(1) {
    div:nth-child(1) {
      display: none;
    }
  }
`;

export default function DurationInput({
  id,
  value = { hours: '', minutes: '', seconds: '' },
  errorHours = false,
  errorMinutes = false,
  errorSeconds = false,
  onChange = () => {},
}) {
  const [hours, setHours] = React.useState(value.hours);
  const [minutes, setMinutes] = React.useState(value.minutes);
  const [seconds, setSeconds] = React.useState(value.seconds);
  const intl = useIntl();

  function handleChange({ target: { value: newValue, name } }) {
    switch (name) {
      case 'hours':
        setHours(newValue);
        onChange({ hours: newValue, minutes: String(minutes ?? ''), seconds: String(seconds ?? '') });
        break;

      case 'minutes':
        setMinutes(newValue);
        onChange({ hours: String(hours ?? ''), minutes: newValue, seconds: String(seconds ?? '') });
        break;

      case 'seconds':
        setSeconds(newValue);
        onChange({ hours: String(hours ?? ''), minutes: String(minutes ?? ''), seconds: newValue });
        break;

      default:
        console.log('default action');
        break;
    }
  }

  return (
    <Row width="100%" justify="start" gap="base">
      <Stack align="start">
        <StyledInputFieldLabel label={intl.formatMessage({ id: 'hours' })} htmlFor={`${id}-hours`} />
        <NumberInput id={`${id}-hours`} name="hours" value={hours} onChange={handleChange} error={errorHours} />
      </Stack>
      <Stack align="start">
        <StyledInputFieldLabel label={intl.formatMessage({ id: 'minutes' })} htmlFor={`${id}-minutes`} />
        <NumberInput id={`${id}-minutes`} name="minutes" value={minutes} onChange={handleChange} error={errorMinutes} />
      </Stack>
      <Stack align="start">
        <StyledInputFieldLabel label={intl.formatMessage({ id: 'seconds' })} htmlFor={`${id}-seconds`} />
        <NumberInput id={`${id}-seconds`} name="seconds" value={seconds} onChange={handleChange} error={errorSeconds} />
      </Stack>
    </Row>
  );
}

DurationInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  errorHours: PropTypes.bool,
  errorMinutes: PropTypes.bool,
  errorSeconds: PropTypes.bool,
  onChange: PropTypes.func,
};
