import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { AuthContext } from 'contexts/AuthContext';
import { dataStates as tableDataStates } from '../utils/dataStates';
import { spaceManagement } from 'utils/api';

export const GroupsContext = createContext();

export default function GroupsContextProvider({ children }) {
  const history = useHistory();
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const [group, setGroup] = useState({
    isActive: false,

    spaceId: null,
    parentId: null,
    isSpaceAdmin: false,

    groups: null,
    isAddOpen: false,
    isEditOpen: false,
    isRemoveOpen: false,

    offset: null,
    limit: null,
  });

  const [tableStatus, setTableStatus] = React.useState(tableDataStates.INIT);

  useEffect(() => {
    if (
      !group.isActive ||
      group.groups ||
      group.parentId == null ||
      group.limit == null ||
      group.offset == null ||
      group.spaceId == null
    ) {
      return;
    }

    spaceManagement
      .getGroupContent(getUser, group.spaceId, group.parentId, group.offset, group.limit)
      .then(response => {
        const newGroup = { ...group, groups: response };
        setGroup(newGroup);
        setTableStatus(tableDataStates.SUCCESS);
      })
      .catch(err => {
        console.error(err);
        setTableStatus(tableDataStates.ERROR);
      });
  }, [group, getUser]);

  const updateGroupParams = (limit, offset) => {
    if (group.limit === limit && group.offset === offset) {
      return;
    }

    const newGroup = { ...group, limit, offset, groups: null };
    setGroup(newGroup);
  };

  const updateGroupsSpace = (spaceId, parentId, isAdmin) => {
    if (spaceId === group.spaceId && parentId === group.parentId && isAdmin === group.isSpaceAdmin) {
      return;
    }
    setGroup({ ...group, spaceId, parentId, isSpaceAdmin: isAdmin, groups: null });
  };

  const openAddDialog = () => setGroup({ ...group, isAddOpen: true });
  const handleAddClose = () => setGroup({ ...group, isAddOpen: false });
  const addGroup = (groupIdParam, groupName, clearSelection = () => {}) =>
    spaceManagement
      .addGroup(getUser, groupIdParam, groupName)
      .then(() => {
        setGroup({ ...group, isAddOpen: false, groups: null });
        clearSelection();
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 401:
            return { dialogError: intl.formatMessage({ id: 'addGroup401' }) };
          case 403:
            history.push('/login');
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'addGroup500' }) };
          default:
            if (err.response) {
              return err.response.data;
            }

            if (err.message) {
              console.log(err.message);
            }

            return;
        }
      });

  const openEditDialog = () => setGroup({ ...group, isEditOpen: true });
  const handleEditClose = () => setGroup({ ...group, isEditOpen: false });
  const editGroup = (groupId, newName, clearSelection = () => {}) =>
    spaceManagement
      .editGroup(getUser, groupId, newName)
      .then(() => {
        setGroup({ ...group, isEditOpen: false, groups: null });
        clearSelection();
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 401:
            return { dialogError: intl.formatMessage({ id: 'editGroup401' }) };
          case 403:
            history.push('/login');
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'editGroup500' }) };
          default:
            if (err.response) {
              return err.response.data;
            }

            if (err.message) {
              console.log(err.message);
            }

            return;
        }
      });

  const setGroupsActive = isActiveParam => {
    if (isActiveParam === group.isActive) {
      return;
    }
    setGroup({ ...group, isActive: isActiveParam });
  };

  const openRemoveDialog = () => setGroup({ ...group, isRemoveOpen: true });
  const handleRemoveClose = () => setGroup({ ...group, isRemoveOpen: false });
  const removeGroup = (groupIdParam, clearSelection = () => {}) =>
    spaceManagement
      .removeGroup(getUser, groupIdParam)
      .then(() => {
        setGroup({ ...group, isRemoveOpen: false, groups: null });
        clearSelection();
      })
      .catch(err => {
        switch (err.response?.status) {
          case 400:
            return { error400: err.response.data };
          case 401:
            return { dialogError: intl.formatMessage({ id: 'removeGroup401' }) };
          case 403:
            history.push('/login');
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'removeGroup500' }) };

          default:
            if (err.message) {
              console.log(err.message);
            }
            setGroup({ ...group, isRemoveOpen: false, groups: null });
            return;
        }
      });
  return (
    <GroupsContext.Provider
      value={{
        tableStatus,
        group,
        updateGroupParams,
        openAddDialog,
        handleAddClose,
        addGroup,
        setGroupsActive,
        openEditDialog,
        handleEditClose,
        editGroup,
        updateGroupsSpace,
        openRemoveDialog,
        handleRemoveClose,
        removeGroup,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
}

GroupsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
