import React from 'react';
import ErrorPageTemplate from './ErrorPageTemplate';
import { useIntl } from 'react-intl';
import { ServerError } from '../../components/ErrorSvgs';

const email = 'support@blueprism.com';
const subject = '500%20Error%20from%20the%20browser';

export default function ServerErrorPage() {
  const intl = useIntl();

  return (
    <ErrorPageTemplate
      headingText={intl.formatMessage({ id: '500header' })}
      errorMessageText={intl.formatMessage({ id: '500messageBody' })}
      linkText={intl.formatMessage({ id: '500linkText' })}
      linkHref={`mailto:${email}?subject=${subject}`}
      image={<ServerError />}
    />
  );
}
