import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Stack, Text, Box } from '@blueprism/ui-core';
import LoadingState from 'components/TableStates/LoadingState';

const Container = styled(Stack)`
  position: relative;
`;

const Content = styled(Stack)`
  border-radius: 0.5rem;
  background: white;
`;

const Overlay = styled(Stack)`
  position: absolute;
  margin: 0;
`;

const EmptyState = ({ lines = 6, text = '', title = '' }) => (
  <Container gap="base" data-testid="table empty">
    <LoadingState lines={lines} pauseAnimation={true} />
    <Overlay align="center" justify="center" width="100%" height="100%">
      <Content gap="medium" width="60%" padding="base">
        <Box>
          <Text type="h4">{title}</Text>
        </Box>
        <Box>
          <Text type="bodyL">{text}</Text>
        </Box>
      </Content>
    </Overlay>
  </Container>
);

export default EmptyState;

LoadingState.propTypes = {
  lines: PropTypes.number,
  text: PropTypes.string,
  title: PropTypes.string,
};
