import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import PATDialog from 'components/PATDialog';
import FormField from '@blueprism/ui-core/components/FormField';
import Text from '@blueprism/ui-core/components/Text';
import Switch from '@blueprism/ui-core/components/Switch';
import InputField from '@blueprism/ui-core/components/InputField';
import { AuthContext } from 'contexts/AuthContext';
import { SpaceContext } from 'contexts/SpaceContext';
import DialogTitle from 'components/DialogTitle';
import useDebounce from 'utils/useDebounce';
import { isValidEmail } from 'utils/helper';
import { user } from 'utils/api';

export default function SpaceUserAddDialog({ open = false, onClose, onConfirm }) {
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const { reloadSpace } = React.useContext(SpaceContext);
  const [search, setSearch] = React.useState('');
  const [results, setResults] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const debouncedSearchTerm = useDebounce(search, 500);

  React.useEffect(() => {
    setSearch('');
    setIsAdmin(false);
    setErrors({});
  }, [open]);

  React.useEffect(() => {
    if (debouncedSearchTerm && isValidEmail(debouncedSearchTerm)) {
      user.isEmailInPortal(getUser, debouncedSearchTerm).then(response => {
        setResults(response);
        setErrors({});
      });
    } else {
      setResults(false);
    }
  }, [debouncedSearchTerm, getUser]);

  const handleClose = () => onClose();
  const handleCloseClick = () => handleClose();

  const handleAddClick = async () => {
    const error = await onConfirm(debouncedSearchTerm, isAdmin);
    if (!!error === false) {
      reloadSpace();
    }
    setErrors(error ?? {});
  };

  const handleTextChange = event => setSearch(event.target.value);
  const handleCheckedChange = () => setIsAdmin(isAdminValue => !isAdminValue);

  const getErrorMessage = fieldName => errors[fieldName];
  const isInError = fieldName => errors[fieldName] != null && errors[fieldName].length > 0;

  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={handleCloseClick}>
          <FormattedMessage id="addSpaceUserClose" />
        </Button>
        <Button onClick={handleAddClick}>
          <FormattedMessage id="addSpaceUserOk" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      errorText={getErrorMessage('dialogError')}
      size="medium"
      overlay
      visible={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id="addSpaceUserHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <FormField
        label={intl.formatMessage({ id: 'addSpaceUserSubHeader' })}
        errorText={!results && getErrorMessage('email')}
        helperText={intl.formatMessage({ id: 'addSpaceUserHelperText' })}
        error={!results && isInError('email')}
      >
        <InputField id="user-email-input" value={search} onChange={handleTextChange} aria-labelledby="label" />
        <Row align="center" justify="between">
          <Text>
            <FormattedMessage id="addSpaceUserToggleText" />
          </Text>
          <Switch id="administrator-switch" checked={isAdmin} onChange={handleCheckedChange} value="checkedB" />
        </Row>
      </FormField>
    </PATDialog>
  );
}

SpaceUserAddDialog.props = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
