import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { QuestionMark } from '@blueprism/ui-icons';
import Slider from '@blueprism/ui-core/components/Slider';
import CheckBox from '@blueprism/ui-core/components/CheckBox';
import ToggleTip from 'components/core/ToggleTip';
import FormField from '@blueprism/ui-core/components/FormField';
import Row from '@blueprism/ui-core/layout/Row';
import Box from '@blueprism/ui-core/layout/Box';
import Text from '@blueprism/ui-core/components/Text';
import { globalSizes } from '@blueprism/ui-core/theme/units';

const SliderContainer = styled(Box)`
  flex-grow: 1;
`;

const SliderValueLabel = styled(Text)`
  width: 3rem;
  text-align: end;
  line-height: 2.5rem;
`;

export default function AssessmentSliderQuestion({
  value,
  helperText = '',
  questionText = '',
  errorText = '',
  isInError = false,
  validationLabel,
  fieldName = '',
  checkBoxLabel = '',
  defaultValue = 0,
  maxValue = 100,
  minValue = 0,
  step = 10,
  description = '',
  onChange = () => {},
}) {
  const [sliderValue, setSliderValue] = useState(value?.value ?? defaultValue.toString());
  const [checked, setChecked] = useState(value?.iDontKnow ?? false);
  const handleCheckboxChange = () => {
    const isChecked = !checked;
    setChecked(isChecked);
    setSliderValue(defaultValue.toString());
    onChange(fieldName, { value: isChecked ? '' : defaultValue.toString(), iDontKnow: isChecked });
  };

  const handleSliderChange = event => {
    setSliderValue(event.target.value);
    onChange(fieldName, { value: event.target.value, iDontKnow: false });
  };

  return (
    <FormField
      label={questionText}
      htmlFor={fieldName}
      id="discrete-slider-always"
      helperText={validationLabel}
      leadPara={description}
      errorText={errorText}
      error={isInError}
      aria-describedby={fieldName + '-helper'}
      standalone
      isFieldset
    >
      {helperText && <ToggleTip detailsText={helperText} icon={<QuestionMark />} />}
      <Row gap="small" height={globalSizes.large}>
        <SliderContainer padding="none">
          <Slider
            id={fieldName}
            onChange={handleSliderChange}
            value={sliderValue}
            max={maxValue}
            min={minValue}
            step={step}
            disabled={checked ? true : false}
            aria-labelledby="discrete-slider-always"
          />
        </SliderContainer>
        <SliderValueLabel padding="none"> {checked ? null : `${sliderValue}%`}</SliderValueLabel>
      </Row>
      <CheckBox id={`${fieldName}_dontKnow`} label={checkBoxLabel} checked={checked} onChange={handleCheckboxChange} />
    </FormField>
  );
}

AssessmentSliderQuestion.propTypes = {
  value: PropTypes.object,
  helperText: PropTypes.string,
  questionText: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  isInError: PropTypes.bool,
  fieldName: PropTypes.string,
  checkBoxLabel: PropTypes.string,
  defaultValue: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  step: PropTypes.number,
  description: PropTypes.string,
  onChange: PropTypes.func,
};
