import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Stack, Switch } from '@blueprism/ui-core';

const Container = styled(Stack)(
  props => css`
    display: ${props.enabled ? 'block' : 'none'};
  `
);

export default function Filter({ children, fieldName, enabled = false, title = '', onToggle = () => {} }) {
  function handleSwitchChange({ target }) {
    onToggle(fieldName, target.checked);
  }

  return (
    <Stack gap="base">
      <Switch
        displayType="justified"
        align="right"
        onChange={handleSwitchChange}
        label={title}
        id={`${fieldName}-switch`}
        checked={enabled}
      />
      <Container gap="medium" enabled={enabled} aria-hidden={!enabled} aria-expanded={enabled}>
        {children}
      </Container>
    </Stack>
  );
}

Filter.propTypes = {
  children: PropTypes.node.isRequired,
  fieldName: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  title: PropTypes.string,
  onToggle: PropTypes.func,
};
