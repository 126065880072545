import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { AuthContext } from 'contexts/AuthContext';
import { dataStates as tableDataStates } from '../utils/dataStates';
import { spaceManagement } from 'utils/api';

export const TeamsContext = createContext();

export default function TeamsContextProvider({ children }) {
  const history = useHistory();
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const [team, setTeam] = useState({
    isActive: false,
    spaceId: null,
    teams: null,
    isAddOpen: false,
    isEditOpen: false,
    isRemoveOpen: false,
    offset: null,
    limit: null,
  });

  const [tableStatus, setTableStatus] = React.useState(tableDataStates.INIT);

  useEffect(() => {
    if (team.isActive && !team.teams && team.spaceId && team.limit != null && team.offset != null) {
      spaceManagement
        .getSpaceTeams(getUser, team.spaceId, team.offset, team.limit)
        .then(response => {
          setTeam({ ...team, teams: response });
          setTableStatus(tableDataStates.SUCCESS);
        })
        .catch(err => {
          if (err.response?.status === 401) {
            history.replace(`/space/${team.spaceId}/groups`);
          } else {
            console.error(err);
            setTableStatus(tableDataStates.ERROR);
          }
        });
    }
  }, [history, team, getUser]);

  const updateTeamParams = (spaceId, limit, offset) => {
    if (team.spaceId === spaceId && team.limit === limit && team.offset === offset) {
      return;
    }
    setTeam({ ...team, spaceId, limit, offset, teams: null });
  };

  const openAddDialog = () => setTeam({ ...team, isAddOpen: true });
  const closeAddDialog = () => setTeam({ ...team, isAddOpen: false });

  const addTeam = (teamName, clearSelection = () => {}) =>
    spaceManagement
      .addTeam(getUser, team.spaceId, teamName)
      .then(() => {
        setTeam({ ...team, isAddOpen: false, teams: null });
        clearSelection();
      })
      .catch(err => {
        switch (err.response?.status) {
          case 401:
            return { dialogError: intl.formatMessage({ id: 'addTeam401' }) };
          case 403:
            history.push('/login');
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'addTeam500' }) };
          default:
            if (err.response?.data?.name) {
              return { name: err.response?.data?.name };
            }
            return;
        }
      });

  const editTeam = (teamName, teamId, clearSelection = () => {}) =>
    spaceManagement
      .editTeam(getUser, teamId, teamName)
      .then(() => {
        setTeam({ ...team, isEditOpen: false, teams: null });
        clearSelection();
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 401:
            return { dialogError: intl.formatMessage({ id: 'editTeam401' }) };
          case 403:
            history.push('/login');
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'editTeam500' }) };
          default:
            if (err.response?.data?.name) {
              return { name: err.response?.data?.name };
            }
            return;
        }
      });

  const removeTeam = (teamId, clearSelection = () => {}) =>
    spaceManagement
      .removeTeam(getUser, teamId)
      .then(() => {
        setTeam({ ...team, isRemoveOpen: false, teams: null });
        clearSelection();
      })
      .catch(err => {
        switch (err.response?.status) {
          case 401:
            return { dialogError: intl.formatMessage({ id: 'removeTeam401' }) };
          case 403:
            history.push('/login');
            return;
          case 500:
            return { dialogError: intl.formatMessage({ id: 'removeTeam500' }) };
          default:
            return;
        }
      });

  const openEditDialog = () => setTeam({ ...team, isEditOpen: true });
  const closeEditDialog = () => setTeam({ ...team, isEditOpen: false });
  const openRemoveDialog = () => setTeam({ ...team, isRemoveOpen: true });
  const closeRemoveDialog = () => setTeam({ ...team, isRemoveOpen: false });

  const setTeamsActive = isActiveParam => {
    if (isActiveParam === team.isActive) {
      return;
    }
    setTeam({ ...team, isActive: isActiveParam });
  };

  return (
    <TeamsContext.Provider
      value={{
        tableStatus,
        team,
        openAddDialog,
        closeAddDialog,
        addTeam,
        openEditDialog,
        closeEditDialog,
        editTeam,
        openRemoveDialog,
        closeRemoveDialog,
        removeTeam,
        updateTeamParams,
        setTeamsActive,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
}

TeamsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
