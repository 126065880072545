import * as React from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router';
import { useIntl } from 'react-intl';
import Button from '@blueprism/ui-core/components/Button';
import Text from '@blueprism/ui-core/components/Text';
import StatusIndicator from '@blueprism/ui-core/components/StatusIndicator';
import Stack from '@blueprism/ui-core/layout/Stack';
import Box from '@blueprism/ui-core/layout/Box';
import Row from '@blueprism/ui-core/layout/Row';
import Switch from '@blueprism/ui-core/components/Switch';
import { AuthContext } from 'contexts/AuthContext';
import AssessmentTextQuestion from 'components/AssessmentTextQuestion';
import AssessmentRadioQuestion from 'components/AssessmentRadioQuestion';
import AssessmentDataListQuestion from 'components/AssessmentDataListQuestion';
import AssessmentSliderQuestion from 'components/AssessmentSliderQuestion';
import AssessmentNumberQuestion from 'components/AssessmentNumberQuestion';
import AssessmentNumberOptionQuestion from 'components/AssessmentNumberOptionQuestion';
import AssessmentSelectQuestion from 'components/AssessmentSelectQuestion';
import AssessmentTagsQuestion from 'components/AssessmentTagsQuestion';
import AssessmentTimespanQuestion from 'components/AssessmentTimespanQuestion';
import AssessmentCurrencyQuestion from 'components/AssessmentCurrencyQuestion';
import AssessmentSummarySection from 'components/AssessmentSummarySection';
import AssessmentSummary from 'components/AssessmentSummary';
import { toUInt } from 'utils/helper';
import AssessmentGroupSelector from 'components/AssessmentGroupSelector';
import ErrorMessage from 'components/ErrorMessage';
import { assessments } from 'utils/api';

export const questionType = {
  TEXT: 0,
  ENUM: 1,
  NUMBER: 2,
  TAGS: 3,
  SELECT: 4,
  DATALIST: 5,
  SLIDER: 6,
  TIMESPAN: 7,
  NUMBEROPTION: 8,
  CURRENCY: 9,
  GROUPSELECT: 10,
};

const StackedContainer = styled(Stack)`
  width: 100%;
  min-width: min-content;
`;

const QuestionList = styled(Stack)`
  padding: 0;
  min-height: 560px;
  max-width: var(--measure);
`;

export default function ProcessAssessment(props) {
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const [errors, setErrors] = React.useState({});
  const [process, setProcess] = React.useState({
    isProcessDataSet: false,
    processData: {},
  });
  const [pageError, setPageError] = React.useState('');
  const [filterIncomplete, setFilterIncomplete] = React.useState(false);
  const [savePrompt, setSavePrompt] = React.useState({
    state: 'idle',
  });
  const [metaData, setMetaData] = React.useState({
    isMetaDataSet: false,
    data: {},
  });

  const params = props.match.params;

  React.useEffect(() => {
    const localProcessId = toUInt(params.processId);
    if (!process.isProcessDataSet && params.processId.toLowerCase() === 'new') {
      assessments
        .getNewAssessment(getUser)
        .then(response => {
          setProcess({
            isProcessDataSet: true,
            processData: response,
          });
        })
        .catch(err => {
          if (err.response.status === 403 || err.response.status === 401) {
            props.history.push('/login');
            return;
          } else if (err.response.status === 500) {
            props.history.push('/servererror');
            return;
          }
        });
    } else if (!process.isProcessDataSet || (localProcessId && process.processData.id !== localProcessId)) {
      assessments
        .getAssessment(getUser, localProcessId)
        .then(response => {
          setProcess({
            isProcessDataSet: true,
            processData: response,
          });
        })
        .catch(err => {
          switch (err.response.status) {
            case 401:
              props.history.push('/unauthorised');
              return;
            case 403:
              props.history.push('/login');
              return;
            case 500:
              props.history.push('/servererror');
              return;
            default:
              break;
          }
        });
    }
  }, [process, params, getUser, props.history]);

  React.useEffect(() => {
    if (!metaData.isMetaDataSet) {
      assessments
        .getAssessmentMetaData(getUser)
        .then(response => {
          setMetaData({ isMetaDataSet: true, data: response });
        })
        .catch(err => {
          if (err.response.status === 403 || err.response.status === 401) {
            props.history.push('/login');
            return;
          } else if (err.response.status === 500) {
            props.history.push('/servererror');
            return;
          }
        });
    }
  }, [metaData, getUser, props.history]);

  React.useLayoutEffect(() => {
    const mains = window.document.getElementsByTagName('main');

    if (mains?.length === 1) {
      const main = mains[0];
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
      if (supportsNativeSmoothScroll) {
        main.scrollTo(0, 0);
      } else {
        main.scrollTop = 0;
      }
    }
  }, [params.processPage]);

  const getCurrentPage = parameters =>
    parameters.processPage
      ? metaData.data.pages?.[parameters.processPage.toLowerCase()]
      : metaData.data.pages?.[Object.keys(metaData.data.pages)[0]];

  if (!metaData.isMetaDataSet || !process.isProcessDataSet) {
    return null;
  }

  const pageMetaData = getCurrentPage(params);
  if (!!pageMetaData === false) {
    props.history.push('/NotFound');
    return null;
  }

  const pageIndex = metaData.isMetaDataSet ? Object.keys(metaData.data.pages).indexOf(pageMetaData.pageName) : -1;

  if (process.processData.isReadonly && pageMetaData.pageName !== 'summary') {
    props.history.push(`/process-assessment/${params.processId}/summary`);
  }

  const navigateTo = increment => {
    const navigateToUrlForPageName = (pageName, processId) => {
      const path = `/process-assessment/:processId/${pageName}`;
      const finalPath = generatePath(path, { processId: processId });
      props.history.push(finalPath);
    };

    const newPageName = Object.keys(metaData.data.pages)[pageIndex + increment];

    if (pageMetaData.type === 1 || pageMetaData.page === 2) {
      setSavePrompt({ state: 'pending' });
      process.processData.submittedFromPage = pageMetaData.page;

      const dto = Object.assign({}, process.processData, { missingFields: [] });

      if (dto.id === 0) {
        assessments
          .addAssessment(getUser, dto.folderId, dto)
          .then(result => {
            setErrors({});
            setPageError('');
            if (result !== null) {
              setProcess(p => ({ ...p, processData: result }));
              navigateToUrlForPageName(newPageName, result.id || 'new');
              triggerSaveComplete();
            }
          })
          .catch(err => {
            setSavePrompt({ state: 'warning' });
            if (err.response) {
              if (err.response.status === 403) {
                props.history.push('/login');
                return;
              } else if (err.response.status === 401) {
                setPageError(intl.formatMessage({ id: '401fullPage' }));
                return;
              } else if (err.response.status === 500) {
                setPageError(intl.formatMessage({ id: '500fullPage' }));
                return;
              }
              setErrors(err.response.data);
              return;
            }

            if (err.message) {
              console.log(err.message);
            }
          });
      } else {
        assessments
          .editAssessment(getUser, dto)
          .then(result => {
            setErrors({});
            setPageError('');
            if (result !== null) {
              setProcess(p => ({ ...p, processData: result }));
              navigateToUrlForPageName(newPageName, result.id || 'new');
              triggerSaveComplete();
            }
          })
          .catch(err => {
            setSavePrompt({ state: 'warning' });

            if (err.response) {
              if (err.response.status === 403) {
                props.history.push('/login');
                return;
              } else if (err.response.status === 401) {
                setPageError(intl.formatMessage({ id: '401fullPage' }));
                return;
              } else if (err.response.status === 500) {
                setPageError(intl.formatMessage({ id: '500fullPage' }));
                return;
              } else if (err.response.status === 409) {
                setPageError(err.response.data);
                return;
              }

              setErrors(err.response.data);
              return;
            }

            if (err.message) {
              console.log(err.message);
            }
          });
      }
    } else {
      navigateToUrlForPageName(newPageName, params.processId);
    }
  };

  const getErrorMessage = fieldName => errors[fieldName];

  const isInError = fieldName => errors[fieldName] != null && errors[fieldName].length > 0;

  const handleChange = (key, value) => {
    setProcess(p => ({ ...p, processData: { ...p.processData, [key]: value } }));
  };

  const toggleIncomplete = event => setFilterIncomplete(event.target.checked);

  const handleSaveAndContinueClick = event => {
    event.preventDefault();
    navigateTo(1);
  };

  const handlePreviousClick = () => {
    navigateTo(-1);
  };

  const handleViewSummaryClick = () => {
    navigateTo(Object.keys(metaData.data.pages).length - pageIndex - 1);
  };

  const handleSaveAndExit = event => {
    event.preventDefault();
    props.history.push('/your-processes');
  };

  const handleSaveAndViewRecommendations = event => {
    event.preventDefault();
    props.history.push(`/process-assessment/${params.processId}/recommendation`);
  };

  function triggerSaveComplete() {
    setSavePrompt({ state: 'complete' });
    setTimeout(() => {
      setSavePrompt({ state: 'idle' });
    }, 1500);
  }

  return (
    <form noValidate>
      <StackedContainer gap="large">
        <Box element="header" padding="none">
          <Text type="h1">{metaData.data.assessmentTitle}</Text>
          {pageMetaData.title && <Text type="h2">{pageMetaData.title}</Text>}
        </Box>
        <Box width="var(--measure)">
          {pageMetaData.description && <Text type="body">{pageMetaData.description}</Text>}
        </Box>
        {pageError && <ErrorMessage label={pageError} />}
        {pageMetaData.type === 1 && (
          <QuestionList gap="medium">
            {pageMetaData.questions.map(x => {
              const question = metaData.data[x];

              switch (question.type) {
                case questionType.TEXT:
                  return (
                    <AssessmentTextQuestion
                      key={x}
                      metaData={metaData.data}
                      fieldName={question.fieldName}
                      question={question}
                      value={process.processData[question.fieldName] ?? ''}
                      validationLabel={question.description}
                      isInError={isInError(question.fieldName)}
                      errorText={getErrorMessage(question.fieldName)}
                      onChange={handleChange}
                    />
                  );

                case questionType.ENUM:
                  return (
                    <AssessmentRadioQuestion
                      key={x}
                      fieldName={question.fieldName}
                      label={!!question.helperText ? question.questionText : question.label}
                      toggleTipText={question.helperText}
                      helperText={!!question.helperText ? '' : question.description}
                      options={metaData.data[question.enum]}
                      value={process.processData[question.fieldName]?.toString()}
                      errorText={getErrorMessage(question.fieldName)}
                      isInError={isInError(question.fieldName)}
                      standalone={!!question.helperText}
                      onChange={handleChange}
                    />
                  );

                case questionType.NUMBER:
                  return (
                    <AssessmentNumberQuestion
                      key={x}
                      metaData={metaData.data}
                      fieldName={question.fieldName}
                      label={question.label}
                      questionText={question.questionText}
                      helperText={question.helperText}
                      value={process.processData[question.fieldName]}
                      minValue={question.minValue}
                      maxValue={question.maxValue}
                      step={question.step}
                      handleChange={handleChange}
                      validationLabel={question.description}
                      isInError={isInError(question.fieldName)}
                      errorText={getErrorMessage(question.fieldName)}
                    />
                  );

                case questionType.TAGS:
                  return (
                    <AssessmentTagsQuestion
                      key={x}
                      metaData={metaData.data}
                      question={question}
                      value={process.processData[question.fieldName]}
                      handleChange={handleChange}
                      validationLabel={question.description}
                      isInError={isInError(question.fieldName)}
                      errorText={getErrorMessage(question.fieldName)}
                    />
                  );

                case questionType.SELECT:
                  return (
                    <AssessmentSelectQuestion
                      key={x}
                      metaData={metaData.data}
                      question={question}
                      value={process.processData[question.fieldName]}
                      handleChange={handleChange}
                      validationLabel={question.description}
                      isInError={isInError(question.fieldName)}
                      errorText={getErrorMessage(question.fieldName)}
                    />
                  );

                case questionType.DATALIST:
                  return (
                    <AssessmentDataListQuestion
                      key={x}
                      metaData={metaData.data}
                      question={question}
                      value={process.processData[question.fieldName]}
                      handleChange={handleChange}
                      validationLabel={question.description}
                      isInError={isInError(question.fieldName)}
                      errorText={getErrorMessage(question.fieldName)}
                    />
                  );

                case questionType.SLIDER:
                  return (
                    <AssessmentSliderQuestion
                      key={x}
                      questionText={question.questionText}
                      value={process.processData[question.fieldName]}
                      onChange={handleChange}
                      validationLabel={question.description}
                      isInError={isInError(question.fieldName)}
                      fieldName={question.fieldName}
                      helperText={question.helperText}
                      checkBoxLabel={metaData.data.iDontKnow}
                      defaultValue={question.defaultValue}
                      maxValue={question.maxValue}
                      minValue={question.minValue}
                      step={question.step}
                      errorText={getErrorMessage(question.fieldName)}
                    />
                  );

                case questionType.TIMESPAN:
                  return (
                    <AssessmentTimespanQuestion
                      key={x}
                      metaData={metaData.data}
                      fieldName={question.fieldName}
                      label={question.label}
                      helperText={question.helperText}
                      value={process.processData[question.fieldName]}
                      onChange={handleChange}
                      validationLabel={question.description}
                      isInError={
                        isInError(`${question.fieldName}.Hours`) ||
                        isInError(`${question.fieldName}.Minutes`) ||
                        isInError(`${question.fieldName}.Seconds`) ||
                        isInError(question.fieldName)
                      }
                      errorHours={isInError(`${question.fieldName}.Hours`) || isInError(question.fieldName)}
                      errorMinutes={isInError(`${question.fieldName}.Minutes`) || isInError(question.fieldName)}
                      errorSeconds={isInError(`${question.fieldName}.Seconds`) || isInError(question.fieldName)}
                      errorText={[]
                        .concat(getErrorMessage(`${question.fieldName}.Hours`))
                        .concat(getErrorMessage(`${question.fieldName}.Minutes`))
                        .concat(getErrorMessage(`${question.fieldName}.Seconds`))
                        .concat(getErrorMessage(question.fieldName))}
                    />
                  );

                case questionType.NUMBEROPTION:
                  return (
                    <AssessmentNumberOptionQuestion
                      key={x}
                      metaData={metaData.data}
                      questionText={question.questionText}
                      iDontKnow={metaData.data.iDontKnow}
                      fieldName={question.fieldName}
                      label={question.label}
                      helperText={question.helperText}
                      value={process.processData[question.fieldName]}
                      minValue={question.minValue}
                      maxValue={question.maxValue}
                      step={question.step}
                      handleChange={handleChange}
                      validationLabel={question.description}
                      isInError={isInError(question.fieldName)}
                      errorText={getErrorMessage(question.fieldName)}
                    />
                  );

                case questionType.CURRENCY:
                  return (
                    <AssessmentCurrencyQuestion
                      key={x}
                      metaData={metaData.data}
                      fieldName={question.fieldName}
                      question={question}
                      value={process.processData[question.fieldName]}
                      handleChange={handleChange}
                      validationLabel={question.description}
                      amountIsInError={isInError(`${question.fieldName}.Amount`)}
                      currencyIsInError={isInError(`${question.fieldName}.CurrencyCode`)}
                      amountErrorText={getErrorMessage(`${question.fieldName}.Amount`)}
                      currencyErrorText={getErrorMessage(`${question.fieldName}.CurrencyCode`)}
                    />
                  );

                case questionType.GROUPSELECT:
                  return (
                    <AssessmentGroupSelector
                      key={x}
                      hasAssessmentBeenSaved={process.processData.id > 0}
                      label={question.label}
                      groupIdFieldName={question.fieldName}
                      groupNameFieldName={question.fieldName2}
                      helperText={question.helperText}
                      value={process.processData[question.fieldName]}
                      groupName={process.processData[question.fieldName2]}
                      onChange={handleChange}
                      validationLabel={question.description}
                      selectGroup={question.selectGroup}
                      changeGroup={question.changeGroup}
                      isInError={isInError(question.fieldName)}
                      errorText={getErrorMessage(question.fieldName)}
                    />
                  );

                default:
                  return <div key={x}>Type {x} Not implemented</div>;
              }
            })}
          </QuestionList>
        )}
        <Stack gap="medium" width="65%">
          {pageMetaData.type === 2 && (
            <AssessmentSummary id={params.processId}>
              <AssessmentSummarySection
                currentPageType={pageMetaData.type}
                pageMetaData={pageMetaData}
                filterIncomplete={false}
                processId={params.processId}
                processData={process.processData}
                questions={pageMetaData.questions}
                metaData={metaData}
                {...props}
              />
            </AssessmentSummary>
          )}
          {pageMetaData.type === 4 && (
            <>
              <Switch
                id="summaryFilter"
                name="summaryFilter"
                checked={filterIncomplete}
                onChange={toggleIncomplete}
                label={intl.formatMessage({ id: 'filterIncompleteFields' })}
              />
              <AssessmentSummary id={params.processId}>
                {Object.values(metaData.data.pages)
                  .filter(page => page.type === 2)
                  .map(page => {
                    return (
                      <AssessmentSummarySection
                        key={page.pageName}
                        currentPageType={pageMetaData.type}
                        pageMetaData={page}
                        filterIncomplete={filterIncomplete}
                        processId={params.processId}
                        processData={process.processData}
                        header={page.title}
                        questions={page.questions}
                        metaData={metaData}
                        {...props}
                      />
                    );
                  })}
              </AssessmentSummary>
            </>
          )}
        </Stack>
        <Row width="100%" justify="between">
          <Box padding="none">
            {pageMetaData.type < 3 && params.processId.toLowerCase() !== 'new' && (
              <Button id="viewSummary" onClick={handleViewSummaryClick}>
                {intl.formatMessage({ id: 'viewSummary' })}
              </Button>
            )}
          </Box>
          <Row gap="small">
            {pageIndex > 0 && (
              <Button id={`previous${pageMetaData.type}`} name="previous" onClick={handlePreviousClick}>
                {intl.formatMessage({ id: 'previous' })}
              </Button>
            )}

            {pageIndex === 0 && (
              <Button id="cancel" onClick={() => props.history.push('/process-assessment')}>
                {intl.formatMessage({ id: 'cancel' })}
              </Button>
            )}

            {pageMetaData.type < 4 && (
              <Button
                id={`saveAndContinue${pageMetaData.type}`}
                name="saveAndContinue"
                type="submit"
                onClick={handleSaveAndContinueClick}
              >
                {intl.formatMessage({ id: pageMetaData.type === 1 ? 'saveAndContinue' : 'continue' })}
              </Button>
            )}

            {pageMetaData.type === 4 && process.processData.mandatoryFieldsComplete && (
              <Button id="saveAndViewRecommendation" type="submit" onClick={handleSaveAndViewRecommendations}>
                {intl.formatMessage({ id: 'saveAndViewRecommendation' })}
              </Button>
            )}

            {pageMetaData.type === 4 && !process.processData.mandatoryFieldsComplete && (
              <Button id="saveAndExit" type="submit" onClick={handleSaveAndExit}>
                {intl.formatMessage({ id: 'saveAndExit' })}
              </Button>
            )}
          </Row>
        </Row>
        <Row width="100%" justify="end" height="2rem" style={{ margin: '5px' }}>
          <StatusIndicator
            id="saveStatusIndicator"
            statusLabels={[
              intl.formatMessage({ id: 'saving' }),
              intl.formatMessage({ id: 'saved' }),
              intl.formatMessage({ id: 'failed' }),
            ]}
            status={savePrompt.state}
          />
        </Row>
      </StackedContainer>
    </form>
  );
}
