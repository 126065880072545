import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import Button from '@blueprism/ui-core/components/Button';
import PATDialog from 'components/PATDialog';
import DialogTitle from 'components/DialogTitle';
import ErrorMessage from 'components/ErrorMessage';
import User from 'components/User';
import { config } from 'utils/config';

export default function TeamUserRemoveDialog({ open, user, onConfirm, onClose }) {
  const intl = useIntl();
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => setErrors({}), [open]);

  const name = user ? user.name : null;
  const avatar = user ? user.avatar : null;
  const email = user ? user.email : null;

  const handleClose = () => onClose();
  const handleCloseClick = () => onClose();
  const handleAdd = async () => {
    const result = await onConfirm(user.userId, onClose);
    setErrors(result || {});
  };

  const getErrorMessage = fieldName => errors[fieldName];

  function renderFooter() {
    return (
      <Row justify="between">
        <Button id="cancelButton" onClick={handleCloseClick}>
          <FormattedMessage id="removeTeamUserClose" />
        </Button>
        <Button id="okButton" onClick={handleAdd}>
          <FormattedMessage id="removeTeamUserOK" />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="small"
      overlay
      visible={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id="removeTeamUserHeader" />
        </DialogTitle>
      }
      footerContent={renderFooter()}
      errorText={getErrorMessage('api')}
    >
      <User avatarImage={avatar && config.imageUrl(avatar)} userName={`${name} ${email && `(${email})`}`} />
      <ErrorMessage id="remove-space-user-details" label={intl.formatMessage({ id: 'removeTeamUserToggleText' })} />
    </PATDialog>
  );
}

TeamUserRemoveDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};
