import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Stack, Text, Center } from '@blueprism/ui-core';
import { Error400 } from 'images/Error400';
import { ErrorTable } from 'images/ErrorTable';
import LoadingState from 'components/TableStates/LoadingState';

const Container = styled(Stack)`
  position: relative;
`;

const Content = styled(Stack)`
  border-radius: 0.5rem;
  background: white;
`;

const Overlay = styled(Stack)`
  position: absolute;
`;

const StyledText = styled(Text)`
  font-weight: 900;
  margin-top: 1.5rem;
`;

const ErrorState = ({ lines = 6, text = '', errorCode }) => {
  let ErrorPic;
  switch (errorCode) {
    case '400':
      ErrorPic = () => <Error400 width="100%" />;
      break;
    case '500':
      ErrorPic = () => <ErrorTable width="100%" />;
      break;
    default:
      ErrorPic = () => <ErrorTable width="100%" />;
  }
  return (
    <Container gap="base" data-testid="table error">
      <LoadingState lines={lines} pauseAnimation={true} />
      <Overlay align="center" justify="center" width="100%" height="100%">
        <Content gap="medium" width="60%" padding="base">
          <Center intrinsic andText>
            <ErrorPic />
            <StyledText type="bodyL">{text}</StyledText>
          </Center>
        </Content>
      </Overlay>
    </Container>
  );
};

export default ErrorState;

LoadingState.propTypes = {
  lines: PropTypes.number,
  text: PropTypes.string,
};
