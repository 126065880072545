import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Stack, Box, Row, ToolbarButton, Text, Cluster, Button } from '@blueprism/ui-core';
import { Cross, List as ListIcon } from '@blueprism/ui-icons';
import GaugeChart from '@blueprism/ui-charts/components/GaugeChart';
import { YourProcessesContext } from 'contexts/YourProcessesContextProvider';
import RecommendationDescriptionDialog from './RecommendationDescriptionDialog';
import { getIcon } from './Banner';
import { typography } from '@blueprism/ui-core/theme/units';

const Content = styled(Stack)`
  min-width: 90%;
`;

const StyledDescriptionDetail = styled.dd(
  props =>
    `
  font-size: ${props.headline ? typography.mega.size : typography.bodyL.size}rem;
  font-weight: 900;
  `
);

export default function YourProcessesDetails({ assessmentId, onShowDetailsChanged }) {
  const intl = useIntl();
  const history = useHistory();
  const { state } = React.useContext(YourProcessesContext);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const assessment = state.assessments.items.find(process => process.id.toString() === assessmentId);

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  function handleDialogOpen() {
    setIsDialogOpen(true);
  }

  function handleCloseDetails() {
    onShowDetailsChanged(false);
  }

  function handleViewSummaryClick() {
    history.push(`/process-assessment/${assessment.id}/summary`);
  }

  const details = {
    hoursSavings: assessment.potentialSavingHours,
    capitalSavings: assessment.currency
      ? intl.formatNumber(assessment.potentialSavingCost, {
          style: 'currency',
          currency: assessment.currency,
        })
      : assessment.potentialSavingCost,
    analyst: assessment.processAnalyst,
    owner: assessment.processOwner,
    createdDate: intl.formatDate(assessment.createdAt),
    status: assessment.status,
    space: assessment.space,
    group: assessment.folderName,
    processId: assessment.processId,
    type: assessment.processType,
    businessFunction: assessment.businessFunction,
    reasonForAutomating: assessment.reasonForAutomating,
    tags: assessment.tags,
  };

  const getDetails = () => {
    const filteredDetails = Object.entries(details).filter(([, value]) => value !== null && value !== '');
    return filteredDetails.map((detail, index) => (
      <Box width="35ch" key={index}>
        <dt>
          <FormattedMessage id={`yourProcesses_table_${detail[0]}Column`} />
        </dt>
        <StyledDescriptionDetail headline={detail[0] === 'hoursSavings' || detail[0] === 'capitalSavings'}>
          {detail[1]}
        </StyledDescriptionDetail>
      </Box>
    ));
  };

  const showIncomplete = () => {
    return (
      <Text type="body">
        <FormattedMessage id="yourProcesses_details_incomplete" />
      </Text>
    );
  };

  return (
    <>
      <Stack width="100%">
        <Row align="start" justify="between">
          <Box>
            <Text>
              <FormattedMessage id="yourProcesses_table_titleColumn" />
            </Text>
            <Text type="h1">{assessment.processTitle}</Text>
            <Button iconLeft={<ListIcon />} onClick={handleViewSummaryClick}>
              <FormattedMessage id="yourProcesses_button_viewSummary" />
            </Button>
          </Box>
          <ToolbarButton
            onClick={handleCloseDetails}
            label={intl.formatMessage({ id: 'yourProcesses_button_closeDetails' })}
            icon={<Cross />}
          />
        </Row>

        <Content justify="between" align="start">
          <Cluster gap="xs" justify="center" padding="small" width="auto">
            <Box padding="small">
              <GaugeChart
                align="right"
                title={intl.formatMessage({ id: 'recommnedationAumationChart' })}
                min={0}
                max={6}
                value={assessment.recommendation.apScore ?? 0}
              />
            </Box>
            <Box padding="small">
              <GaugeChart
                title={intl.formatMessage({ id: 'recommnedationEaseChart' })}
                min={0}
                max={6}
                value={assessment.recommendation.ieScore ?? 0}
                pattern="navy"
              />
            </Box>
          </Cluster>
          <dl>
            <Cluster gap="large" padding="large">
              <Box width="35ch">
                <Stack gap="xs">
                  <dt>
                    <FormattedMessage id="yourProcesses_table_recommendationColumn" />
                  </dt>
                  <Row width="100%" align="center">
                    <Box padding="small">{getIcon(assessment.recommendation.recommendation)}</Box>
                    <StyledDescriptionDetail headline>{assessment.recommendation.title}</StyledDescriptionDetail>
                  </Row>
                </Stack>
              </Box>
              <Box width="35ch">
                <Button onClick={handleDialogOpen}>
                  <FormattedMessage id="yourProcess_details_whatDoesThisMean" />
                </Button>
              </Box>
              {assessment.recommendation.recommendation !== 0 ? getDetails() : showIncomplete()}
            </Cluster>
          </dl>
        </Content>
      </Stack>
      <RecommendationDescriptionDialog assessmentId={assessmentId} onClose={handleDialogClose} open={isDialogOpen} />
    </>
  );
}

YourProcessesDetails.propTypes = {
  assessmentId: PropTypes.string,
  onShowDetailsChanged: PropTypes.func,
};
