import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Person from '@blueprism/ui-icons/Person';
import Stack from '@blueprism/ui-core/layout/Stack';
import Breadcrumbs from '@blueprism/ui-core/components/Breadcrumbs';
import Text from '@blueprism/ui-core/components/Text';
import Toolbar from '@blueprism/ui-core/components/Toolbar';
import ButtonTabGroup from '@blueprism/ui-core/components/ButtonTabGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import { AuthContext } from 'contexts/AuthContext';
import Link from 'components/Link';
import TeamUsers from './TeamUsers';
import { toUInt } from 'utils/helper';
import TeamUserButtons from './TeamUserButtons';
import useTableRowSelection from 'utils/useTableRowSelection';
import { spaceManagement } from 'utils/api';

const tabs = ['users', 'teams'];

export default function Team() {
  const params = useParams();
  const history = useHistory();
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const { clearSelection, selectedRowIds, onRowSelectionChange } = useTableRowSelection([], true);

  const [team, setTeam] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [requiresUsersRefresh, setRequiresUsersRefresh] = React.useState(true);

  const teamId = toUInt(params.teamId);
  const tab = (params.tab || 'users').toLowerCase();

  const offset = toUInt(params.offset) || 0;
  const limit = toUInt(params.limit) || 25;

  React.useEffect(() => {
    if (teamId === team?.teamId) {
      return;
    }

    spaceManagement
      .getTeam(getUser, teamId)
      .then(response => setTeam(response))
      .catch(err => {
        switch (err.response?.status) {
          case 500:
            history.push('/servererror');
            return;
          case 403:
            history.push('/login');
            return;
          case 401:
            history.push('/unauthorised');
            return;
          default:
            console.log(err);
        }
      });
  }, [team, teamId, getUser, history]);

  React.useEffect(() => {
    if (requiresUsersRefresh) {
      setRequiresUsersRefresh(false);
      spaceManagement
        .getTeamMembers(getUser, teamId, offset, limit)
        .then(response => {
          setCount(response.count);
          setUsers(response.items);
        })
        .catch(err => {
          switch (err.response?.status) {
            case 500:
              history.push('/servererror');
              return;
            case 403:
              history.push('/login');
              return;
            case 401:
              history.push('/unauthorised');
              return;
            default:
              console.log(err);
          }
        });
    }
  }, [requiresUsersRefresh, teamId, offset, limit, getUser, history]);

  if (!team) {
    return <FormattedMessage id="fetching" />;
  }

  function handleChange(newValue) {
    const fragment = tabs[newValue.currentTarget.value];
    history.push(`/your-spaces/team/${team.teamId}/${fragment}`);
  }

  function handleUserRemoved(userId) {
    clearSelection();
    setRequiresUsersRefresh(true);
  }

  function handleUserAdded() {
    setRequiresUsersRefresh(true);
  }

  function handlePaginationChanged() {
    setRequiresUsersRefresh(true);
  }

  const canEditSelection =
    selectedRowIds.length === 1 && (users?.some(user => user.userId.toString() === selectedRowIds[0]) ?? false);
  const currentUser =
    selectedRowIds.length === 1 && users ? users.find(user => user.userId.toString() === selectedRowIds[0]) : {};

  return (
    <Stack gap="base">
      <Breadcrumbs id="teamBreadcrumb">
        <Link href="/your-spaces">
          <FormattedMessage id="spaces" />
        </Link>
        <Link href={'/your-spaces/space/' + team.spaceId}>{team.spaceName}</Link>
        <Link href={`/your-spaces/space/${team.spaceId}/teams`}>
          <FormattedMessage id="teams" />
        </Link>
        <Text>{team.name}</Text>
      </Breadcrumbs>

      <Text type="h1" id="teamNameText">
        {team.name}
      </Text>

      <Toolbar>
        <ButtonTabGroup selectedIndex={tabs.indexOf(tab)} onChange={handleChange}>
          <ToolbarButton label={intl.formatMessage({ id: 'users' })} icon={<Person />} />
        </ButtonTabGroup>
        <TeamUserButtons
          team={team}
          canEditSelection={canEditSelection}
          currentUser={currentUser}
          onUserAdded={handleUserAdded}
          onUserRemoved={handleUserRemoved}
        />
      </Toolbar>
      <TeamUsers
        teamId={team.teamId}
        users={users}
        count={count}
        onSelectionChanged={onRowSelectionChange}
        onPaginationChanged={handlePaginationChanged}
        selectedRowIds={selectedRowIds}
        offset={offset}
        limit={limit}
      />
    </Stack>
  );
}
