import * as React from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import { PersonPlus, PersonRemove, KeyWithDots } from '@blueprism/ui-icons';
import SpaceUserAddDialog from 'components/SpaceUserAddDialog';
import SpaceUserEditDialog from 'components/SpaceUserEditDialog';
import SpaceUserRemoveDialog from 'components/SpaceUserRemoveDialog';
import AlertDialog from 'components/AlertDialog';
import { UsersContext } from 'contexts/UsersContext';

export default function UsersNavButtons({ selectedRowIds = [], clearSelection = () => {} }) {
  const intl = useIntl();
  const {
    userContext,
    openAddDialog,
    openEditDialog,
    openRemoveDialog,
    addSpaceUser,
    handleAddClose,
    removeSpaceUser,
    handleRemoveClose,
    onAlertOk,
  } = React.useContext(UsersContext);

  const selectedUser = {
    member:
      (selectedRowIds.length === 1
        ? userContext.users?.items.find(user => user.memberId.toString() === selectedRowIds[0])
        : {}) ?? {},
    canEdit:
      selectedRowIds.length === 1 &&
      (userContext.users?.items.some(user => user.memberId.toString() === selectedRowIds[0]) ?? false),
  };

  function handleOpenRemoveDialog() {
    openRemoveDialog(selectedUser.member);
  }

  function handleOpenAddialog() {
    openAddDialog(selectedUser.member);
  }

  function handleRemoveSpaceUser(userId) {
    return removeSpaceUser(userId, clearSelection);
  }

  function handleAddSpaceUser(email, isAdmin) {
    return addSpaceUser(email, isAdmin, clearSelection);
  }

  return (
    <>
      <ButtonGroup>
        <ToolbarButton
          id="spaceUserAdd"
          onClick={handleOpenAddialog}
          label={intl.formatMessage({ id: 'spaceMembersAdd' })}
          icon={<PersonPlus />}
        />
        <ToolbarButton
          id="spaceUserRemove"
          onClick={handleOpenRemoveDialog}
          disabled={!selectedUser.canEdit}
          label={intl.formatMessage({ id: 'spaceMembersRemove' })}
          icon={<PersonRemove />}
        />
        <ToolbarButton
          id="spaceUserEdit"
          onClick={openEditDialog}
          disabled={!selectedUser.canEdit}
          label={intl.formatMessage({ id: 'spaceMembersEdit' })}
          icon={<KeyWithDots />}
        />
      </ButtonGroup>
      <SpaceUserAddDialog open={userContext.isAddOpen} onConfirm={handleAddSpaceUser} onClose={handleAddClose} />
      <SpaceUserEditDialog clearSelection={clearSelection} selectedUser={selectedUser} />
      <SpaceUserRemoveDialog
        user={selectedUser.member}
        open={userContext.isRemoveOpen}
        onConfirm={handleRemoveSpaceUser}
        onClose={handleRemoveClose}
      />
      <AlertDialog
        open={userContext.isAlertOpen}
        title={userContext.alertText}
        text={userContext.alertText}
        onOk={onAlertOk}
      />
    </>
  );
}
