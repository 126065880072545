import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { AuthContext } from 'contexts/AuthContext';
import useDebounce from 'utils/useDebounce';
import { spaceManagement } from 'utils/api';

export const GroupMembersSearchContext = React.createContext();

export default function GroupMembersSearchContextProvider({ children }) {
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);
  const [search, setSearch] = React.useState({
    searchTerm: '',
    lastExecutedSearchTerm: null,
    results: [],
    isSearching: false,
    spaceId: null,
  });

  const [errorText, setErrorText] = React.useState('');
  const debouncedSearchTerm = useDebounce(search.searchTerm, 500);

  React.useEffect(() => {
    if (debouncedSearchTerm && search.spaceId && search.lastExecutedSearchTerm !== debouncedSearchTerm) {
      setSearch(p => ({ ...p, isSearching: true, lastExecutedSearchTerm: debouncedSearchTerm }));

      spaceManagement
        .spaceMemberSearch(getUser, search.spaceId, debouncedSearchTerm)
        .then(response => {
          setSearch(p => ({ ...p, isSearching: false, results: response }));
        })
        .catch(err => {
          switch (err?.response?.status) {
            case 400:
            case 500:
              setErrorText(intl.formatMessage({ id: 'userSearch400and500Error' }));
              break;
            case 401:
              setErrorText(intl.formatMessage({ id: 'userSearch401Error' }));
              break;
            default:
              break;
          }
        });
    }
  }, [debouncedSearchTerm, search.lastExecutedSearchTerm, search.spaceId, getUser, intl]);

  const searchForGroupMember = (spaceId, name) => {
    setErrorText('');
    setSearch({ ...search, searchTerm: name, spaceId: spaceId });
  };

  const resetSearch = () => {
    setSearch({
      searchTerm: '',
      lastExecutedSearchTerm: null,
      results: [],
      isSearching: false,
      spaceId: null,
    });
    setErrorText('');
  };

  return (
    <GroupMembersSearchContext.Provider
      value={{
        results: search.results,
        lastExecutedSearchTerm: search.lastExecutedSearchTerm,
        isSearching: search.isSearching,
        searchForGroupMember,
        resetSearch,
        errorText,
      }}
    >
      {children}
    </GroupMembersSearchContext.Provider>
  );
}

GroupMembersSearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
