import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import PATDialog from 'components/PATDialog';
import Button from '@blueprism/ui-core/components/Button';
import InputField from '@blueprism/ui-core/components/InputField';
import FormField from '@blueprism/ui-core/components/FormField';
import DialogTitle from 'components/DialogTitle';

export default function GroupNameDialog({ parentGroupId, group, mode = 'add', open = false, onClose, onConfirm }) {
  const intl = useIntl();
  const [text, setText] = React.useState('');
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (open) {
      setText(group?.name ?? '');
      setErrors({});
    }
  }, [group, open]);

  function handleCancelClick() {
    onClose();
  }

  async function handleOkClick() {
    const groupParam = mode === 'add' ? parentGroupId : group.id;
    const results = await onConfirm(groupParam, text);
    setErrors(results ?? {});
  }

  function handleTextChange(event) {
    setText(event.target.value);
  }

  const getErrorMessage = fieldName => errors[fieldName];
  const isInError = fieldName => errors[fieldName] != null && errors[fieldName].length > 0;

  function renderFooter() {
    return (
      <Row justify="between">
        <Button onClick={handleCancelClick}>
          <FormattedMessage id={mode + 'GroupCancel'} />
        </Button>
        <Button onClick={handleOkClick}>
          <FormattedMessage id={mode + 'GroupButtonOK'} />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      errorText={getErrorMessage('dialogError')}
      size="small"
      overlay
      visible={open}
      onClose={onClose}
      aria-labelledby="group-name-dialog"
      headerContent={
        <DialogTitle id="group-name-dialog">
          <FormattedMessage id={mode + 'GroupTitle'} />
        </DialogTitle>
      }
      footerContent={renderFooter()}
    >
      <FormField
        errorText={getErrorMessage('name')}
        label={intl.formatMessage({ id: mode + 'GroupHeading' })}
        helperText={intl.formatMessage({ id: mode + 'GroupDetails' })}
        error={isInError('name')}
      >
        <InputField type="text" id={mode + 'group-name-textbox'} value={text} onChange={handleTextChange} />
      </FormField>
    </PATDialog>
  );
}

GroupNameDialog.propTypes = {
  group: PropTypes.object,
  parentGroupId: PropTypes.number,
  mode: PropTypes.oneOf(['add', 'edit']),
  open: PropTypes.bool,
  spaceId: PropTypes.number,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
