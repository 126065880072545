import * as React from 'react';
import PropTypes from 'prop-types';
import DialogModal from '@blueprism/ui-core/components/DialogModal';
import ErrorMessage from 'components/ErrorMessage';

function getModalHeight(size) {
  switch (size) {
    case 'small':
      return '20rem';
    case 'medium':
      return '25rem';
    case 'large':
      return '32rem';
    default:
      return '20rem';
  }
}

export default function PATDialog({ errorText, size, children, ...rest }) {
  return (
    <DialogModal width="30rem" height={getModalHeight(size)} {...rest}>
      {errorText && <ErrorMessage label={errorText} />}
      {children}
    </DialogModal>
  );
}

PATDialog.propTypes = {
  /** contents of the Dialog */
  children: PropTypes.node,
  /** Size of the Dialog (Small/Medium/Large) -
   * just affects the height of the dialog - small = 20rem, medium = 25rem, large = 32rem
   * */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  errorText: PropTypes.string,
};
