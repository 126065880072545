import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PagingToolbar from '@blueprism/ui-core/components/PagingToolbar';

export default function TablePagination({ itemName, count, offset, limit, onLimitChange, onNextData, onPreviousData }) {
  const intl = useIntl();
  const cantGoBack = offset <= 0;
  const cantGoForward = offset + limit >= count;

  function handleChange(event) {
    onLimitChange(Number(event.target.value));
  }

  return (
    <PagingToolbar
      rowCountLabel={intl.formatMessage({ id: `paginationItemsPerPage${itemName}` })}
      pageLabel={intl.formatMessage(
        { id: `paginationShowingRangeOfItems${itemName}` },
        { rangeBottom: count ? offset + 1 : 0, rangeTop: Math.min(offset + limit, count), max: count }
      )}
      pageValue={limit}
      prevButtonLabel={intl.formatMessage({ id: 'paginationPrev' })}
      nextButtonLabel={intl.formatMessage({ id: 'paginationNext' })}
      prevButtonDisabled={cantGoBack}
      nextButtonDisabled={cantGoForward}
      onPageValueChange={handleChange}
      onPrevButtonClick={onPreviousData}
      onNextButtonClick={onNextData}
    />
  );
}

TablePagination.propTypes = {
  itemName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  limitLabelId: PropTypes.string.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onNextData: PropTypes.func.isRequired,
  onPreviousData: PropTypes.func.isRequired,
};
