import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ThemeProvider from '@blueprism/ui-core/theme/ThemeProvider';
import PageLayout from '@blueprism/ui-core/templates/PageLayout';
import AuthContextProvider from 'contexts/AuthContext';
import UserContextProvider from 'contexts/UserContext';
import LanguageProvider from 'components/LanguageProvider';
import AuthenticationGuard from 'components/AuthenticationGuard';
import RegistrationGuard from 'components/RegistrationGuard';
import Sidebar from 'components/Sidebar';
import SilentRenew from 'pages/SilentRenew';
import Routes from 'pages/Routes';

const App = () => (
  <ThemeProvider theme="light">
    <LanguageProvider>
      <Router>
        <AuthContextProvider>
          <Route path="/silent_renew" exact component={SilentRenew} />
          <UserContextProvider>
            <AuthenticationGuard>
              <RegistrationGuard>
                <PageLayout sideContent={<Sidebar />} mainContent={<Routes />} />
              </RegistrationGuard>
            </AuthenticationGuard>
          </UserContextProvider>
        </AuthContextProvider>
      </Router>
    </LanguageProvider>
  </ThemeProvider>
);

export default App;
