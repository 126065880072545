import React from 'react';
import GroupsContextProvider from 'contexts/GroupsContext';
import UsersContextProvider from 'contexts/UsersContext';
import TeamsContextProvider from 'contexts/TeamsContext';
import SpaceContextProvider from 'contexts/SpaceContext';
import Space from 'pages/Space';

const SpaceWrapper = props => (
  <SpaceContextProvider>
    <GroupsContextProvider>
      <TeamsContextProvider>
        <UsersContextProvider>
          <Space {...props} />
        </UsersContextProvider>
      </TeamsContextProvider>
    </GroupsContextProvider>
  </SpaceContextProvider>
);

export default SpaceWrapper;
