import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Box from '@blueprism/ui-core/layout/Box';
import { remValue, hexToRGBA } from '@blueprism/ui-core/utils/utils';
import { globalSizes } from '@blueprism/ui-core/theme/units';

const FooterBox = styled(Box)(
  ({ fadeScroll, theme, gap }) => css`
    ${fadeScroll &&
    css`
      margin-top: 0;
      position: relative;

      &::before {
        position: absolute;
        top: ${0 - remValue(theme.globalSizes[gap])}rem;
        height: ${remValue(theme.globalSizes[gap])}rem;
        left: 0;
        width: 100%;
        background: linear-gradient(
            to bottom,
            ${hexToRGBA(theme.color.paper, 0)} 0,
            ${hexToRGBA(theme.color.paper, 1)} ${remValue(theme.globalSizes[gap])}rem
          )
          transparent;
        content: ' ';
      }
    `}
  `
);
/**
 *
 * The DialogFooter component renders the footer of the Dialog - typically used to display action buttons - the
 * body of the dialog scrolls to allow the footer of the dialog to always be visible.
 */
const DialogFooter = ({ children, fadeScroll, gap }) => (
  <FooterBox element="footer" className="DialogFooter" fadeScroll={fadeScroll} gap={gap}>
    {children}
  </FooterBox>
);

DialogFooter.propTypes = {
  /** The children of the footer - usually buttons, but images and/or other decorative elements can be placed in the footer */
  children: PropTypes.node,
  /** Places a gradient element over the scrolling body elements */
  fadeScroll: PropTypes.bool,
  /** The gap specified in the dialog can be passed in to ensure consistent spacing between elements */
  gap: PropTypes.oneOf(Object.keys(globalSizes)),
};

export default DialogFooter;
