import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, Switch, Redirect, Route } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Stack from '@blueprism/ui-core/layout/Stack';
import Box from '@blueprism/ui-core/layout/Box';
import Team from 'components/Team';
import YourProcesses from 'components/YourProcesses';
import SpaceWrapper from 'pages/SpaceWrapper';
import GroupWrapper from 'pages/GroupWrapper';
import ProcessAssessment from 'pages/ProcessAssessment';
import Recommendation from 'pages/Recommendation';
import ProcessAssessmentCreator from 'pages/ProcessAssessmentCreator';
import GroupManagement from 'pages/GroupManagement';
import NotFoundErrorPage from 'pages/Errors/NotFoundErrorPage';
import UnauthorisedErrorPage from 'pages/Errors/UnauthorisedErrorPage';
import ServerErrorPage from 'pages/Errors/ServerErrorPage';
import MaintenanceBanner from 'components/MaintenanceNotificationBanner';
import { findBaseUrl, getUrlTitle } from 'utils/urlHelpers';

const ContentWrapper = styled(Box)`
  overflow: auto;
  flex-grow: 1;
`;

const PageContainer = styled(Stack)`
  max-width: 960px;
`;

function changeTitle(location, intl) {
  const url = findBaseUrl(location.pathname);
  document.title = intl.formatMessage({ id: 'pageTitleMain' }) + ' - ' + getUrlTitle(url, intl);
}

export default function Routes() {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  React.useEffect(() => {
    changeTitle(location, intl);
  }, [location, intl]);

  React.useEffect(() => {
    return history.listen(historyLocation => {
      changeTitle(historyLocation, intl);
    });
  }, [history, intl]);

  return (
    <ContentWrapper element="main" padding="small">
      <PageContainer>
        <MaintenanceBanner />
        <Switch>
          <Route path="/your-processes" exact component={YourProcesses} />
          <Route path="/process-assessment" exact component={ProcessAssessmentCreator} />
          <Route path="/process-assessment/:processId/recommendation" exact component={Recommendation} />
          <Route
            path={['/process-assessment/:processId', '/process-assessment/:processId/:processPage']}
            exact
            component={ProcessAssessment}
          />
          <Route
            path={[
              '/your-spaces/space/:spaceId',
              '/your-spaces/space/:spaceId/:tab(users|groups|teams|admins)',
              '/your-spaces/space/:spaceId/:tab(users|groups|teams|admins)/:offset/:limit',
            ]}
            exact
            component={SpaceWrapper}
          />
          <Route
            path={[
              '/your-spaces/team/:teamId',
              '/your-spaces/team/:teamId/:tab(users|teams)',
              '/your-spaces/team/:teamId/:tab(users|teams)/:offset/:limit',
            ]}
            exact
            component={Team}
          />
          <Route
            path={[
              '/your-spaces/group/:groupId',
              '/your-spaces/group/:groupId/:tab(groups|members)',
              '/your-spaces/group/:groupId/:tab(groups|members)/:offset/:limit',
            ]}
            exact
            component={GroupWrapper}
          />
          <Route
            path={['/your-spaces', '/your-spaces/:offset', '/your-spaces/:offset/:limit']}
            exact
            component={GroupManagement}
          />
          <Route path="/unauthorised" exact component={UnauthorisedErrorPage} />
          <Route path="/notfound" exact component={NotFoundErrorPage} />
          <Route path="/servererror" exact component={ServerErrorPage} />

          <Redirect exact from="/" to="/your-processes" />
          <Redirect exact from="/login" to="/your-processes" />
          <Redirect exact from="/oidc-callback" to="/your-processes" />

          <Redirect to="/notfound" />
        </Switch>
      </PageContainer>
    </ContentWrapper>
  );
}
