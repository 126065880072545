import * as React from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from './AuthContext';
import { user as userApi } from 'utils/api';

export const UserContext = React.createContext();

export default function UserContextProvider({ children }) {
  const [user, setUser] = React.useState({
    isUserSet: false,
    isRegistered: false,
    isFirstVisit: false,
    details: {},
  });

  const { isLoggedIn, getUser } = React.useContext(AuthContext);

  React.useEffect(() => {
    const updateUser = response => {
      setUser({
        isUserSet: true,
        isRegistered: response.isRegistered,
        isFirstVisit: !response.isRegistered,
        details: response.details || {},
      });
    };

    if (isLoggedIn && !user.isUserSet) {
      userApi
        .getUserDetails(getUser)
        .then(response => updateUser(response))
        .catch(err => console.error(err));

      userApi
        .getRefreshedUserDetails(getUser)
        .then(response => updateUser(response))
        .catch(err => console.error(err));
    }
  }, [user, getUser, isLoggedIn]);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
