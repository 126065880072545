import React from 'react';
import PropTypes from 'prop-types';
import { FormField, InputField } from '@blueprism/ui-core';

export default function TextFilter({ enabled, fieldName, label = '', helperText = '', onChange = () => {} }) {
  const [localValue, setLocalValue] = React.useState('');

  function handleTextChange({ target }) {
    setLocalValue(target.value);
    onChange({ [fieldName]: target.value });
  }

  React.useEffect(() => {
    if (enabled && localValue) {
      onChange({ [fieldName]: localValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <FormField gap="base" htmlFor={fieldName} label={label} helperText={helperText}>
      <InputField type="text" id={fieldName} value={localValue} onChange={handleTextChange} />
    </FormField>
  );
}

TextFilter.propTypes = {
  enabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
};
