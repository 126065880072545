import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Stack from '@blueprism/ui-core/layout/Stack';
import Row from '@blueprism/ui-core/layout/Row';
import { typography } from '@blueprism/ui-core/theme/units';

const DescriptionList = styled(Stack)(
  ({ theme }) => css`
    text-align: center;
    color: ${theme.color.text};
  `
);

const MainTitle = styled.dt(
  ({ theme }) => css`
    font-size: ${typography.bodyL.size}rem;
    font-weight: ${theme.font.weight.black};
    order: 2;
  `
);

const MainTitleDescription = styled(Row)(
  ({ theme }) => css`
    order: 1;
    font-size: ${typography.mega.size}rem;
    font-weight: ${theme.font.weight.black};
  `
);

const SecondaryTitle = styled.dt(
  ({ theme }) => css`
    order: 3;
    font-size: ${typography.body.size}rem;
    font-weight: ${theme.font.weight.regular};
  `
);

const SecondaryTitleDescription = styled.dd(
  ({ theme }) => css`
    font-weight: ${theme.font.weight.black};
    font-size: ${typography.body.size}rem;
    order: 4;
  `
);

const IconContainer = styled.span(
  css`
    display: flex;
    align-content: center;
  `
);

const CountContainer = styled.span(
  css`
    text-align: right;
  `
);

export default function JumboMetadata({
  mainTitleDescriptionText = '',
  mainTitleText = '',
  secondaryTitleText = '',
  secondaryTitleDescriptionText = '',
  width = '100%',
  icon,
}) {
  return (
    <DescriptionList element="dl" width={width} justify="center">
      <MainTitle>{mainTitleText}</MainTitle>
      <MainTitleDescription element="dd" justify="center" align="center" gap="base">
        <IconContainer>{icon}</IconContainer>
        <CountContainer padding="none">{mainTitleDescriptionText}</CountContainer>
      </MainTitleDescription>
      <SecondaryTitle>{secondaryTitleText}</SecondaryTitle>
      <SecondaryTitleDescription>{secondaryTitleDescriptionText}</SecondaryTitleDescription>
    </DescriptionList>
  );
}

JumboMetadata.propTypes = {
  mainTitleDescriptionText: PropTypes.string,
  mainTitleText: PropTypes.string,
  secondaryTitleText: PropTypes.string,
  secondaryTitleDescriptionText: PropTypes.string,
  width: PropTypes.string,
  icon: PropTypes.node,
};
