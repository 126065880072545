import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Link from '@blueprism/ui-core/components/Link';
import { UserContext } from 'contexts/UserContext';
import Tour from '../Tour';
import { config } from 'utils/config';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1rem;
`;

const ListItem = styled.li(
  props => css`
    padding: 0.5rem 0;
    text-indent: 1rem;
    transition: text-indent 0.5s ease-in-out, opacity 0.4s ease-out;
    ${props.collapsed &&
    css`
      text-indent: -30rem;
      opacity: 0;
    `}
  `
);

export default function LinkList({ collapsed, avatarImage, firstName }) {
  const { user } = React.useContext(UserContext);
  const [tourModalVisible, setTourModalVisible] = React.useState(user.isFirstVisit);

  function handleTakeTourClick(event) {
    event.preventDefault();
    setTourModalVisible(true);
  }

  function handleTourModalClose() {
    setTourModalVisible(false);
  }

  return (
    <>
      <List>
        <ListItem collapsed={collapsed}>
          <Link onClick={handleTakeTourClick}>
            <FormattedMessage id="navBarMenuItemTakeTour" />
          </Link>
        </ListItem>
        <ListItem collapsed={collapsed}>
          <Link href={config.helpUrl} rel="noopener" target="_blank">
            <FormattedMessage id="navBarMenuItemHelp" />
          </Link>
        </ListItem>
      </List>
      <Tour visible={tourModalVisible} avatarImage={avatarImage} firstName={firstName} onClose={handleTourModalClose} />
    </>
  );
}

LinkList.propTypes = {
  collapsed: PropTypes.bool,
  avatarImage: PropTypes.string,
  firstName: PropTypes.string,
};
