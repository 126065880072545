import React from 'react';
import GroupsContextProvider from 'contexts/GroupsContext';
import GroupContextProvider from 'contexts/GroupContext';
import GroupMembersContextProvider from 'contexts/GroupMembersContext';
import GroupMembersSearchContextProvider from 'contexts/GroupMembersSearchContext';
import Group from './Group';

const GroupWrapper = props => (
  <GroupContextProvider>
    <GroupsContextProvider>
      <GroupMembersContextProvider>
        <GroupMembersSearchContextProvider>
          <Group {...props} />
        </GroupMembersSearchContextProvider>
      </GroupMembersContextProvider>
    </GroupsContextProvider>
  </GroupContextProvider>
);

export default GroupWrapper;
