import React from 'react';
import PropTypes from 'prop-types';
import { GroupMembersContext } from 'contexts/GroupMembersContext';
import GroupMembersList from './GroupMembersList';

export default function GroupMembersContent({ isAdmin = false, selectedRowIds = [], onRowSelectionChange = () => {} }) {
  const { tableStatus, groupMembers, setSelectedGroupMember } = React.useContext(GroupMembersContext);

  const handleSelectedMemberChange = rows => setSelectedGroupMember(rows);

  return (
    <GroupMembersList
      tableStatus={tableStatus}
      data={groupMembers.members}
      onSelectionChanged={handleSelectedMemberChange}
      groupId={groupMembers.groupId}
      offset={groupMembers.offset}
      limit={groupMembers.limit}
      isAdmin={isAdmin}
      selectedRowIds={selectedRowIds}
      onRowSelectionChange={onRowSelectionChange}
    />
  );
}

GroupMembersContent.propTypes = {
  isAdmin: PropTypes.bool,
};
