import * as React from 'react';
import PropTypes from 'prop-types';
import QuestionMark from '@blueprism/ui-icons/QuestionMark';
import Text from '@blueprism/ui-core/components/Text';
import CurrencyInput from 'components/CurrencyInput';
import ToggleTip from 'components/core/ToggleTip';

export default function AssessmentCurrencyQuestion({
  value = { amount: null, currency: null },
  question,
  fieldName = '',
  metaData = [],
  currencyIsInError = false,
  currencyErrorText = '',
  amountIsInError = false,
  amountErrorText = '',
  handleChange = () => {},
}) {
  const [amount, setAmount] = React.useState();

  const currencyData = Object.keys(metaData.availableCurrencies).map(x => [
    metaData.availableCurrencies[x].currencyCode,
    metaData.availableCurrencies[x].currencyName,
  ]);

  const handleCurrencyChange = event => {
    var currencyCode = event.target.value !== null ? event.target.value : null;

    const newValue = {
      currencyCode: currencyCode ?? document.getElementById(`currency-datalist-indexed-${fieldName}`)?.value?.trim(),
      amount: amount,
    };
    handleChange(fieldName, newValue);
  };

  const handleAmountChange = event => {
    const newValue = { ...value, amount: event.target.value };
    setAmount(event.target.value);
    handleChange(fieldName, newValue);
  };

  React.useEffect(() => {
    const initialAmount = value && value.amount != null ? value.amount : '';
    setAmount(initialAmount);
  }, [metaData, value]);

  const currencyInputProps = {
    id: fieldName,
    name: fieldName,
    onAmountChange: handleAmountChange,
    onCurrencyChange: handleCurrencyChange,
    currencyLabel: currencyErrorText || question.currencyHelperText,
    label: question.label,
    amountLabel: amountErrorText || question.description,
    currencyData: currencyData,
    currencyError: currencyIsInError,
    amountError: amountIsInError,
  };

  if (amount != null) {
    currencyInputProps['amountValue'] = amount;
  }

  if (value?.currencyCode !== null && metaData.availableCurrencies.find(x => x.currencyCode === value.currencyCode)) {
    currencyInputProps['currencyValue'] = metaData.availableCurrencies.find(
      x => x.currencyCode === value.currencyCode
    ).currencyName;
  }

  return (
    <>
      {question.questionText && <Text type="body">{question.questionText}</Text>}
      {question.helperText && <ToggleTip detailsText={question.helperText} icon={<QuestionMark size={16} />} />}
      <CurrencyInput {...currencyInputProps} />
    </>
  );
}

AssessmentCurrencyQuestion.propTypes = {
  value: PropTypes.object,
  question: PropTypes.object,
  fieldName: PropTypes.string,
  metaData: PropTypes.object,
  currencyIsInError: PropTypes.bool,
  currencyErrorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  amountIsInError: PropTypes.bool,
  amountErrorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleChange: PropTypes.func,
};
