import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Row from '@blueprism/ui-core/layout/Row';
import FormField from '@blueprism/ui-core/components/FormField';
import InputField from '@blueprism/ui-core/components/InputField';
import Button from '@blueprism/ui-core/components/Button';
import PATDialog from 'components/PATDialog';
import DialogTitle from 'components/DialogTitle';

export default function TeamAddDialog({ team, open = false, mode = 'Add', onConfirm, onClose }) {
  const intl = useIntl();
  const [text, setText] = React.useState('');
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (open) {
      setText(team?.name ?? '');
      setErrors({});
    }
  }, [open, team]);

  function handleClose() {
    onClose();
  }

  function handleCloseClick() {
    onClose();
  }

  function handleTextChange(event) {
    setText(event.target.value);
  }

  async function handleOkClick() {
    const error = await onConfirm(text, team?.teamId);
    setErrors(error ?? {});
    if (!error) {
      onClose();
    }
  }

  const getErrorMessage = fieldName => errors[fieldName];
  const isInError = fieldName => errors[fieldName] != null && errors[fieldName].length > 0;

  function renderFooter() {
    return (
      <Row justify="between">
        <Button id="cancelButton" onClick={handleCloseClick}>
          <FormattedMessage id="addTeamClose" />
        </Button>
        <Button id="okButton" onClick={handleOkClick}>
          <FormattedMessage id={'teamButtonOK' + mode} />
        </Button>
      </Row>
    );
  }

  return (
    <PATDialog
      size="small"
      overlay
      visible={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      headerContent={
        <DialogTitle id="simple-dialog-title">
          <FormattedMessage id={'teams' + mode} />
        </DialogTitle>
      }
      errorText={getErrorMessage('dialogError')}
      footerContent={renderFooter()}
    >
      <FormField
        label={intl.formatMessage({ id: 'addTeamInputTitle' })}
        helperText={intl.formatMessage({ id: `teamHelperText${mode}` })}
        error={isInError('name')}
        errorText={getErrorMessage('name')}
      >
        <InputField value={text} onChange={handleTextChange} />
      </FormField>
    </PATDialog>
  );
}

TeamAddDialog.propTypes = {
  team: PropTypes.object,
  open: PropTypes.bool,
  mode: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};
