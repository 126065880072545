import { useEffect, useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

export default function SilentRenew() {
  const { userManager } = useContext(AuthContext);

  useEffect(() => {
    userManager.signinSilentCallback().catch(error => console.log(error));
  });

  return null;
}
