import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { SpaceAdd, SpaceEdit, SpaceRemove } from '@blueprism/ui-icons';
import Stack from '@blueprism/ui-core/layout/Stack';
import Text from '@blueprism/ui-core/components/Text';
import Toolbar from '@blueprism/ui-core/components/Toolbar';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import Breadcrumbs from '@blueprism/ui-core/components/Breadcrumbs';
import { AuthContext } from 'contexts/AuthContext';
import TablePagination from 'components/TablePagination';
import SpaceAddDialog from 'components/SpaceAddDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import Link from 'components/Link';
import { toUInt } from 'utils/helper';
import useTableRowSelection from 'utils/useTableRowSelection';
import TableLoader from 'components/TableStates/TableLoader';
import { dataStates as tableDataStates } from '../utils/dataStates';
import { spaceManagement } from 'utils/api';

export default function GroupManagement() {
  const intl = useIntl();
  const params = useParams();
  const history = useHistory();
  const { selectedRowIds, onRowSelectionChange, clearSelection } = useTableRowSelection([], true);
  const { getUser } = React.useContext(AuthContext);
  const [spaces, setSpaces] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
  const [removeErrorText, setRemoveErrorText] = React.useState('');

  const selectedSpace =
    (selectedRowIds.length === 1 ? spaces?.find(space => space.id.toString() === selectedRowIds[0]) : {}) ?? {};
  const canModifySpace = selectedSpace.isAdmin ?? false;
  const offset = toUInt(params.offset, 0);
  const limit = toUInt(params.limit, 25);

  const rowData = React.useMemo(
    () =>
      spaces.map(({ id, name, isAdmin, createdAt, createdBy }) => ({
        id: id.toString(),
        name: (
          <Link href={`/your-spaces/space/${id}/users`}>
            {`${name}${isAdmin ? ' (' + intl.formatMessage({ id: 'spacesColumn1Admin' }) + ')' : ''}`}
          </Link>
        ),
        createdBy,
        createdAt: intl.formatDate(new Date(createdAt)),
        isAdmin,
      })),
    [intl, spaces]
  );

  const columns = React.useMemo(
    () => [
      {
        header: intl.formatMessage({ id: 'spacesColumn1' }),
        accessor: 'name',
      },
      {
        header: intl.formatMessage({ id: 'spacesColumn2' }),
        accessor: 'createdAt',
      },
      {
        header: intl.formatMessage({ id: 'spacesColumn3' }),
        accessor: 'createdBy',
      },
    ],
    [intl]
  );

  const [tableStatus, setTableStatus] = React.useState(tableDataStates.INIT);

  const updateSpaces = React.useCallback(async () => {
    let items = [];
    let itemCount = 0;
    setTableStatus(tableDataStates.INIT);

    try {
      const data = await spaceManagement.getSpaces(getUser, offset, limit);
      if (data !== null) {
        items = data.items;
        itemCount = data.count;
      }
    } catch (err) {
      if (err.response.status === 500) {
        history.push('/servererror');
        return;
      } else if (err.response.status === 403) {
        history.push('/login');
        return;
      } else if (err.response.status === 401) {
        history.push('/unauthorised');
        return;
      }
    }
    setSpaces(items);
    setCount(itemCount);
    setTableStatus(tableDataStates.SUCCESS);
  }, [getUser, limit, offset, history]);

  React.useEffect(() => {
    updateSpaces();
  }, [updateSpaces]);

  async function postAddSpaceRequest(name) {
    await spaceManagement.addSpace(getUser, name);
    clearSelection();
    updateSpaces();
    handleAddDialogClose();
  }

  async function postEditSpaceRequest(name) {
    if (selectedSpace.id) {
      await spaceManagement.editSpace(getUser, selectedSpace.id, name);
      clearSelection();
      updateSpaces();
      handleEditDialogClose();
    }
  }

  async function postRemoveSpaceRequest() {
    if (selectedSpace.id) {
      try {
        await spaceManagement.removeSpace(getUser, selectedSpace.id);
        clearSelection();
        updateSpaces();
        handleRemoveDialogClose();
      } catch (err) {
        switch (err.response?.status) {
          case 400:
            setRemoveErrorText(err.response.data);
            break;
          case 500:
            setRemoveErrorText(intl.formatMessage({ id: 'removeSpace500' }));
            break;
          case 403:
            history.push('/login');
            break;
          case 401:
            setRemoveErrorText(intl.formatMessage({ id: 'removeSpace401' }));
            break;
          default:
            break;
        }
      }
    }
  }

  function handlePagingUrlChange({ newOffset, newLimit }) {
    history.push(`/your-spaces/${Math.max(0, newOffset || 0)}/${Math.max(0, newLimit || 25)}`);
  }

  function handlePagingPreviousClick() {
    handlePagingUrlChange({ newOffset: offset - limit, newLimit: limit });
  }

  function handlePagingNextClick() {
    handlePagingUrlChange({ newOffset: offset + limit, newLimit: limit });
  }

  function handlePagingLimitChange(value) {
    // TODO: Should probably debounce this!
    handlePagingUrlChange({ newOffset: offset, newLimit: value });
  }

  function handleAddGroupClick() {
    setAddDialogOpen(true);
  }

  function handleEditGroupClick() {
    setEditDialogOpen(true);
  }

  function handleRemoveGroupClick() {
    setRemoveDialogOpen(true);
  }

  function handleAddDialogClose() {
    setAddDialogOpen(false);
  }

  function handleEditDialogClose() {
    setEditDialogOpen(false);
  }

  function handleRemoveDialogClose() {
    setRemoveDialogOpen(false);
    setRemoveErrorText('');
  }

  return (
    <>
      <Stack gap="base">
        <Breadcrumbs>
          <FormattedMessage id="spaces" />
        </Breadcrumbs>
        <Text type="h1">
          <FormattedMessage id="groupManagement" />
        </Text>
        <Toolbar justify="end">
          <ButtonGroup>
            <ToolbarButton
              onClick={handleAddGroupClick}
              label={intl.formatMessage({ id: 'spacesAdd' })}
              icon={<SpaceAdd />}
            />
            <ToolbarButton
              disabled={!canModifySpace}
              onClick={handleRemoveGroupClick}
              label={intl.formatMessage({ id: 'spacesRemove' })}
              icon={<SpaceRemove />}
            />
            <ToolbarButton
              disabled={!canModifySpace}
              onClick={handleEditGroupClick}
              label={intl.formatMessage({ id: 'spacesEdit' })}
              icon={<SpaceEdit />}
            />
          </ButtonGroup>
        </Toolbar>

        <TableLoader
          tableStatus={tableStatus}
          emptyStateTitle={intl.formatMessage({ id: 'spacesTableNoDataTitle' })}
          emptyStateText={intl.formatMessage({ id: 'spacesTableNoDataText' })}
          errorStateText={intl.formatMessage({ id: 'spacesTableDataFetchFail' })}
          tableLayout="auto"
          rowData={rowData}
          columns={columns}
          selectRows
          selectedIds={selectedRowIds}
          onRowSelectionChange={onRowSelectionChange}
        />

        <TablePagination
          limitLabelId="your-spaces-limit"
          itemName="Spaces"
          limit={limit}
          offset={offset}
          count={count}
          onLimitChange={handlePagingLimitChange}
          onNextData={handlePagingNextClick}
          onPreviousData={handlePagingPreviousClick}
        />
      </Stack>

      <SpaceAddDialog
        title={intl.formatMessage({ id: 'spacesAdd' })}
        open={addDialogOpen}
        onConfirm={postAddSpaceRequest}
        onClose={handleAddDialogClose}
        mode="Add"
      />
      <SpaceAddDialog
        open={editDialogOpen}
        title={intl.formatMessage({ id: 'spacesEdit' })}
        currentName={selectedSpace.name}
        onConfirm={postEditSpaceRequest}
        onClose={handleEditDialogClose}
        mode="Edit"
      />
      <ConfirmDialog
        open={removeDialogOpen}
        errorText={removeErrorText}
        title={intl.formatMessage({ id: 'spacesRemove' })}
        description={intl.formatMessage({ id: 'removeSpaceCheck' })}
        onConfirm={postRemoveSpaceRequest}
        onCancel={handleRemoveDialogClose}
      />
    </>
  );
}
