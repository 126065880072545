import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { UsersContext } from 'contexts/UsersContext';
import TablePagination from 'components/TablePagination';
import User from 'components/User';
import { toUInt } from 'utils/helper';
import TableLoader from 'components/TableStates/TableLoader';
import { config } from 'utils/config';

export default function SpaceUsers({ selectedRowIds = [], onRowSelectionChange = () => {} }) {
  const intl = useIntl();
  const history = useHistory();
  const { tableStatus, userContext } = React.useContext(UsersContext);

  const offset = toUInt(userContext.offset, 0);
  const limit = toUInt(userContext.limit, 25);
  const spaceId = userContext.spaceId;

  const handlePreviousData = () =>
    history.push(`/your-spaces/space/${spaceId}/users/${Math.max(0, offset - limit)}/${limit}`);
  const handleNextData = () => history.push(`/your-spaces/space/${spaceId}/users/${offset + limit}/${limit}`);
  const handleLimitChange = newLimit => history.push(`/your-spaces/space/${spaceId}/users/${offset}/${newLimit}`);

  const count = userContext.users?.count ?? 0;

  const columns = [
    {
      header: intl.formatMessage({ id: 'spaceMembersColumn1' }),
      accessor: 'userDetails',
    },
    {
      header: intl.formatMessage({ id: 'spaceMembersColumn2' }),
      accessor: 'role',
    },
  ];

  const rowData = () => {
    if (userContext.users) {
      return userContext.users.items.map((x, i) => ({
        id: x.memberId.toString(),
        user: x,
        userDetails: (
          <User
            userName={x.name + (x.isCurrentUser ? ` (${intl.formatMessage({ id: 'memberYou' })})` : '')}
            avatarImage={x.avatar && config.imageUrl(x.avatar)}
          />
        ),
        role: x.isAdmin && intl.formatMessage({ id: 'isAdmin' }),
      }));
    }

    return [];
  };

  return (
    <>
      <TableLoader
        tableStatus={tableStatus}
        emptyStateTitle={intl.formatMessage({ id: 'spaceUsersTableNoDataTitle' })}
        emptyStateText={intl.formatMessage({ id: 'spaceUsersTableNoDataText' })}
        errorStateText={intl.formatMessage({ id: 'spaceUsersTableDataFetchFail' })}
        tableLayout="auto"
        rowData={rowData()}
        columns={columns}
        selectRows
        selectedIds={selectedRowIds}
        onRowSelectionChange={onRowSelectionChange}
      />
      <TablePagination
        limitLabelId="space-users-limit"
        itemName="Users"
        limit={limit}
        offset={offset}
        count={count}
        onLimitChange={handleLimitChange}
        onNextData={handleNextData}
        onPreviousData={handlePreviousData}
      />
    </>
  );
}
