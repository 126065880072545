import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { remValue } from '@blueprism/ui-core/utils/utils';
import Stack from '@blueprism/ui-core/layout/Stack';
import { globalSizes } from '@blueprism/ui-core/theme/units';

const ContentWrapper = styled(Stack)`
  flex-grow: 1;
  overflow-y: auto;
`;

const ContentSection = styled(Stack)(
  ({ contentHeight, contentWidth, fadeScroll, gap, theme }) => css`
    max-width: ${contentWidth};
    height: ${contentHeight};

    ${fadeScroll &&
    css`
      padding-bottom: ${remValue(theme.globalSizes[gap])}rem;
    `}
  `
);
/**
 *
 * The DialogBody component is a resizing container which is used to present the body of a dialog
 * Placed inside a Stack component it allows for the contents of the dialog to resize, whilst ensuring
 * the contents of a DialogFooter component also in the same dialog is always displayed
 */
const DialogBody = ({ children, contentWidth, contentHeight, fadeScroll, gap = 'base', ...rest }) => (
  <ContentWrapper padding="none" gap={gap} {...rest}>
    <ContentSection contentWidth={contentWidth} contentHeight={contentHeight} fadeScroll={fadeScroll} gap={gap}>
      {children}
    </ContentSection>
  </ContentWrapper>
);

DialogBody.propTypes = {
  /** Content of the component */
  children: PropTypes.node,
  /** Set the preferred height of the scrolling content (px/rem/percent)*/
  contentHeight: PropTypes.string,
  /** Set the preferred width of the scrolling content (px/rem/percent)*/
  contentWidth: PropTypes.string,
  /** Fade the base of the scrolling content box to make obvious that there is more content */
  fadeScroll: PropTypes.bool,
  /** Space between header / content /footer (Dialog is a styled Stack)
   *
   *  choose from : [ none | smallest | tiny | xxs | xs | small | base | medium | large | xl | xxl | largest ] */
  gap: PropTypes.oneOf(Object.keys(globalSizes)),
};

export default DialogBody;
