import * as React from 'react';
import Button from '@blueprism/ui-core/components/Button';
import FormField from '@blueprism/ui-core/components/FormField';
import Text from '@blueprism/ui-core/components/Text';
import GroupSelectorDialog from 'components/GroupSelector';

export default function AssessmentGroupSelector({
  hasAssessmentBeenSaved,
  label,
  groupIdFieldName,
  groupNameFieldName,
  helperText,
  value,
  groupName,
  onChange,
  validationLabel,
  selectGroup,
  changeGroup,
  isInError,
  errorText = '',
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleDialogOpen() {
    setIsOpen(true);
  }

  function handleDialogClose() {
    setIsOpen(false);
  }

  function handleGroupChange(groupId, newGroupName) {
    onChange(groupIdFieldName, groupId);
    onChange(groupNameFieldName, newGroupName);
  }

  return (
    <>
      <FormField label={label} error={isInError} helperText={validationLabel} errorText={errorText}>
        <Text>{groupName && helperText + ' ' + groupName}</Text>
        <Button onClick={handleDialogOpen}>{value === null || value === 0 ? selectGroup : changeGroup}</Button>
      </FormField>

      <GroupSelectorDialog
        disableAdminCheck={!hasAssessmentBeenSaved}
        currentGroupId={value}
        isOpen={isOpen}
        onClose={handleDialogClose}
        onConfirm={handleGroupChange}
      />
    </>
  );
}
