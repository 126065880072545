import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const translate = (from = [0, 0], to = [0, 0]) => keyframes`
  from {
    transform: translate(${from[0]}px, ${from[1]}px);
  }

  to {
    transform: translateX(${to[0]}px, ${to[1]}px);
  }
`;

const translateX = (from = 0, to = 0) => keyframes`
  from {
    transform: translateX(${from}px);
  }

  to {
    transform: translateX(${to}px);
  }
`;

const translateY = (from = 0, to = 0) => keyframes`
  from {
    transform: translateY(${from}px);
  }

  to {
    transform: translateY(${to}px);
  }
`;

const dashOffset = (from = 0, to = 0) => keyframes`
  from {
    stroke-dashoffset: ${from};
  }

  to {
    stroke-dashoffset: ${to};
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const Svg = styled.svg`
  fill-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  clip-rule: evenodd;
  width: 100%;
  height: 100%;
  background: #e1f4f9;
`;

const TrackPaths = styled.g`
  path {
    stroke: #80cfeb;
    stroke-width: 15;
    fill: none;
  }

  path:nth-of-type(1) {
    animation: 8s ease-in-out infinite alternate ${translateX(-30, 30)};
  }
`;

const BlueCircles = styled.g`
  path {
    fill: #b1e2f2;
  }

  path:nth-of-type(1) {
    animation: 11s ease-in-out infinite alternate ${translateY(-80, 20)};
  }

  path:nth-of-type(2) {
    animation: 10s ease-in-out infinite alternate ${translateY(-120, 50)};
  }

  path:nth-of-type(3) {
    animation: 12s ease-in-out infinite alternate ${translate([40, 40], [-40, -40])};
  }

  path:nth-of-type(4) {
    animation: 10s ease-in-out infinite alternate ${translateY(0, 160)};
  }
`;

const YellowCircles = styled.g`
  path {
    fill: #f9f0df;
  }

  path:nth-of-type(1) {
    animation: 10s ease-in-out infinite alternate ${translate([40, 40], [-40, -40])};
  }

  path:nth-of-type(2) {
    animation: 9s ease-in-out infinite alternate ${translate([160, 160], [0, 0])};
  }
`;

const lineBlockFirst = keyframes`
  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(40px);
  }
`;

const lineBlockSecond = keyframes`
  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(40px);
  }
`;

const LineBlock = styled.g`
  path {
    stroke: #18a1db;
    stroke-width: 12;
    fill: none;
  }

  path:nth-of-type(1),
  path:nth-of-type(3),
  path:nth-of-type(5) {
    animation: 20s ease-in-out infinite alternate ${lineBlockFirst};
  }

  path:nth-of-type(2),
  path:nth-of-type(4),
  path:nth-of-type(6) {
    animation: 20s ease-in-out infinite alternate ${lineBlockSecond};
  }
`;

const Icons = styled.g`
  path {
    stroke: #19a2db;
    fill: none;
    stroke-width: 18;
  }

  path:nth-of-type(1),
  path:nth-of-type(5),
  path:nth-of-type(6) {
    fill: #19a2db;
    stroke-width: 0;
  }

  path:nth-of-type(4) {
    stroke-width: 30;
  }

  path:nth-of-type(7) {
    fill: #80cfeb;
    stroke-width: 0;
  }
`;

const FilterLines = styled.g`
  fill: none;
  stroke: #80cfeb;
  stroke-dasharray: 30 30;
  stroke-width: 15;
  animation: 5s linear infinite ${dashOffset(0, -60)};
`;

const UnderMagnifyingGlass = styled.path`
  fill: none;
  stroke: #80cfeb;
  stroke-dasharray: 0 40;
  stroke-width: 30;
  animation: 2s linear infinite ${dashOffset(0, -40)};
  clip-path: url(#clipPath);
`;

const Crosses = styled.g`
  fill: #b2e2f3;
`;

const Ticks = styled.g`
  fill: none;
  stroke: #b2e2f3;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-width: 18;
`;

const AnimatedBackground = ({ children }) => (
  <Container>
    <Svg viewBox="0 0 4320 3072" width="4320" height="3072" preserveAspectRatio="xMidYMid slice">
      <defs>
        <clipPath id="clipPath" fill="#FFFFFF">
          <circle fill="#000" cx="3484" cy="2515" r="85" />
        </clipPath>
      </defs>
      <YellowCircles>
        <path d="M3948 72a196 196 0 110 391 196 196 0 010-391z" />
        <path d="M1474 1959a138 138 0 110 276 138 138 0 010-276z" />
      </YellowCircles>
      <BlueCircles>
        <path d="M318 2407a233 233 0 110 465 233 233 0 010-465z" />
        <path d="M4334 69a276 276 0 110 552 276 276 0 010-552z" />
        <path d="M1786 2391a93 93 0 110 186 93 93 0 010-186z" />
        <path d="M994 930a117 117 0 110 234 117 117 0 010-234z" />
      </BlueCircles>
      <TrackPaths>
        <path d="M630 1276l-42 43 42 42-42-42 42-43zm-42 43h127" />
        <path d="M3580 2532h335l1-1303m-674 609v-89h360v89m311-197h-491v108" />
        <path d="M2030 2729h-200v-197h1552M1047 2822v93m-774-186v186h2532v-186h-589" />
        <path d="M3914 968V805h-614V488m42 43l-42-43-42 43 42-43 42 43z" />
        <path d="M273 2638V1319h94M230 2595l43 43 42-43-42 43-43-43zm467-463v-89h360v89m-784-197h604v108" />
        <path d="M925 889v291M550 889h516-516zm0-129v129" />
      </TrackPaths>
      <LineBlock>
        <path d="M3764 1029h300" />
        <path d="M3764 1060h300" />
        <path d="M3764 1091h300" />
        <path d="M3764 1122h300" />
        <path d="M3764 1153h300" />
        <path d="M3764 1184h300" />
      </LineBlock>
      <LineBlock>
        <path d="M776 1240h300" />
        <path d="M776 1271h300" />
        <path d="M776 1302h300" />
        <path d="M776 1333h300" />
        <path d="M776 1364h300" />
        <path d="M776 1395h300" />
      </LineBlock>
      <Icons>
        <path d="M3300 324a18 18 0 100 36 18 18 0 000-36zm0-18a36 36 0 110 72 36 36 0 010-72zm-45 18h4a46 46 0 00-4 14 13 13 0 105 25c3 4 5 8 9 11-4 3-9 4-14 4a27 27 0 010-54zm90 0a27 27 0 11-14 50c4-3 6-7 9-11l5 1a14 14 0 000-26l-4-14h4zm-90 54c4 0 8 1 11 3a48 48 0 00-7 11l-4-1c-7 0-13 7-13 14v27h-14v-27c0-15 12-27 27-27zm90 0c15 0 27 12 27 27v27h-14v-27a13 13 0 00-17-13 48 48 0 00-7-11c3-2 7-3 11-3zm-45 0c20 0 36 16 36 36v36h-18v-36a18 18 0 00-36 0v36h-18v-36c0-20 16-36 36-36z" />
        <path d="M3474 2423a93 93 0 110 185 93 93 0 010-185zm72 164l129 129" />
        <path d="M459 534h180l-67 90v69l-46 30v-99l-67-90z" />
        <path d="M3588 2629l80 81" />
        <path d="M2168 2725v9c0 2-2 4-5 4h-9a5 5 0 01-4-4v-9c0-3 2-5 4-5h9c3 0 5 2 5 5zm-90-5v18h-18v-18h18zm63 77c0 2-2 4-5 4h-45c-2 0-4-2-4-4v-45c0-3 2-5 4-5h14v-37a27 27 0 1118 0v37h13c3 0 5 2 5 5v45z" />
        <path d="M523 1271h-19a27 27 0 01-51 0h-20v108h90v-108zm0-18c10 0 18 8 18 18v108c0 9-8 18-18 18h-90c-10 0-18-9-18-18v-108c0-10 8-18 18-18h90z" />
        <path d="M514 1352c0 5-4 9-9 9h-54a9 9 0 010-18h54c5 0 9 4 9 9zm-18-36c0 5-4 9-9 9h-36a9 9 0 010-18h36c5 0 9 4 9 9z" />
      </Icons>
      <FilterLines>
        <path d="M0 360h505v97" />
        <path d="M191-126v341h344v272" />
        <path d="M1381-75v344H595v188" />
        <path d="M565 0v487" />
      </FilterLines>
      <UnderMagnifyingGlass d="M3380 2532h200" />
      <Crosses>
        <path d="M3591 1868h-21l16 31-17 32h21l9-18 10 18h21l-18-32 17-31h-20l-10 17-9-17z" />
        <path d="M1049 2164h-21l16 31-17 32h21l9-18 10 18h21l-18-32 17-31h-20l-10 17-9-17z" />
        <path d="M1104 857h-21l16 31-17 32h21l9-18 10 18h21l-18-32 17-31h-20l-10 17-9-17z" />
        <path d="M1039 2735h-21l16 31-17 32h21l9-18 10 18h21l-18-32 17-31h-20l-10 17-9-17z" />
      </Crosses>
      <Ticks>
        <path d="M3206 1894l19 20 47-44" />
        <path d="M664 2187l19 20 47-44" />
      </Ticks>
    </Svg>
    {children}
  </Container>
);

export default AnimatedBackground;
