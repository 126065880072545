import React from 'react';
import { useIntl } from 'react-intl';
import ErrorPageTemplate from './ErrorPageTemplate';
import { NotFound } from 'components/ErrorSvgs';

export default function NotFoundErrorPage() {
  const intl = useIntl();

  return (
    <ErrorPageTemplate
      headingText={intl.formatMessage({ id: '404header' })}
      errorMessageText={intl.formatMessage({ id: '404messageBody' })}
      linkText={intl.formatMessage({ id: '404linkText' })}
      linkHref="/your-processes"
      image={<NotFound />}
    />
  );
}
