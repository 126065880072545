import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import PATDialog from 'components/PATDialog';
import Button from '@blueprism/ui-core/components/Button';
import Row from '@blueprism/ui-core/layout/Row';
import Stack from '@blueprism/ui-core/layout/Stack';
import Box from '@blueprism/ui-core/layout/Box';
import Breadcrumbs from '@blueprism/ui-core/components/Breadcrumbs';
import Text from '@blueprism/ui-core/components/Text';
import Link from '@blueprism/ui-core/components/Link';
import { AuthContext } from 'contexts/AuthContext';
import DialogTitle from 'components/DialogTitle';
import ErrorMessage from 'components/ErrorMessage';
import ErrorDialog from 'components/ErrorDialog';
import Toolbar from '@blueprism/ui-core/components/Toolbar';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import { ArrowLeft, ArrowRight } from '@blueprism/ui-icons';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import { spaceManagement } from 'utils/api';

const StyledUl = styled(Stack)`
  list-style-type: none;
  padding: 0;
`;

const StyledBox = styled(Box)(
  props => css`
    width: 100%;
  `
);

const FooterStack = styled(Stack)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.color.structure};
  `
);

const StyledHeader = styled(Stack)(
  ({ theme }) => css`
    padding-bottom: ${theme.globalSizes.base};
    border-bottom: 1px solid ${theme.color.structure};
  `
);

export default function GroupSelectorDialog({
  disableAdminCheck = false,
  currentGroupId = -1,
  isOpen,
  onClose,
  onConfirm,
}) {
  const intl = useIntl();

  const { getUser } = React.useContext(AuthContext);
  const [displayValidationError, setDisplayValidationError] = React.useState(false);
  const [userIsNotAdmin, setUserIsNotAdmin] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [content, setContent] = React.useState([]);
  const [group, setGroup] = React.useState(null);
  const [pageSettings, setPageSettings] = React.useState({
    isOpen: false,
    hasAutoSkippedSpaces: false,
    isRootGroup: false,
    contentId: -1,
    spaceId: -1,
    offset: 0,
    limit: 5,
  });

  React.useEffect(() => {
    setPageSettings({
      isOpen: isOpen,
      hasAutoSkippedSpaces: currentGroupId > 0,
      isRootGroup: false,
      spaceId: -1,
      contentId: currentGroupId,
      limit: 5,
      offset: 0,
    });
    setCount(0);
    setContent([]);
    setGroup(null);
    setUserIsNotAdmin(false);
  }, [isOpen, currentGroupId]);

  React.useEffect(() => {
    setDisplayValidationError(false);
    if (disableAdminCheck) {
      setUserIsNotAdmin(false);
      return;
    }

    if (group && currentGroupId === group.groupId && group.privLevel !== 2) {
      setUserIsNotAdmin(true);
      return;
    }

    setUserIsNotAdmin(false);
  }, [pageSettings, currentGroupId, group, disableAdminCheck]);

  React.useEffect(() => {
    if (!pageSettings.isOpen) {
      return;
    }
    if (pageSettings.contentId <= 0) {
      spaceManagement
        .getSpaces(getUser, pageSettings.offset, pageSettings.limit)
        .then(response => {
          if (!pageSettings.hasAutoSkippedSpaces && response.count === 1) {
            setGroup({
              spaceId: response.items[0].id,
              spaceName: response.items[0].name,
              name: '',
              parentGroups: [],
            });
            setPageSettings(settings => ({
              ...settings,
              hasAutoSkippedSpaces: true,
              isRootGroup: true,
              contentId: response.items[0].rootGroupId,
              spaceId: response.items[0].id,
              offset: 0,
            }));
            return;
          }

          setContent(response.items);
          setCount(response.count);
        })
        .catch(err => console.error(err));
    } else if (pageSettings.spaceId > 0) {
      spaceManagement
        .getGroupContent(getUser, pageSettings.spaceId, pageSettings.contentId, pageSettings.offset, pageSettings.limit)
        .then(response => {
          setContent(response.items);
          setCount(response.count);
        })
        .catch(() => {
          setContent([]);
          setCount(0);
        });
    }
  }, [pageSettings, getUser]);

  React.useEffect(() => {
    if (!pageSettings.isOpen) {
      return;
    }

    if (pageSettings.contentId > 0 && !pageSettings.isRootGroup) {
      spaceManagement
        .getGroup(getUser, pageSettings.contentId)
        .then(response => {
          setPageSettings(settings =>
            response.spaceId === settings.spaceId ? settings : { ...settings, spaceId: response.spaceId }
          );
          setGroup(response);
        })
        .catch(err => console.error(err));
    }
  }, [pageSettings.contentId, pageSettings.isRootGroup, getUser, pageSettings.isOpen]);

  function handleContentSelect(event, contentId, spaceId, isRootGroup) {
    event.preventDefault();
    setPageSettings(settings => ({
      ...settings,
      isRootGroup: isRootGroup,
      spaceId: spaceId,
      contentId: contentId,
      offset: 0,
    }));

    if (isRootGroup) {
      setGroup({
        contentId: contentId,
        rootGroupId: contentId,
        spaceId: spaceId,
        spaceName: event.target.innerText,
        name: '',
        parentGroups: [],
      });
    }
  }

  function handleNextData() {
    setPageSettings(settings => ({ ...settings, offset: settings.offset + settings.limit }));
  }

  function handlePreviousData() {
    setPageSettings(settings => ({ ...settings, offset: Math.max(0, settings.offset - settings.limit) }));
  }

  function getLinkElements() {
    if (!group || pageSettings.contentId <= 0) {
      return [
        <Text key="breadCrumbText">
          <FormattedMessage id="spaces" />
        </Text>,
      ];
    }

    const links = [
      { contentId: -1, spaceId: -1, isRootGroup: true, text: intl.formatMessage({ id: 'spaces' }) },
      {
        contentId: group.rootGroupId,
        spaceId: group.spaceId,
        isRootGroup: true,
        text: group.spaceName,
      },
    ].concat(
      group.parentGroups.map(parent => ({
        contentId: parent.id,
        spaceId: group.spaceId,
        text: parent.name,
        isRootGroup: false,
      }))
    );

    if (!pageSettings.isRootGroup) {
      links.push({
        contentId: group.groupId,
        spaceId: group.spaceId,
        text: group.name,
        isRootGroup: false,
      });
    }

    return links.map((link, index) =>
      index === links.length - 1 ? (
        <Text id="selected-group" aria-describedby="selected-group-label" key="breadCrumbText">
          {link.text}
        </Text>
      ) : (
        <Link
          key={link.contentId + '-' + link.isRootGroup}
          onClick={event => handleContentSelect(event, link.contentId, link.spaceId, link.isRootGroup)}
        >
          {link.text}
        </Link>
      )
    );
  }

  function renderContent() {
    if ((content?.length ?? 0) === 0) {
      return (
        <li option="option" key="no-content">
          <FormattedMessage id="groupSelectorNoContent" />
        </li>
      );
    }

    if (pageSettings.contentId <= 0) {
      return content.map(item => (
        <li option="option" key={item.id}>
          <Link onClick={event => handleContentSelect(event, item.rootGroupId, item.id, true)}>{item.name}</Link>
        </li>
      ));
    }

    return content.map(item => (
      <li key={item.id}>
        <Link onClick={event => handleContentSelect(event, item.id, pageSettings.spaceId, false)}>{item.name}</Link>
      </li>
    ));
  }

  function handleConfirm() {
    if (pageSettings.isRootGroup || pageSettings.contentId <= 0) {
      setDisplayValidationError('groupSelectorError');
      return;
    }

    if (group.privLevel === 0) {
      setDisplayValidationError('groupSelectorNoAccessError');
      return;
    }

    onConfirm(group.groupId, group.name);
    onClose();
  }

  function renderFooter() {
    return (
      <FooterStack>
        <Toolbar>
          <Row justify="between" align="center" width="100%">
            <Text>
              {intl.formatMessage(
                { id: 'paginationShowingRangeOfItems' },
                {
                  itemName: intl.formatMessage({ id: 'groups' }),
                  rangeBottom: pageSettings.offset + 1,
                  rangeTop: Math.min(pageSettings.offset + pageSettings.limit, count),
                  max: count,
                }
              )}
            </Text>
            <ButtonGroup>
              <ToolbarButton
                icon={<ArrowLeft />}
                label={intl.formatMessage({ id: 'paginationPrev' })}
                onClick={handlePreviousData}
                disabled={pageSettings.offset <= 0}
              />
              <ToolbarButton
                icon={<ArrowRight />}
                label={intl.formatMessage({ id: 'paginationNext' })}
                disabled={pageSettings.offset + pageSettings.limit >= count}
                onClick={handleNextData}
              />
            </ButtonGroup>
          </Row>
        </Toolbar>
        <Row justify="between">
          <Button onClick={onClose}>
            <FormattedMessage id="groupSelectorCancel" />
          </Button>
          <Button
            aria-label={`${intl.formatMessage({ id: 'groupSelectorSelect' })} ${
              group?.name ?? intl.formatMessage({ id: 'groupSelectorGroup' })
            }`}
            onClick={handleConfirm}
          >
            <FormattedMessage id="groupSelectorOK" />
          </Button>
        </Row>
      </FooterStack>
    );
  }

  return (
    <>
      <ErrorDialog
        title={intl.formatMessage({ id: 'groupNotAdminTitle' })}
        bodyText={intl.formatMessage({ id: 'groupNotAdminBody' }, { groupName: group?.name ?? '' })}
        errorText={intl.formatMessage({ id: 'groupNotAdminError' })}
        open={isOpen && (group !== null || pageSettings.contentId <= 0) && userIsNotAdmin ? true : false}
        onOk={onClose}
        onClose={onClose}
      />
      <PATDialog
        size="large"
        overlay
        visible={isOpen && (group !== null || pageSettings.contentId <= 0) && !userIsNotAdmin ? true : false}
        onClose={onClose}
        aria-labelledby="group-selector-dialog-title"
        headerContent={
          <StyledHeader gap="small">
            <DialogTitle id="group-selector-dialog-title">
              <FormattedMessage id="selectGroupTitle" />
            </DialogTitle>
            <Breadcrumbs id="groupBreadcrumb">{getLinkElements()}</Breadcrumbs>
          </StyledHeader>
        }
        footerContent={renderFooter()}
      >
        <StyledBox gap="base" padding="base">
          {displayValidationError && <ErrorMessage label={intl.formatMessage({ id: displayValidationError })} />}
          <StyledUl
            gap="small"
            role="listbox"
            aria-label={intl.formatMessage({ id: 'groupSelectorAvailableGroups' })}
            aria-controls="selected-group"
            aria-live="polite"
            element="ul"
          >
            {renderContent()}
          </StyledUl>
        </StyledBox>
      </PATDialog>
    </>
  );
}

GroupSelectorDialog.propTypes = {
  disableAdminCheck: PropTypes.bool,
  currentGroupId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
