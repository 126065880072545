import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
  DialogModal,
  RadioButtonGroup,
  RadioButton,
  Button,
  FormField,
  Row,
  Box,
  Text,
  Stack,
  Placeholder,
} from '@blueprism/ui-core';
import { typography } from '@blueprism/ui-core/theme/units';
import { AuthContext } from 'contexts/AuthContext';
import DialogTitle from 'components/DialogTitle';
import ErrorMessage from 'components/ErrorMessage';
import { assessments } from 'utils/api';

const HeaderInfoContainer = styled(Box)(
  props => css`
    width: 100%;
    border-bottom: 1px solid ${props.theme.color.structure};
  `
);

const BoldText = styled.h2`
  font-size: ${typography.body.size}em;
  font-weight: 700;
`;

const dialogStates = { FETCHING: 'fetching', ERROR: 'error', READY: 'ready' };

const DialogHeaderContent = ({ dialogstatus }) => (
  <DialogTitle id="set-status-dialog-title">
    <FormattedMessage id={dialogstatus === dialogStates.ERROR ? 'setStatusNotAdminTitle' : 'setStatusTitle'} />
  </DialogTitle>
);

function DialogFooterContent({ dialogstatus, onClose, onConfirm }) {
  switch (dialogstatus) {
    case dialogStates.FETCHING:
      return null;

    case dialogStates.ERROR:
      return (
        <Row justify="end">
          <Button onClick={onClose}>
            <FormattedMessage id="setStatusNotAdminConfirm" />
          </Button>
        </Row>
      );

    case dialogStates.READY:
    default:
      return (
        <Row justify="between">
          <Button onClick={onClose}>
            <FormattedMessage id="setStatusCancel" />
          </Button>
          <Button onClick={onConfirm}>
            <FormattedMessage id="setStatusChange" />
          </Button>
        </Row>
      );
  }
}

export default function SetStatusDialog({ assessmentId, assessmentTitle, isOpen = false, onClose, onConfirm }) {
  const intl = useIntl();
  const { getUser } = React.useContext(AuthContext);

  const [validOptions, setValidOptions] = React.useState([]);
  const [status, setStatus] = React.useState();
  const [errorText, setErrorText] = React.useState('');
  const [dialogstatus, setDialogStatus] = React.useState(dialogStates.FETCHING);

  React.useEffect(() => {
    if (isOpen === false || assessmentId <= 0) {
      return;
    }

    setErrorText('');
    setDialogStatus(dialogStates.FETCHING);

    assessments
      .getStatusForChanging(getUser, assessmentId)
      .then(fetchedStatus => {
        setValidOptions(fetchedStatus.validOptions);
        setStatus(fetchedStatus.status);
        setDialogStatus(dialogStates.READY);
      })
      .catch(err => {
        if (err.response?.status === 400) {
          setErrorText(err.response.data);
          setDialogStatus(dialogStates.READY);
        } else if (err.response?.status === 401) {
          setErrorText(intl.formatMessage({ id: 'setStatusNotAdminMessage' }));
          setDialogStatus(dialogStates.ERROR);
        } else {
          console.log(err.message);
        }
      });
  }, [assessmentId, getUser, intl, isOpen]);

  async function handleConfirm() {
    try {
      await assessments.postStatus(getUser, assessmentId, status);
      onConfirm();
    } catch (err) {
      setDialogStatus(dialogStates.ERROR);

      if (err.response?.status === 401) {
        setErrorText(intl.formatMessage({ id: 'setStatusNotAdmin' }));
      } else {
        setErrorText(intl.formatMessage({ id: 'setStatusUpdateFailed' }));
        console.log(err.message);
      }
      return;
    }
  }

  function handleClose() {
    onClose();
  }

  const renderOptions = React.useMemo(
    () =>
      Object.entries(validOptions).map(([key, value]) => (
        <RadioButton
          id={`radio-button-${key}`}
          key={value}
          name={`radio-button-group-${key}`}
          value={Number(key)}
          label={value}
        />
      )),
    [validOptions]
  );

  function handleGroupChange(event) {
    setStatus(Number(event.target.value));
  }

  function DialogContent() {
    switch (dialogstatus) {
      case dialogStates.FETCHING:
        return <Placeholder height="2rem" />;

      case dialogStates.ERROR:
        return (
          <>
            <ErrorMessage label={errorText} />
            <Text type="body">{assessmentTitle}</Text>
          </>
        );

      case dialogStates.READY:
      default:
        return (
          <Stack gap="base">
            <HeaderInfoContainer>
              <BoldText>
                <FormattedMessage id="setStatusAssessmentsHeader" />
              </BoldText>
              <Text>{assessmentTitle}</Text>
            </HeaderInfoContainer>
            <FormField
              id="statusField"
              htmlFor="status"
              label={intl.formatMessage({ id: 'setStatusFieldHeader' })}
              errorText={errorText}
              error={!!errorText}
              aria-describedby="status-helper"
            >
              <RadioButtonGroup error={!!errorText} name="status" onChange={handleGroupChange} value={status}>
                {renderOptions}
              </RadioButtonGroup>
            </FormField>
          </Stack>
        );
    }
  }

  return (
    <DialogModal
      overlay
      visible={isOpen}
      onClose={onClose}
      aria-labelledby="set-status-dialog-title"
      headerContent={<DialogHeaderContent dialogstatus={dialogstatus} />}
      footerContent={
        <DialogFooterContent dialogstatus={dialogstatus} onClose={handleClose} onConfirm={handleConfirm} />
      }
    >
      <DialogContent />
    </DialogModal>
  );
}

SetStatusDialog.propTypes = {
  assessmentId: PropTypes.number.isRequired,
  assessmentTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
