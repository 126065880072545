import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '@blueprism/ui-core/components/ButtonGroup';
import ToolbarButton from '@blueprism/ui-core/components/ToolbarButton';
import PersonPlus from '@blueprism/ui-icons/PersonPlus';
import PersonRemove from '@blueprism/ui-icons/PersonRemove';
import { AuthContext } from 'contexts/AuthContext';
import TeamUserAddDialog from './TeamUserAddDialog';
import TeamUserRemoveDialog from './TeamUserRemoveDialog';
import { spaceManagement } from 'utils/api';

export default function TeamUserButtons({
  onUserAdded = () => {},
  onUserRemoved = () => {},
  team,
  canEditSelection,
  currentUser,
}) {
  const intl = useIntl();
  const history = useHistory();
  const { getUser } = React.useContext(AuthContext);
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);

  function handleRemoveClose() {
    setRemoveDialogOpen(false);
  }

  function handleRemoveUser() {
    setRemoveDialogOpen(true);
  }

  function handleAddClose() {
    setAddDialogOpen(false);
  }

  function handleAddUser() {
    setAddDialogOpen(true);
  }

  const addTeamUser = userId =>
    spaceManagement
      .addMemberToTeam(getUser, userId ?? 0, team.teamId)
      .then(response => {
        setAddDialogOpen(false);
        onUserAdded();
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 400:
            return err.response.data;
          case 500:
            return {
              api: intl.formatMessage({ id: '500TeamDialogText' }),
            };
          case 401:
            return { api: intl.formatMessage({ id: '401TeamDialogText' }) };
          case 403:
            history.push('/login');
            return;
          default:
            console.log(err);
            return;
        }
      });

  const removeTeamUser = userId =>
    spaceManagement
      .removeMemberFromTeam(getUser, userId ?? 0, team.teamId)
      .then(() => {
        setRemoveDialogOpen(false);
        onUserRemoved(userId);
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 400:
            return err.response.data;
          case 500:
            return {
              api: intl.formatMessage({ id: '500TeamDialogText' }),
            };
          case 401:
            return { api: intl.formatMessage({ id: '401TeamDialogText' }) };
          case 403:
            history.push('/login');
            return;
          default:
            console.log(err);
            return;
        }
      });

  return (
    <>
      <ButtonGroup>
        <ToolbarButton
          onClick={handleAddUser}
          label={intl.formatMessage({ id: 'teamMembersAdd' })}
          icon={<PersonPlus />}
        />
        <ToolbarButton
          onClick={handleRemoveUser}
          disabled={!canEditSelection}
          label={intl.formatMessage({ id: 'teamMembersRemove' })}
          icon={<PersonRemove />}
        />
      </ButtonGroup>
      <TeamUserAddDialog
        open={addDialogOpen}
        spaceId={team?.spaceId}
        onConfirm={addTeamUser}
        onClose={handleAddClose}
      />
      <TeamUserRemoveDialog
        user={currentUser}
        open={removeDialogOpen}
        onConfirm={removeTeamUser}
        onClose={handleRemoveClose}
      />
    </>
  );
}
