import React from 'react';
import PropTypes from 'prop-types';
import RegistrationQuestions from 'pages/RegistrationQuestions/RegistrationQuestions';
import { UserContext } from 'contexts/UserContext';

export default function RegistrationGuard({ children }) {
  const { user } = React.useContext(UserContext);

  if (!user.isUserSet) {
    return null;
  }

  if (user.isRegistered) {
    return children;
  }

  return <RegistrationQuestions />;
}

RegistrationGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
