import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AvatarProgress from '@blueprism/ui-core/components/AvatarProgress';
import DialogModal from '@blueprism/ui-core/components/DialogModal';
import Center from '@blueprism/ui-core/layout/Center';
import Box from '@blueprism/ui-core/layout/Box';
import Stack from '@blueprism/ui-core/layout/Stack';
import { globalSizes } from '@blueprism/ui-core/theme/units';

//TODO: the all the ">" entries below are a temporary fix - can be removed when dialogModal has been updated to remove headers and footers (and not be a Stack)
const DialogWithOverFlow = styled(DialogModal)`
  overflow: visible;
  position: relative;
  padding: 0;

  > footer,
  > header {
    display: none;
  }

  > div {
    margin-top: 0;
  }
`;

const AvatarBox = styled(Box)(
  props => css`
    background: url("data:image/svg+xml,%3Csvg  viewBox='0 0 58 53' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.358 3.752L.54 28.938a2.22 2.22 0 0 0 .508 3.337l21.818 13.404a2.166 2.166 0 0 0 2.267 0l21.818-13.404a2.219 2.219 0 0 0 .508-3.337L25.641 3.752a2.167 2.167 0 0 0-3.283 0z' fill='${props.theme.color.paper.replace(
      '#',
      '%23'
    )}'/%3E%3C/svg%3E%0A")
      0.8rem 1rem no-repeat;
  `
);

const AvatarHeaderCenter = styled(Center)`
  margin-top: -6rem;
  position: absolute;
  width: 100%;
`;

const AvatarHeader = ({ avatarImage, percent }) => (
  <AvatarHeaderCenter intrinsic>
    <AvatarBox width="10rem" height="10rem" padding="none" title="user avatar image">
      <AvatarProgress image={avatarImage} percent={percent} />
    </AvatarBox>
  </AvatarHeaderCenter>
);

const TourContent = styled(Stack)(
  props => css`
    position: relative;
    top: 3rem;
    overflow: hidden;
    maxheight: calc(100vh - ${globalSizes.large} - (2 * ${globalSizes[props.padding]}));
  `
);

/**
 *
 * The AvatarProgessModal component is a Modal Dialog Box which presents an AvatarProgress
 * component above its content. This modal is typically to be used to present a number of
 * steps (in a "wizard" type format) with the progress bar showing the user's progression
 * through the process.
 *
 * It adds the following props to its children:
 *
 * * Height : passed to DialogBody component to render the height of the scrolling content within the dialog
 * * Hidden : tells the page to display or not depending upon whether it is the current active page of the wizard
 * * Gap - the gap prop passed to the Dialog - to be passed down to DialogBody/DialogFooter components
 * * Index - used for generating keys
 */
export default function AvatarProgressModal({
  avatarImage,
  children,
  height = '25rem',
  gap = 'base',
  onClose = () => {},
  onChange = () => {},
  overlay = false,
  padding = 'medium',
  page = 0,
  visible = true,
  width,
  ...rest
}) {
  const percent = page === 0 ? 0 : Math.max(0, page / (React.Children.count(children) - 1)) * 100;
  const innerHeight = `calc(${height} - 3rem)`;

  return (
    <DialogWithOverFlow
      data-testid="AvatarModal"
      gap={gap}
      height={height}
      overlay={overlay}
      onClose={onClose}
      padding="none"
      visible={visible}
      width={width}
      {...rest}
    >
      <AvatarHeader avatarImage={avatarImage} percent={percent} />
      <TourContent height={innerHeight} gap={gap} padding={padding} splitAfter="2">
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            height: innerHeight,
            hidden: page === index ? false : true,
            gap: gap,
          })
        )}
      </TourContent>
    </DialogWithOverFlow>
  );
}

AvatarProgressModal.propTypes = {
  /**
   * the image to be used in the AvatarProgress component
   */
  avatarImage: PropTypes.string,
  /**
   * the children of the Modal - these will be the "pages" of the wizard
   */
  children: PropTypes.node,
  /**
   * The gap between the vertical elements inside the modal (this is passed down to DioalogBody ands DialogFooter components)
   */
  gap: PropTypes.string,
  /**
   * The preferred Height of the Dialog (px/rem/%)
   */
  height: PropTypes.string,
  /**
   * passthrough to DialogModal
   */
  onChange: PropTypes.func,
  /**
   * function to padd on closing the dialog
   */
  onClose: PropTypes.func,
  /**
   * tell DialogModal whether or not to display an overlay
   */
  overlay: PropTypes.bool,
  /**
   * The padding for the Modal
   */
  padding: PropTypes.string,
  /**
   * the current page within the wizard - passed to children to indicate which to display
   */
  page: PropTypes.number,
  /**
   * indicate whether Dialog is visible or not
   */
  visible: PropTypes.bool,
  /**
   * The preferred width of the dialog (px/rem/%)
   */
  width: PropTypes.string,
};
